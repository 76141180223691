(function () {
  'use strict';

  var module = angular.module('app.components');

  // /* @ngInject */
  // function controller($http) {
  function controller() {

    /*jshint validthis: true */
    var vm = this;
		vm.state = '';

    // Handle component lifecycle events
    vm.$onInit = function () {
      /**
       * Called on each controller after all the controllers on an element have been constructed
       * and had their bindings initialized (and before the pre & post linking functions for the
       * directives on this element). This is a good place to put initialization code for your
       * controller.
       */
      //doSomeDefaultStuff();
			//makeSummary();
    };

	vm.switchToSummaryView = function() {
		if (angular.equals({}, vm.entity)) {
			vm.state = '';
		}
		else {
			vm.state = 'summary';
			makeSummary();
		}
	};

    ////////////////////////
    // function doSomeDefaultStuff(){
    //   vm.entity.name = "doSomeDefaultStuff Was Also Here";
    // }
		function makeSummary() {
			console.log(vm.entity);
		}
  }

  module.component('freightInsuranceEntity', {
    bindings: {
      entity: '<',
      title: '@'
    },
    controllerAs: 'vm',
    controller: [controller], // list of DI items with controller method as the last item
    // controller: ['$http', controller], // list of DI items with controller method as the last item
    templateUrl: 'app/components/orderEntry/freightInsuranceEntity.component.html'
  });

}());
