// const { date } = require("gulp-util");

// const { version } = require("moment");

(function () {
	'use strict';

	angular
		.module('app.organization')
		.controller('OrganizationController', OrganizationController);

	OrganizationController.$inject = ['logger', 'currentUser', 'organizationDataservice', 'currentCustomer', '$q', '$state', '$scope', 'auth'];
	/* @ngInject */
	function OrganizationController(logger, currentUser, organizationDataservice, currentCustomer, $q, $state, $scope, auth) {
		var vm = this;
		vm.title = $state.current.title;
		vm.organizationId = $state.params.id;
		vm.userId = $state.params.userId;
		vm.currentUser = currentUser;
		// vm.create = create;
		// vm.createUser = createUser;
		vm.orgIsNew = false;
		vm.userIsNew = false;
		vm.nameChange = false;
		vm.externalChange = false;

		activate();

		////////////////////

		function activate() {
			var promises = [];
			switch ($state.current.name) {
				case 'organization.list':
					// vm.organizations = [];
					vm.removeOrganization = removeOrganization;
					promises.push(loadOrganizations());
					break;
				case 'organization.create':
				case 'organization.details':
					vm.organization = {};
					vm.organizationUsers = [];
					vm.validation = [];
					vm.isValid = orgIsValid;
					vm.createUser = createUser;
					vm.updateOrganization = updateOrganization;
					vm.impersonateUser = impersonateUser;
					vm.removeUser = removeUser;
					if ($state.current.name === 'organization.create') {
						vm.orgIsNew = true;
						promises.push(createOrganization());
					} else if ($state.current.name === 'organization.details') {
						vm.orgIsNew = false;
						promises.push(getOrganizationDetails());
						promises.push(getOrganizationUsers());
					} else {
						break;  // shouldn't be any other options
					}
					break;
				case 'organization.newUser':
				case 'organization.editUser':
					// vm.newUser = { orgId: vm.organizationId };
					vm.user = {};
					vm.changesMade = false;
					vm.nameChecked = false;
					vm.nameAvailable = false;
					vm.updateUser = updateUser;
					vm.isValid = userIsValid;
					vm.findUserName = findUserName;
					promises.push(getOrganizationDetails());
					if ($state.current.name === 'organization.newUser') {
						vm.userIsNew = true;
						promises.push(createUser());
					} else if ($state.current.name === 'organization.editUser') {
						vm.userIsNew = false;
						promises.push(getUserDetails());
					} else {
						break;  // shouldn't be any other options
					}
					break;
			}
			return $q.all(promises).then(function () {
				// logger.info('Activated Admin Organizations View');
			});
		}

		function loadOrganizations() {
			return organizationDataservice.getOrganizations().then(function (data) {
				vm.organizations = data;
				return vm.organizations;
			});
		}

		function createUser() {
			// create an empty user object
			vm.user = {
				id: null,
				organization_id: +vm.organizationId,
				username: '',
				is_deleted: 0,
				organizationRole: 60,
				admin: false,
				pass: '',
				// following set in dao
				// password_hash: '',
				// created_on: date.Now(),
				// updated_on: date.Now(),
				// created_by: vm.currentUser.userid,
				// updated_by: vm.currentUser.userid,
			};
			return vm.user;
		}

		function getUserDetails() {
			return organizationDataservice.getUserById(vm.organizationId, vm.userId).then(function (data) {
				vm.user = data[0];
				switch (vm.user.organizationRole) {
					case 60:
					case 120:
						vm.user.admin = false;
					case 255:
						vm.user.admin = true;
						break;
				}
				console.log(vm.user);
				return vm.user;
			});
		}

		function findUserName() {
			return organizationDataservice.getUserByName(vm.user.username).then(function (data) {
				vm.nameChecked = true;
				if (data) { // got result
					if (data.status && data.status === 404) {  // not found
						vm.nameAvailable = true;
					} else { // found
						vm.nameAvailable = false;
					}
				} else { // got no data
					vm.nameAvailable = true;
				}
			});
		}

		function createOrganization() {
			// create an empty user object
			vm.organization = {
				id: null,
				name: '',
				csi_id: null,
				external_id: '',
				usage_type: 'LTL',
				qb_name: '',
				expense_account: '',
				ap_account: '',
				ar_account: '',
				permissions: '1',  // converted to numeric on save
				is_deleted: 0,
				// following set in dao
				// created_by: vm.currentUser.userid,
				// updated_by: vm.currentUser.userid,
				// following set in db
				// created_on: date.Now(),
				// updated_on: date.Now(),
			};
			return vm.organization;
		}

		function updateOrganization() {
			// strip stuff not stored
			// delete vm.organization. ;
			vm.organization.permissions = +vm.organization.permissions;
			if ((!vm.organization.id) || +vm.organization.id === 0) { // no id, it's an add
				if (vm.organization && vm.organization.name) {
					return organizationDataservice.createOrganization(vm.organization).then(function (data) {
						if (data) {
							vm.orgIsNew = false;
							logger.success('Organization created: ' + data.name);
							$state.go('organization.details', { id: data.id }, { reload: true });
						}
					});
				}
			} else { // update
				return organizationDataservice.updateOrganization(vm.organization).then(function (data) {
					logger.success('Organization updated: ' + vm.organization.name);
					$state.go('organization.list', {}, { reload: true });
				});
			}
		}

		function removeOrganization(org) {
			return organizationDataservice.removeOrganization(org).then(function (data) {
				logger.success('Organization removed: ' + org.name);
				$state.go('organization.list', {}, { reload: true });
			});
		}

		function getOrganizationDetails() {
			return organizationDataservice.getOrganizationById(vm.organizationId).then(function (data) {
				vm.organization = data;
				vm.organization.permissions = (!vm.organization.permissions) ? "1" : vm.organization.permissions.toString();
				return vm.organization;
			});
		}

		function getOrganizationUsers() {
			return organizationDataservice.getUsersForOrganization(vm.organizationId).then(function (data) {
				vm.organizationUsers = data;
				return vm.organizationUsers;
			});
		}

		function impersonateUser(userId) {
			/**
			 * call server with userId we want to impersonate
			 * get a token back for the impersonated user
			 * use the auth service to save the new token
			 * call something similar to currentUser.isLoggedIn() to reset currentUser claims
			 */
			currentCustomer.clearCurrentCustomer(); // prevents unwanted carryover
			return organizationDataservice.getImpersonationTokenFor(userId).then(function (data) {
				if (data.token) {
					auth.saveToken(data.token);
					currentUser.reset();
				}
				logger.success('Viola! You are now logged in as ' + currentUser.username);
				$state.go('dashboard');
				return;
			});
		}

		function updateUser() {
			// strip stuff not stored
			delete vm.user.admin;
			if ((!vm.user.id) || +vm.user.id === 0) { // no id, it's an add
				if (vm.user && vm.user.pass && vm.user.username) {
					return organizationDataservice.createOrganizationUser(vm.user).then(function (data) {
						if (data) {
							logger.success('User created: ' + data.username);
							$state.go('organization.details', { id: vm.organizationId }, { reload: true });
						}
					});
				} else {
					logger.error('No inputs can be left blank');
				}
				vm.user.username = '';
				vm.user.pass = '';
			} else { // update
				return organizationDataservice.updateOrganizationUser(vm.user).then(function (data) {
					logger.success('User updated: ' + vm.user.username);
					$state.go('organization.details', { id: vm.organizationId }, { reload: true });
				});
			}
		}

		function removeUser(user) {
			return organizationDataservice.removeOrganizationUser(vm.organizationId, user.id).then(function (data) {
				logger.success('User removed: ' + user.username);
				$state.go('organization.details', { id: vm.organizationId }, { reload: true });
			});
		}

		function dupOrgName() {
			// check for duplicate name
			try {
				organizationDataservice.getOrganizationByName(vm.organization.name).then(function (data) {
					return (!!data.id);
				});
			} catch (error) {
				return false;
			}
		}
		function dupOrgExternal() {
			// check for duplicate external_id
			try {
				organizationDataservice.getOrganizationByExternal(vm.organization.external_id).then(function (data) {
					return (!!data.id);  // a successful read means a duplicate
				})
			} catch (error) {
				return false;
			}
		}

		function orgIsValid() {
			isChanged();
			vm.validation = [];

			if (vm.nameChange && vm.organization.name.length > 3) {
			  vm.nameChange = false;					
			  if (dupOrgName()) {
					vm.validation.push('Name may not be duplicated. (Another organization has this name.)');
			  } else {
					vm.nameChange = true;
			  }
			}
			if (vm.externalChange && vm.organization.external_id.length > 3) {
				vm.externalChange = false;
			  if (dupOrgExternal()) {
					vm.validation.push('External ID may not be duplicated. (Another organization has this external ID.)');
			  } else {
					vm.externalChange = true;
			  }
			}
			if (!vm.organization.usage_type) {
				vm.validation.push('Usage is required');
			}
			if (!vm.organization.qb_name) {
				vm.validation.push('QuickBooks Name is required');
			}
			if (!vm.organization.expense_account) {
				vm.validation.push('Expense Account is required');
			}
			if (!vm.organization.ap_account) {
				vm.validation.push('AP Account is required');
			}
			if (!vm.organization.ar_account) {
				vm.validation.push('AR Account is required');
			}
			if (!vm.organization.income_item) {
				vm.validation.push('Income Item is required');
			}
			if (!vm.organization.permissions) {
				vm.validation.push('Permissions is required');
			}
			// force angular re-eval changes to vm.validation[] 
			try { $scope.$digest(); } catch (error) { }	// ignore 'already in progress'	
			return vm.validation.length === 0;
		}

		function userIsValid() {
			isChanged();
			vm.validation = [];

			if (!vm.user.username || vm.user.username == '') {
				vm.validation.push('Username is required');
			}
			if (!vm.user.pass || vm.user.pass == '') {
				vm.validation.push('Password is required');
			}
			return vm.validation.length === 0;
		}

		function isChanged() {
			vm.changesMade = true;
		}

	}
})();

		// function createUser() {
		// 	if (vm.user && vm.user.pass && vm.user.username) {
		// 		return organizationDataservice.createOrganizationUser(vm.user).then(function (data) {
		// 			if (data) {
		// 				logger.success('User created: ' + data.username);
		// 				$state.go('organization.details', { id: vm.organizationId }, { reload: true });
		// 			}
		// 		});
		// 	} else {
		// 		logger.error('No inputs can be left blank');
		// 	}
		// 	vm.user.username = '';
		// 	vm.user.pass = '';
		// }
