(function() {
  'use strict';

  angular
    .module('app.admin')
    .run(appRun);

  appRun.$inject = ['routerHelper'];
  /* @ngInject */
  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  // handy article on skinny controllers
  // https://scotch.io/tutorials/making-skinny-angularjs-controllers

  function getStates() {
    return [
      {
        state: 'admin.systoken',
        config: {
          url: '',
          abstract: true,
          template: '<ui-view/>',
          supersOnly: true,
        }
      },
      {
        state: 'admin.systoken.list',
        config: {
          url: '/admin/systoken',
          templateUrl: 'app/areas/admin/systoken/admin-systoken-list.html',
          title: 'Admin: System Tokens',
          controller: 'AdminSystokenController',
          controllerAs: 'vm',
        }
      }
    ];
  }
})();
