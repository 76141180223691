(function() {
  'use strict';

  angular
    .module('app.directives')
		.directive('pcCustomFocus', customFocus);

	function customFocus() {
   var directive = {
        link: link,
        restrict: 'EA'
    };
    return directive;

    function link(scope, element, attrs) {
			scope.$watch(function() {
					return scope.$eval(attrs.customFocus);
				},
				function (){
					element[0].focus();
				});
    }

	}
})();
