(function () {
	'use strict';

	angular
		.module('app.admin')
		.factory('adminSystemCarrierDataservice', adminSystemCarrierDataservice);

		adminSystemCarrierDataservice.$inject = ['$http', '$q', 'exception', 'logger'];
	/* @ngInject */
	function adminSystemCarrierDataservice($http, $q, exception, logger) {

		var service = {
			get: getSystemCarriers,
			getById: getCarrier,
			create: createCarrier,
			update: updateCarrier,
			removeCarrier: removeCarrier
		};

		return service;

		function getSystemCarriers() {
			return $http.get('/api/shippers')
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getSystemCarriers')(e);
			}
		}

		function getCarrier(id) {
			return $http.get('/api/shippers/' + id)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getSystemCarrier')(e);
			}
		}

		function createCarrier(newCarrier) {
			return $http.post('/api/shippers', newCarrier).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				if(e.status === 400)
				{
					logger.error('Carrier already exists');
				} else {
					logger.error('Failed to create carrier');
				}

				return null;
			}
		}

		function updateCarrier(newCarrier) {
			return $http.put('/api/shippers/' + newCarrier.id, newCarrier).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				logger.error('Failed to create carrier');
				return null;
			}
		}

		function removeCarrier(carrier) {
			return $http.delete('/api/shippers/' + carrier.id).then(success).catch(fail);
			function success(response) {
				return response.data;
			}

			function fail(e) {
				return logger.error('Failed to delete carrier: ' + e);
			}
		}
	}
})();
