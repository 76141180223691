(function() {
  'use strict';

  angular
    .module('app.admin')
    .run(appRun);

  appRun.$inject = ['routerHelper'];
  /* @ngInject */
  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  // handy article on skinny controllers
  // https://scotch.io/tutorials/making-skinny-angularjs-controllers

  function getStates() {
    return [
      {
        state: 'admin.sysuser',
        config: {
          url: '',
          abstract: true,
          template: '<ui-view/>',
          supersOnly: true,
        }
      },
      {
        state: 'admin.sysuser.list',
        config: {
          url: '/admin/sysuser',
          templateUrl: 'app/areas/admin/sysuser/admin-sysuser-list.html',
          title: 'Admin: System Users',
          controller: 'AdminSystemUserController',
          controllerAs: 'vm',
        }
			},
			{
				state: 'admin.sysuser.create',
				config: {
					url: '/admin/sysuser/new',
					templateUrl: 'app/areas/admin/sysuser/admin-sysuser-create.html',
					title: 'Admin: Create System User',
					controller: 'AdminSystemUserController',
					controllerAs: 'vm',
				}
      }
    ];
  }
})();
