(function () {
	'use strict';

	angular
		.module('app.admin')
		.factory('adminSystemUserDataservice', adminSystemUserDataservice);

		adminSystemUserDataservice.$inject = ['$http', '$q', 'exception', 'logger'];
	/* @ngInject */
	function adminSystemUserDataservice($http, $q, exception, logger) {

		var service = {
			get: getSystemUsers,
			create: createSystemUser,
			removeUser: removeUser
		};

		return service;

		function getSystemUsers() {
			return $http.get('/api/organizations/1/users')
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getSystemUsers')(e);
			}
		}

		function createSystemUser(newUser) {
			return $http.post('/api/organizations/1/users', newUser).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				if(e.status === 400)
				{
					logger.error('User already exists');
				} else {
					logger.error('Failed to create user');
				}

				return null;
			}
		}

		function removeUser(userId) {
			return $http.delete('/api/users/' + userId).then(success).catch(fail);
			function success(response) {
				return response.data;
			}

			function fail(e) {
				return logger.error('Failed to delete user: ' + e);
			}
		}


	}
})();
