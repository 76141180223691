(function () {
	'use strict';

	angular
		.module('app.markup')
		.factory('markupDataservice', markupDataservice);

	markupDataservice.$inject = ['$http', '$q', 'exception', 'logger'];
	/* @ngInject */
	function markupDataservice($http, $q, exception, logger) {

		var service = {
			getMarkups: getMarkups,
			getMarkupById: getMarkupById,
			getMarkupsByOrgId: getMarkupsByOrgId,
			// match: match,
			// exactMatch: exactMatch,
			// search: search,
			createMarkup: createMarkup,
			removeMarkup: removeMarkup,
			updateMarkup: updateMarkup,
		};
		return service;


		function getMarkups() {
			return $http.get('/api/custmarkup') 
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getMarkups')(e);
			}
		}


		function getMarkupById(orgid, addrid) {
			return $http.get('/api/custmarkup/' + orgid + '/' + addrid)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getMarkupById')(e);
			}
		}

		function getMarkupsByOrgId(id) {
			return $http.get('/api/custmarkup/' + id)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				if (e === 'not found') { return {} } else {
					return exception.catcher('XHR Failed for getMarkupsByOrgId')(e);
				}
			}
		}


		function createMarkup(markup) {
			// $http.body = markup;
			return $http.post('/api/custmarkup/', markup).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for createMarkup')(e);
			}
		}

		function removeMarkup(id, userId) {

			return $http.delete('/api/custmarkup/' + id + '/' + userId).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for removeMarkup')(e);
			}
		}

		function updateMarkup(markup) {
			return $http.put('/api/custmarkup/' + markup.id, markup).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for updateMarkup')(e);
			}
		}

	}
})();

/* sample markup json
{
	"organization_id": "105",
		"name": "INNIO WAUKESHA",
			"markup_info": {
		"service": {
			"type": "ltl",
				"transit": "*",
					"priority": "*",
						"minimum": 100,
							"minimum_unit": "cost",
								"transaction_fee": 0
		},
		"base": {
			"method": "markup",
				"rate": 0.13
		},
		"fuel_surcharge": {
			"method": "markup",
				"rate": 0.13
		},
		"accessorial": {
			"method": "markup",
				"rate": 0.13
		},
		"insurance": {
			"method": "cost",
				"rate": 1
		}
	}
} */