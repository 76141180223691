// import { json } from "body-parser";

// import { version } from "moment";

(function () {
	'use strict';

	angular
		.module('app.markup')
		.controller('MarkupController', MarkupController);

	MarkupController.$inject = ['$http', 'logger', 'currentUser', 'currentCustomer', 'markupDataservice', '$q', '$state', 'auth'];
	/* @ngInject */
	function MarkupController($http, logger, currentUser, currentCustomer, markupDataservice, $q, $state, auth) {
		var vm = this;
		vm.title = $state.current.title;
		vm.id = $state.params.id;
		vm.currentUser = currentUser;
		vm.customer = {};
		if (+currentCustomer.id <= 0) {  // if empty, set it
			currentCustomer.setCurrentCustomer(
				(!!vm.currentUser.organizationId) ? vm.currentUser.organizationId : null,
				(!!vm.currentUser.organizationName) ? vm.currentUser.organizationName : null
			)
		} // else assume passed in
		vm.customer.id = currentCustomer.id;
		vm.customer.name = currentCustomer.name;

		activate();

		////////////////////

		function activate() {
			var promises = [];
			switch ($state.current.name) {
				case 'markup.list':
					vm.markups = [];
					vm.search = { term: '', orgId: vm.customer.id };  // NOTE
					vm.organizations = [];
					vm.markupSearch = markupSearch;
					vm.removeMarkup = removeMarkup;
					vm.changeCustomer = changeCustomerList;
					promises.push(loadOrganizations());
					promises.push(loadMarkups());
					break;
				case 'markup.create':
				case 'markup.details':  // just to minimize lines of code, identical except for createMarkup/updateMarkup
					vm.markup = {};
					vm.organizations = [];
					vm.validation = [];
					vm.changesMade = false;
					vm.updateMarkup = updateMarkup;
					vm.isValid = isValid;
					vm.isChanged = isChanged;
					vm.changeCustomer = changeCustomerDetail;
					promises.push(loadOrganizations());
					if ($state.current.name === 'markup.create') {
						promises.push(createMarkup());
					} else if ($state.current.name === 'markup.details') {
						promises.push(getMarkupDetails());
					} else {
						break;  // shouldn't be any other options
					}
					break;
			}
			return $q.all(promises).then(function () {
			});
		}

		function initCustomer() {
			vm.customer.id = vm.markup.organization_id;
			vm.customer.name = vm.markup.orgname;
			currentCustomer.setCurrentCustomer(vm.customer.id, vm.customer.name);
		}

		function changeCustomerList() {
			vm.customer = (!!vm.customer) ? vm.customer : {};
			currentCustomer.setCurrentCustomer(vm.customer.id, vm.customer.name);
			loadMarkups();
		}
		function changeCustomerDetail() {
			vm.markup.organization_id = vm.customer.id;
			vm.markup.orgname = vm.customer.name;
			currentCustomer.setCurrentCustomer(vm.customer.id, vm.customer.name);
		}

		function loadMarkups() {
			// customer.id !== 1 is a hack, systemUser(s) have org id = 1
			if ((!vm.customer.id) || (vm.customer.id === 1)) {
				// no customer/system user, get all
				return markupDataservice.getMarkups().then(function (data) {
					vm.markups = data;
					formatMarkups();
					return vm.markups;
				});
			} else {
				return markupDataservice.getMarkupsByOrgId(vm.customer.id).then(function (data) {
					vm.markups = data;
					formatMarkups();
					return vm.markups;
				});
			}
		}

		function loadOrganizations() {
			if (vm.currentUser.isSystemUser) {
				if (vm.organizations) {
					if (vm.organizations.length === 0) {
						return $http.get('/api/organizations').then(function (response) {
							vm.organizations = response.data;
						}, function (response) {
							console.log('error loading organizations');
							console.log(response);
						});
					}
				}
			}
		}

		function formatMarkups() {
			_.each(vm.markups, function(m) {
				m.typeName = (m.type === '*') ? 'All' : (m.type.toUpperCase() === 'LTL') ? 'LTL' : 'Parcel';
				m.transitName = (m.transit === '*') ? 'All' : (m.transit.toUpperCase() === 'DOMESTIC') ? 'Domestic' : 'Import';
				m.priorityName = (m.priority === '*') ? 'All' : (m.priority.toUpperCase() === 'STANDARD') ? 'Standard' : 'Expedited';
				m.baseMethodName = upperFirst(m.base_method);
				m.fuelMethodName = upperFirst(m.fuel_surcharge_method);
				m.accessorialMethodName = upperFirst(m.accessorial_method);
				m.insuranceMethodName = upperFirst(m.insurance_method);
			});
		}

		function upperFirst(n) {
			return n.substr(0,1).toUpperCase() + n.substr(1);
		}

		function markupSearch() {
			vm.search.orgId = vm.customer.id;
			return markupDataservice.search(vm.search).then(function (data) {
				vm.markups = data;
				_.each(vm.markups, function(a) { breakOutContacts(a); });
				return vm.markups;
			});
		}

		function createMarkup() {
			// create an empty markup object
			vm.markup = {
				id: null,
				organization_id: vm.customer.id,
				type: '',
				transit: '',
				priority: '',
				minimum: 0,
				minimum_unit: '',
				transaction_fee: 0,  // maybe ought to be null
				base_method: '',
				base_rate: 0,
				fuel_surcharge_method: '',
			  fuel_surcharge_rate: 0,
				fuel_surcharge_table: '',
				accessorial_method: '',
				accessorial_rate: 0,
				insurance_method: '',
				insurance_rate: 0,
				updated_by: vm.currentUser.userId,
				is_deleted: 0,
			}

			return vm.markup;
		}

		function getMarkupDetails() {
			return markupDataservice.getMarkupById(vm.customer.id, vm.id).then(function (data) {
				vm.markup = data;
				initCustomer();
				return vm.markup;
			});
		}

		function updateMarkup(markup) {
			// remove the expedients before writing--this isn't really necessary for the db, ignores members that don't correspond to columns
			delete markup.orgname;
			delete markup.typeName;
			delete markup.transitName;
			delete markup.priorityName;
			delete markup.baseMethodName;
			delete markup.fuelMethodName;
			delete markup.accessorialMethodName;
			delete markup.insuranceMethodName;
			markup.updated_by = vm.currentUser.userId;

			if (markup.base_method === 'markup' && markup.base_rate >= 1) {
				markup.base_rate = markup.base_rate - 1;
			}
			if (markup.accessorial_method === 'markup' && markup.accessorial_rate >= 1) {
				markup.accessorial_rate = markup.accessorial_rate - 1;
			}
			if (markup.fuel_surcharge_method === 'markup' && markup.fuel_surcharge_rate >= 1) {
				markup.fuel_surcharge_rate = markup.fuel_surcharge_rate - 1;
			}
			if (markup.insurance_method === 'markup' && markup.insurance_rate >= 1) {
				markup.insurance_rate = markup.insurance_rate - 1;
			}

			if ((!markup.id) || +markup.id === 0) { // no id, it's an add
				return markupDataservice.createMarkup(markup).then(function (data) {
					logger.success('Markup created: ' + markup.name);
					$state.go('markup.list', {}, { reload: true });
				});
			} else { // update
				//console.log(markup);
				return markupDataservice.updateMarkup(markup).then(function (data) {
					logger.success('Markup updated: ' + markup.name);
					$state.go('markup.list', {}, { reload: true });
				});
			}
		}

		function removeMarkup(id) {
			return markupDataservice.removeMarkup(id, vm.currentUser.userId).then(function (data) {
				logger.success('Markup removed: ' + id);
				$state.go('markup.list', {}, { reload: true });
			});
		}

		function isValid() {
			isChanged();
			vm.validation = [];

			if (!vm.markup.organization_id ||
				(vm.markup.organization_id === 1)) {
				vm.validation.push('A customer must be selected');
			}

			if (!vm.markup.type) {
				vm.validation.push('Type is required' );
			}
			if (!vm.markup.transit) {
				vm.validation.push('Transit is required' );
			}
			if (!vm.markup.priority) {
				vm.validation.push('Priority is required' );
			}
			if (vm.markup.minimum == null) {
				vm.validation.push('Minimum is required' );
			}
			if (vm.markup.transaction_fee == null) {
				vm.validation.push('Transaction Fee is required' );
			}
			if (!vm.markup.base_method) {
				vm.validation.push('Base Method is required' );
			}
			if (vm.markup.base_rate == null) {
				vm.validation.push('Base Rate is required' );
			}
			if (!vm.markup.fuel_surcharge_method) {
				vm.validation.push('Fuel Surcharge Method is required' );
			}
			if (vm.markup.fuel_surcharge_rate == null) {
				vm.validation.push('Fuel Surcharge Rate is required');
			}
			if (!vm.markup.accessorial_method) {
				vm.validation.push('Accessorial Method is required' );
			}
			if (vm.markup.accessorial_rate == null) {
				vm.validation.push('Accessorial Rate is required');
			}
			if (!vm.markup.insurance_method) {
				vm.validation.push('Insurance Method is required' );
			}
			if (vm.markup.insurance_rate == null) {
				vm.validation.push('Insurance Rate is required');
			}

			return vm.validation.length === 0;
		}

		function isChanged() {
			vm.changesMade = true;
		}

	}

})();

