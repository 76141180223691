(function () {
	'use strict';

	angular
		.module('app.admin')
		.factory('AdminOverrideDataservice', AdminOverrideDataservice);

	AdminOverrideDataservice.$inject = ['$http', '$q', 'exception', 'logger'];
	/* @ngInject */
	function AdminOverrideDataservice($http, $q, exception, logger) {

		var service = {
			getOverrides: getOverrides,
			getById: getById,
			create: create,
			update: update,
			remove: remove
		};

		return service;

		function getOverrides() {
			return $http.get('/api/shipperoverrides')
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getOverrides')(e);
			}
		}

		function getById(id) {
			return $http.get('/api/shipperoverrides/' + id)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getById')(e);
			}
		}

		function create(newOverride) {
			return $http.post('/api/shipperoverrides', newOverride).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				if(e.status === 400)
				{
					logger.error('Carrier Override already exists');
				} else {
					logger.error('Failed to create Carrier Override');
				}

				return null;
			}
		}

		function update(newOverride) {
			return $http.put('/api/shipperoverrides/' + newOverride.id, newOverride).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				logger.error('Failed to update Carrier Override');
				return null;
			}
		}

		function remove(id) {
			return $http.delete('/api/shipperoverrides/' + id).then(success).catch(fail);
			function success(response) {
				return response.data;
			}

			function fail(e) {
				return logger.error('Failed to delete Carrier Override: ' + e);
			}
		}
	}
})();
