(function () {
  'use strict';

  angular
    .module('app.profile')
    .run(appRun);

  appRun.$inject = ['routerHelper','currentUser', '$state'];

  /* @ngInject */
  function appRun(routerHelper, currentUser, $state) {
    routerHelper.configureStates(getStates(currentUser, $state));
  }

  function getStates(currentUser, $state) {
    return [
      {
        state: 'profile',
        config: {
          url: '',
          abstract: true,
          template: '<ui-view/>',
          controller: 'ProfileController', // will be inherited by child states
          controllerAs: 'vm',              // will be inherited by child states
        }
      },
      {
        state: 'profile.login',
        config: {
          url: '/login',
          templateUrl: 'app/areas/profile/login.html',
          title: 'Login',
          public: true,
        }
      },
      {
        state: 'profile.passwordChange',
        config: {
          url: '/profile/changepassword',
          templateUrl: 'app/areas/profile/password-change.html',
          title: 'Change Password',
        }
      },
      {
        state: 'profile.logout',
        config: {
          url: '/logout',
          /* @ngInject */
          controller: function (currentUser, $state) {
            currentUser.logout();
            $state.go('dashboard');
          }
        }
      }
    ];
  }
})();
