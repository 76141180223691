(function () {
  'use strict';

  angular
    .module('app')
    .service('auth', AuthService);

  AuthService.$inject = ['logger', '$window'];

  /* @ngInject */
  function AuthService(logger, $window) {
    var vm = this;

    vm.claims = {};

    vm.setClaimsFromStoredToken = function(){
      var token = vm.getToken();
      if (token) {
        vm.claims = vm.parseJwt(token);
      } else {
        vm.claims = {};
      }
    };

    vm.parseJwt = function (token) {
      var base64Url = token.split('.')[1];
	  var base64 = base64Url.replace('-', '+').replace('_', '/');
//	  return angular.fromJson($window.atob(base64));
	  return angular.fromJson(base64_decode(base64));	  
	  //Base64.decode(str);
	};
	
	function base64_decode( str )   
    {  
        if (window.atob) // Internet Explorer 10 and above  
            return decodeURIComponent(escape(window.atob( str )));  
        else  
        {  
            // Cross-Browser Method (compressed)  
          
            // Create Base64 Object  
            var Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/[^A-Za-z0-9\+\/\=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"\n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}}  
            // Encode the String  
            return decodeURIComponent(escape(Base64.decode( str )));  
        }  
    }  

    vm.saveToken = function (token) {
      $window.localStorage['jwtToken'] = token;
      vm.setClaimsFromStoredToken();
      logger.log('User logged in: JWT claims', vm.claims);
    };

    vm.getToken = function () {
      return $window.localStorage['jwtToken'];
    };

    vm.isAuthed = function () {
      if (_.has(vm, 'claims.exp')) {
        return Math.round(new Date().getTime() / 1000) <= vm.claims.exp;
      } else {
        return false;
      }
    };

    vm.logout = function () {
      $window.localStorage.removeItem('jwtToken');
      vm.setClaimsFromStoredToken();
      logger.log('User logged out: JWT claims', vm.claims);
    };


    activate();
    //////////////////////////////

    function activate(){
      vm.setClaimsFromStoredToken();
    }


  }

})();
// https://thinkster.io/tutorials/angularjs-jwt-auth
