(function () {
	'use strict';

	angular
		.module('app.admin')
		.controller('AdminAcctShipperController', AdminAcctShipperController);

	AdminAcctShipperController.$inject = ['$http', 'logger', 'currentUser', 'AdminAcctShipperDataservice', '$q', '$state', 'auth'];
	/* @ngInject */
	function AdminAcctShipperController($http, logger, currentUser, AdminAcctShipperDataservice, $q, $state, auth) {
		var vm = this;
		vm.title = $state.current.title;
		vm.id = $state.params.id;
		vm.currentUser = currentUser;

		// ensures everything gets loaded before needed
		vm.organizations = [];
		vm.shippers = [];
		vm.acctshippers = [];
		loadShippers().then(function () {
			loadOrganizations().then(function () {
				loadAcctShippers().then(function () {
					activate();
				})
			})
		});

		// activate();

		//////////////////// 

		function activate() {
			var promises = [];
			switch ($state.current.name) {
				case 'admin.acctshipper.list':
					vm.orgSelection = {};
					vm.shipperSelection = {};
					vm.filterShipper = filterShipper;
					vm.filterOrg = filterOrg;
					vm.clearFilters = clearFilters;
					vm.removeAcctShipper = removeAcctShipper;
					break;
				case 'admin.acctshipper.create':
				case 'admin.acctshipper.details':  // just to minimize lines of code, identical except for createAcctShipper/updateAcctShipper
					console.log(vm.id);
					vm.acctshipper = {};
					vm.shipperSelection = {};
					vm.orgSelection = {};
					vm.validation = [];
					vm.changesMade = false;
					vm.isValid = isValid;
					vm.isChanged = isChanged;
					vm.organizationChange = organizationChange;
					vm.shipperChange = shipperChange;
					vm.updateAcctShipper = updateAcctShipper;
					// promises.push(loadAcctShippers());  // this refreshes vm.acctshippers to full list for duplicate checking
					if ($state.current.name === 'admin.acctshipper.create') {
						promises.push(createAcctShipper());
					} else if ($state.current.name === 'admin.acctshipper.details') {
						promises.push(getAcctShipperDetails());
					} else {
						break;  // shouldn't be any other options
					}
					break;
			}
			return $q.all(promises).then(function () {
			});
		}

	  function loadAcctShippers() {
			return AdminAcctShipperDataservice.getAcctShippers().then(function (data) {
			  vm.acctshippers = data;

			  _.each(vm.acctshippers, function (o) {
				var a = _.find(vm.organizations, function (ot) { return ot.id === o.organization_id; });
					o.orgname = (!!a) ? a.name : 'not found, org id ' + o.organization_id;
					a = _.find(vm.shippers, function (st) { return st.id === o.shipper_id; });
					o.carrierName = (!!a) ? a.name : 'not found, shipper id ' + o.shipper_id;
					o.removeReplace = (o.remove === 1) ? 'remove' : 'replace';
					// o.removeBool = (o.remove === 1) ? true : false;
					o.removeDisplay = (o.remove === 1) ? 'Y' : 'N';
					// o.replaceBool = (o.replace === 1) ? true : false;
					o.replaceDisplay = (o.replace === 1) ? 'Y' : 'N';
			  });
				// console.log(vm.acctshippers);
				return vm.acctshippers;
		  });
		}

		function loadOrganizations() {
			if (vm.organizations.length === 0) {
				return $http.get('/api/organizations').then(function (response) {
					vm.organizations = response.data;
					// console.log('organizations')
					// console.log(vm.organizations)
				}, function (response) {
					console.log('error loading organizations');
					console.log(response);
				});
			}
		}

		function loadShippers() {
			if (!vm.shippers || vm.shippers.length === 0) {
				return $http.get('/api/shippers').then(function (response) {
					vm.shippers = response.data;
					// console.log('vm.shippers');
					// console.log(vm.shippers);
				}, function (response) {
					console.log('error loading shippers');
					console.log(response);
				});
			}
		}

		function filterShipper() {
			// just reduce the vm.acctshippers array
		  vm.acctshippers = _.filter(vm.acctshippers, function (o) { return o.shipper_id === vm.shipperSelection.id; });
		}
		function filterOrg() {
			// just reduce the vm.acctshippers array
		  vm.acctshippers = _.filter(vm.acctshippers, function (o) { return o.organization_id === vm.orgSelection.id; });
		}
		function clearFilters() {
			vm.shipperSelection = null;
			vm.orgSelection = null;
			loadAcctShippers();
		}

		function getAcctShipperDetails() {
			return AdminAcctShipperDataservice.getById(vm.id).then(function (data) {
				vm.acctshipper = data;
				vm.acctshipper.removeReplace = (vm.acctshipper.remove === 1) ? 'remove' : 'replace';
				// vm.acctshipper.removeBool = (vm.acctshipper.remove === 1) ? true : false;
				vm.acctshipper.removeDisplay = (vm.acctshipper.remove === 1) ? 'Y' : 'N';
				// vm.acctshipper.replaceBool = (vm.acctshipper.replace === 1) ? true : false;
				vm.acctshipper.replaceDisplay = (vm.acctshipper.replace === 1) ? 'Y' : 'N';
				vm.shipperSelection = getShipperDetails(vm.acctshipper.shipper_id);
				vm.acctshipper.carrierName = vm.shipperSelection.name;
				vm.orgSelection = getOrgDetails(vm.acctshipper.organization_id);
				vm.acctshippers.orgname = vm.orgSelection.name;
				return vm.acctshipper;
			});
		}

		function getShipperDetails(id) {
		  const shipper = _.find(vm.shippers, function (s) { return s.id === id; });
			return (!!shipper) ? shipper : {};
		}
		function getOrgDetails(id) {
		  const organization = _.find(vm.organizations, function (o) { return o.id === id; });
			return (!!organization) ? organization : {};
		}

		function organizationChange() {
			vm.acctshipper.organization_id = vm.orgSelection.id;
		}
		function shipperChange() {
			vm.acctshipper.shipper_id = vm.shipperSelection.id;
		}


		function createAcctShipper() {
			// create an empty override object
			vm.acctshipper = {
				id: null,
				organization_id: 0,
				shipper_id: '',
				code: '',
				remove: 0,
				replace: 0,
				access_key: '',
				account_id: '',
				username: '',
				password: '',
			};
			// extra stuff to be stripped before save
			vm.acctshipper.carrierName = '';
			vm.acctshipper.removeDisplay = 'N';
			vm.acctshipper.replaceDisplay = 'N';
			// vm.acctshipper.removeBool = false;
			// vm.acctshipper.replaceBool = true;
			vm.acctshipper.removeReplace = 'replace';
			vm.acctshipper.orgName = '';

			return vm.acctshipper;
		}

		function updateAcctShipper(acctShipper) {
			// convert to storage formats
			// note these .remove/.replace are mutually exclusive
			acctShipper.remove = (acctShipper.removeReplace === 'remove') ? '1' : '0';
			acctShipper.replace = (acctShipper.removeReplace === 'replace') ? '1' : '0';
			// strip stuff not stored
			delete acctShipper.orgName;
			delete acctShipper.carrierName;
			delete acctShipper.removeDisplay;
			delete acctShipper.replaceDisplay;
			// delete acctshipper.removeBool;
			// delete acctshipper.replaceBool;
			delete acctShipper.removeReplace;
			// save
			console.log(acctShipper)
			if ((!acctShipper.id) || +acctShipper.id === 0) { // no id, it's an add
				console.log('create');
				console.log(acctShipper);
				return AdminAcctShipperDataservice.create(acctShipper).then(function (data) {
					logger.success('AcctShipper created');
					$state.go('admin.acctshipper.list', {}, { reload: true });
				});
			} else { // update
				console.log('update');
				console.log(acctShipper);
				return AdminAcctShipperDataservice.update(acctShipper).then(function (data) {
					logger.success('AcctShipper updated: ' + acctShipper.id);
					$state.go('admin.acctshipper.list', {}, { reload: true });
				});
			}
		}

		function removeAcctShipper(id) {
			return AdminAcctShipperDataservice.remove(id).then(function (data) {
				logger.success('AcctShipper removed: ' + id);
				$state.go('admin.acctshipper.list', {}, { reload: true });
			});
		}

		function isDupAcctShipper() {
			// check for dups
			// note loadAcctShippers at top ensures we're looking at an un-filtered list for dup checking
			// remove the one we're editing from the list
		  var checkAcctShippers = _.filter(vm.acctshippers, function (o) { return o.id !== vm.acctshipper.id; });
			// get all acctShippers w same shipper, org as the current acctshipper
			var foundShipperAndOrg = _.filter(checkAcctShippers, function(o) {
				return ((o.shipper_id === vm.acctshipper.shipper_id) &&
					(o.organization_id === vm.acctshipper.organization_id));
			});
			return ((!!foundShipperAndOrg) && foundShipperAndOrg.length > 0);  // found one, it's a dup
		}

		function isValid() {
			isChanged();
			vm.validation = [];
			// check for dups
			// don't look unless shipper and carrier already present
			if ((!!vm.acctshipper.shipper_id) &&
				(!!vm.acctshipper.organization_id)) {
				if (isDupAcctShipper()) {
					vm.validation.push('Configuration already exist, only unique combinations of shipper and customer are allowed');
				}
			}
			if (!vm.acctshipper.shipper_id) {
				vm.validation.push('A carrier must be selected');
			}
			if (!vm.acctshipper.organization_id ||
				(vm.acctshipper.organization_id === 1)) {
				vm.validation.push('A customer must be selected');
			}
			// if (!vm.acctshipper.code) {
			// 	vm.validation.push('A code must be entered');
			// }

			return vm.validation.length === 0;
		}

		function isChanged() {
			vm.changesMade = true;
		}

	}

})();


		// vm.customer = { id: null, name: null };
		// if (+currentCustomer.id <= 0) {  // if empty, set it
		// 	currentCustomer.setCurrentCustomer(
		// 		(!!vm.currentUser.organizationId) ? vm.currentUser.organizationId : null,
		// 		(!!vm.currentUser.organizationName) ? vm.currentUser.organizationName : null
		// 	)
		// } // else assume passed in
		// vm.customer.id = currentCustomer.id;
		// vm.customer.name = currentCustomer.name;
		// vm.containerObj = { name: "Pallet(s)", value: "Pallet" };
		// vm.containerOptions = [];


		// function initCustomer() {
		// 	vm.customer.id = vm.acctshipper.organization_id;
		// 	vm.customer.name = vm.acctshipper.orgname;
		// 	currentCustomer.setCurrentCustomer(vm.customer.id, vm.customer.name);
		// }

		// function changeCustomerList() {
		// 	vm.customer = (!!vm.customer) ? vm.customer : { };
		// 	// vm.customer = (!!vm.customer) ? vm.customer : { id: '', name: '' };
		// 	currentCustomer.setCurrentCustomer(vm.customer.id, vm.customer.name);
		// 	loadOverrides();
		// }
		// function changeCustomerDetail() {
		// 	if (!!vm.customer) {
		// 		vm.acctshipper.organization_id = (!!vm.customer.id) ? vm.customer.id : null;
		// 		vm.acctshipper.orgname = (!!vm.customer.name) ? vm.customer.name : null;
		// 	} else {
		// 		vm.customer = { id: null, name: null };
		// 		vm.acctshipper.organization_id = null;
		// 		vm.acctshipper.orgname = null;
		// 	}
		// 	currentCustomer.setCurrentCustomer(vm.customer.id, vm.customer.name);
		// }

		// function overrideSearch() {
		// 	vm.search.orgId = vm.customer.id;
		// 	return AdminAcctShipperDataservice.search(vm.search).then(function (data) {
		// 		vm.acctshippers = data;
		// 		return vm.acctshippers;
		// 	});
		// }
