(function () {
	'use strict';

	var module = angular.module('app.components');

	// /* @ngInject */
	function controller($http, $window, $anchorScroll) {
		//function controller() {

		/*jshint validthis: true */
		var vm = this;
		vm.state = '';
		vm.enteredLine = { item_reference: '',};
		vm.indexBeingEdited = -1;
		vm.containerObj = { name: "", value: "" }  // TODO change these to "most frequently used"
		vm.validation = [];

		vm.canSave = false;

		vm.scrollTo = function (id) {
			$anchorScroll.yOffset = 50;
			$anchorScroll(id);
		}

		vm.switchToEntryMode = function (index) {
			if (index === null) {
				vm.state = 'add'
				initEnteredLine();
				vm.indexBeingEdited = -1;
				vm.focusOnItemForm('pieces', true);
			}
			else {
				vm.state = 'edit'
				angular.copy(vm.entity.items[index], vm.enteredLine);
				vm.enteredLine.CL = vm.enteredLine.CL.toString();
				vm.indexBeingEdited = index;
			}
		}

		vm.focusOnItemForm = function (which, scroll) {
			setTimeout(function () {
				var element = $window.document.getElementById(which);
				if (element)
					element.focus();
				if (scroll) vm.scrollTo('items-container');
			}, 500);
		}

		vm.addNewLine = function (closeAfterSaved) {

			if (vm.requiredFields()) {
				if (closeAfterSaved && (angular.equals({}, vm.enteredLine))) {
					vm.state = '';
					return;
				}
				if (!!vm.enteredLine.dimensions) {
					vm.enteredLine.cubicFeet = calculateCubicFeet(vm.enteredLine.dimensions.lengthInInches,
						vm.enteredLine.dimensions.widthInInches,
						vm.enteredLine.dimensions.heightInInches);
				}
				vm.enteredLine.container = vm.containerObj.value;
				vm.entity.items.push(vm.enteredLine);
				recalculateOrderTotals();
				initEnteredLine();

				if (closeAfterSaved) {
					vm.state = '';
				}

				vm.focusOnItemForm('pieces', true);
			}
		}

		vm.saveLineChanges = function () {
			if (vm.requiredFields()) {
				vm.enteredLine.cubicFeet = calculateCubicFeet(vm.enteredLine.dimensions.lengthInInches,
					vm.enteredLine.dimensions.widthInInches,
					vm.enteredLine.dimensions.heightInInches);
				vm.enteredLine.container = vm.containerObj.value;
				vm.entity.items[vm.indexBeingEdited] = vm.enteredLine;
				recalculateOrderTotals();
				vm.indexBeingEdited = -1;
				vm.state = '';
				initEnteredLine();
			}
		}

		vm.removeLine = function (index) {
			vm.entity.items.splice(index, 1);
			recalculateOrderTotals();
		}

		// Handle component lifecycle events
		vm.$onInit = function () {
			initEnteredLine();
		};

		vm.$onChanges = function (changesObj) {
			recalculateOrderTotals();
		};

		function recalculateOrderTotals() {
			var totalWeight = 0;
			var totalPieces = 0;

			for (var i = 0, len = vm.entity.items.length; i < len; i++) {
				var item = vm.entity.items[i];

				totalWeight += +item.weight;
				totalPieces += +item.pieces;

				vm.entity.items[i].cubicFeet = calculateCubicFeet(item.dimensions.lengthInInches, item.dimensions.widthInInches, item.dimensions.heightInInches);
			}

			vm.entity.totalPieces = totalPieces;
			vm.entity.totalWeight = totalWeight;
		}

		function calculateCubicFeet(lengthInInches, widthInInches, heightInInches) {
			var cubicInches = lengthInInches * widthInInches * heightInInches || 0;
			return _.round(cubicInches / 1728.0, 2);
		}

		vm.requiredFields = function () {
			vm.validation = [];
			if (!vm.enteredLine.description) {
				vm.validation.push('Description is required');
			}
			if (!vm.enteredLine.container) {
				vm.validation.push('Pkg Type is required');
			}
			if (!vm.enteredLine.pieces) {
				vm.validation.push('Package quantity (# Pkgs) is required');
			} else {
				if (vm.enteredLine.pieces <= 0) {
					vm.validation.push('Package quantity (# Pkgs) must be non-zero');
				}
			}
			if (!vm.enteredLine.NMFC) {
				vm.validation.push('NMFC # is required');
			}
			if (!vm.enteredLine.CL) {
				vm.validation.push('Class is required');
			}
			if (!vm.enteredLine.weight) {
				vm.validation.push('Weight is required');
			}
			vm.canSave = (vm.validation.length === 0);
			return (vm.validation.length === 0) ? true : false;
		}

		vm.setContainerSelection = function () {
			vm.enteredLine.container = vm.containerObj.value;
		}

		vm.setProductSelection = function (item) {
			vm.enteredLine.itemReference = item.item_reference;
			vm.enteredLine.description = item.description;
		  vm.enteredLine.NMFC = item.nmfc || item.NMFC;
			vm.enteredLine.CL = item.class;
			if (!vm.enteredLine.dimensions) vm.enteredLine.dimensions = {};
			vm.enteredLine.dimensions.lengthInInches = item['length'];
			vm.enteredLine.dimensions.widthInInches = item.width;
			vm.enteredLine.dimensions.heightInInches = item.height;
			vm.enteredLine.weight = item.weight;
			vm.newProduct = false;
			vm.requiredFields();
			vm.focusOnItemForm('pieces', false);
		}

		vm.setTerm = function (item) {
			vm.searchTerm = {
				itemReference: item.itemReference,
				description: item.description
			}
		}

		function initEnteredLine() {
			vm.enteredLine = {
				itemReference: '', 
				description: '',
				container: vm.containerObj.value,
				pieces: null,
				dimensions: {
					lengthInInches: 0,
					widthInInches: 0,
					heightInInches: 0
				},
			  NMFC: '0',
				class: '',
				weight: null,
			};
		}

		vm.getToSave = function() {
			angular.copy(vm.enteredLine, vm.toSave);
		}

	}


	module.component('itemsEntity', {
		bindings: {
			entity: '<',
			title: '@',
			readOnly: '@',
			containerOptions: '<',
			searchProducts: '&',
			parentValidate: '&',
			searchTerm: '=',
			matchingProducts: '=',
			newProduct: '=',
			toSave: '=',
			saveProduct: '&',
		},
		controllerAs: 'vm',
		//controller: [controller], // list of DI items with controller method as the last item
		controller: ['$http', '$window', '$anchorScroll', controller], // list of DI items with controller method as the last item
		templateUrl: 'app/components/orderEntry/itemsEntity.component.html'
	});

}());

