(function() {
  'use strict';

  angular
    .module('app.weightReport')
    .run(appRun);

  appRun.$inject = ['routerHelper'];
  /* @ngInject */
  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [
			{
				state: 'weightReport',
				config: {
					url: '/weightReport',
					templateUrl: 'app/areas/report/weightReport.html',
					controller: 'WeightReportController',
					controllerAs: 'vm',
					title: 'Weight Report'
				}
			}
    ];
  }
})();
