(function () {
	'use strict';

	angular
		.module('app.proEntry')
		.controller('ProEntryController', ProEntryController);
	ProEntryController.$inject = ['logger', 'currentUser', '$http'];

	/* @ngInject */
	function ProEntryController(logger, currentUser, $http) {
		var vm = this;
		//TODO remove this when done with invoice recalculation
		window.pec = window.pec || vm;
		vm.currentUser = currentUser;
		vm.invoices = [];
		vm.state = '';
		vm.invoiceToReview = {};
		var indexBeingReviewed = -1;
		vm.actualAmountsEntered = [];
		vm.totalActualAP = 0;
		vm.totalActualAR = 0;
		vm.totalExpectedAR = 0;
		vm.totalExpectedAP = 0;
		vm.deferredInvoiceItmes = [];
		vm.selectedDeferredInvoices = [];
		vm.orderItemsSummary = [];
		vm.showViewItemsColumnText = '';
		vm.editManifestNumber = false;
		vm.manifestNumber = null;
		vm.itemDescriptionEmpty = null;
		vm.previousState = '';
		vm.availableCategories = [];
		vm.isManual = false;
		vm.accessorials = [];
		vm.getAccessorialDescriptions = function () {
			return $http.get('/api/accessorials/')
				.then(success)
				.catch(fail);

			function success(response) {
				vm.accessorials = response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getAccessorialDescriptions')(e);
			}
		};

		vm.populateAccessorialDescription = function (invoiceAccessorials) {
			if (invoiceAccessorials && Array.isArray(invoiceAccessorials) && invoiceAccessorials.length > 0) {
				_.forEach(invoiceAccessorials, function(accessorial) {
					let tempAcc = _.find(vm.accessorials, { 'code': accessorial.code });
					if (tempAcc && tempAcc.label) {
						accessorial.description = tempAcc.label;
					}
				});
			}

			return invoiceAccessorials;
		}

		activate();

		function activate() {
			logger.info('Activated invoices Entry View');
			vm.showViewItemsColumnText = '';
			vm.getAccessorialDescriptions();
		}

		vm.formatPhoneNumber = function(tempPhoneNum) {
			if (!tempPhoneNum) {
				tempPhoneNum = '';
			}
			var phoneNum = '' + tempPhoneNum;
			phoneNum = phoneNum.trim();
			phoneNum = phoneNum.replace(/\D/g, '');
			if (phoneNum.length < 10) {
				phoneNum = phoneNum.padStart(10, ' ');
			}
			var pn = '';
			if (phoneNum.length > 10) {
				pn += '+' + phoneNum.substring(0, phoneNum.length - 10) + ' ';
			}
			pn += '('; pn += phoneNum.substring(phoneNum.length - 10, phoneNum.length - 7) + ') ' + phoneNum.substring(phoneNum.length - 7, phoneNum.length - 4) + '-' + phoneNum.substring(phoneNum.length - 4);

			return pn;
		};

		vm.getFriendlyDateString = function(isoDateString) {
			if (isoDateString && typeof isoDateString === 'string' && isoDateString.length >= 10)
				return (new Date(isoDateString.substr(0, 10).split('-'))).toDateString();
			else
				return '';
		};

		vm.invoicePage = function () {
			if (vm.invoices.length === 0) vm.invoices.push({});
			vm.showViewItemsColumnText = '';
			vm.state = 'invoice';
		};

		vm.reloadDeferredPage = function () {
			vm.invoices = [];
			vm.selectedDeferredInvoices = [];
			vm.getDeferredInvoices();
		};

		vm.previousPage = function () {
			if (vm.previousState === 'deferred') {
				vm.backToDeferredPage();
			} else if (vm.previousState === 'invoice') {
				vm.invoicePage();
			} else {
				vm.resetTheData();
			}
			vm.availableCategories = [];
		};

		vm.resetTheData = function () {
			vm.invoices = [];
			vm.selectedDeferredInvoices = [];
			vm.state = '';
			vm.previousState = '';
		};

		vm.backToDeferredPage = function () {
			vm.state = 'deferred'
		};

		vm.updateCategory = function (index, newCategory) {
			vm.invoiceToReview.quoteResponse.details.request.bill.details.charges[index].category = newCategory;
			vm.updateActualTotal();
		};

		vm.getDeferredInvoices = function () {
			$http.get('/api/invoices/deferred')
				.then(success)
				.catch(fail);

			function success(response) {
				if (response.data.length > 0 && response.data !== "NO DEFERRED") {
					vm.invoices = response.data;
				} else {
					vm.invoices = [];
				}
				vm.state = 'deferred';
			}

			function fail(e) {
				return logger.error('Failed to Retrieve Deferred Invoices')(e);
			}
		};

		vm.clearIsProcessing = function () {
			$http.put('/api/quickbooks/update/clear_is_processing')
				.then(success)
				.catch(fail);

			function success(response) {
				if (response.data.changedRows !== null) {
					logger.success('Successfully updated ' + response.data.changedRows + ' flags')
				} else {
					logger.error('Something went wrong with updating is_processing flag')
				}
			}

			function fail() {
				logger.error('Failed to update is_processing flag');
			}
		};

		vm.getInvoiceById = function (index) {
			$http.get('/api/invoices/getByOrderId/' + vm.invoices[index].swNumber)
				.then(success)
				.catch(fail);

			function success(response) {
				if (response.data[0] && response.data !== "NO INVOICE FOUND") {
					vm.invoices[index] = response.data[0];
					if (vm.state === 'invoice') {
						vm.addNewLineIfNeeded();
					}
				} else {
					vm.invoices = [];
				}
			}

			function fail() {
				logger.error('Failed to Retrieve Invoice details');
			}
		};

		vm.getInvoiceByProNumber = function (index) {
			$http.get('/api/invoices/getByProNumber/' + vm.invoices[index].proNumber)
				.then(success)
				.catch(fail);

			function success(response) {
				if (response.data[0] && response.data !== "NO INVOICE FOUND") {
					vm.invoices[index] = response.data[0];
					if (vm.state === 'invoice') {
						vm.addNewLineIfNeeded();
					}
				} else {
					vm.invoices = [];
					// logger.error('No Deferred Invoices Available');
				}
			}

			function fail() {
				logger.error('Failed to Retrieve Invoice details');
			}
		};


		vm.lookupProNumber = function (index) {
			vm.invoices[index].proNumber = '1234STUBPRO#';
			vm.invoices[index].proNumberRead = '1234STUBPRO#';
		};

		vm.lookupSwNumber = function (index) {
			vm.invoices[index].swNumber = '4321STUBSW#';
			vm.invoices[index].swNumberRead = '4321STUBSW#';
		};

		vm.lookupQuoteWithPro = function (index) {
			function success(response) {
				// vm.lookupSwNumber(index);
				PostProSwLookup(response, vm.invoices[index]);
				vm.postItemCompleteActions(index);
			}

			function fail(e) {
				clearSwNumber(vm.invoices[index]);
				clearInvoice(vm.invoices[index]);
				return logger.error('Invalid PRO#');
			}

			// if (!vm.InvoiceExistsWithProNumber(index)) {
			if (vm.invoices[index].proNumber !== vm.invoices[index].proNumberRead) {
				if (vm.invoices[index].proNumber !== null && vm.invoices[index].proNumber.length > 0) {
					vm.invoices[index].proNumberRead = vm.invoices[index].proNumber;
					//The following line (or something like it will replace the return eventually when we can lookup by both PW or PRO
					//return $http.get('/api/orders/s?pro=' + vm.invoices[index].proNumber)
					// STAN
					// return $http.get('/api/markup/pro/' + vm.invoices[index].proNumber)
					return $http.get('/api/orders/pro/' + vm.invoices[index].proNumber)
						.then(success)
						.catch(fail);
				}
				else {
					clearSwNumber(vm.invoices[index]);
					clearInvoice(vm.invoices[index]);
				}
			}
			// }
		};

		vm.lookupQuoteWithSw = function (index) {

			function success(response) {
				// vm.lookupProNumber(index);
				PostProSwLookup(response, vm.invoices[index]);
				vm.postItemCompleteActions(index);
			}

			function fail(e) {
				clearProNumber(vm.invoices[index]);
				clearInvoice(vm.invoices[index]);
				vm.postItemCompleteActions(index);
				return logger.error('Invalid Order ID');
			}

			if (vm.invoices[index].swNumber !== vm.invoices[index].swNumberRead) {
				if (vm.invoices[index].swNumber !== null && vm.invoices[index].swNumber.length > 0) {
					vm.invoices[index].swNumberRead = vm.invoices[index].swNumber;
					//The following line (or something like it will replace the return eventually when we can lookup by both PW or PRO

					//return $http.get('/api/orders/s?sw=' + vm.invoices[index].swNumber)
					// STAN
					// return $http.get('/api/markup/' + vm.invoices[index].swNumber)
					return $http.get('/api/orders/' + vm.invoices[index].swNumber)
						.then(success)
						.catch(fail);

				} else {
					clearProNumber(vm.invoices[index]);
					clearInvoice(vm.invoices[index]);
				}

			}
		};

		vm.InvoiceExistsWithPwNumber = function (index) {
			function success(response) {
				if (!response.data) {
					vm.lookupQuoteWithSw(index);
				} else {
					logger.error('Invoice with Order ID: ' + vm.invoices[index].swNumber + ' has already been submitted.');
					//todo: (if) its deferred: send to deferred page.
					// todo: (else if) not deferred and not in qb: load all database info. then save the new information via the submit button. new logic for submit button?
					vm.getInvoiceById(index);

					// vm.lookupQuoteWithSw(index);
					// var invoiceNumber = vm.invoices[index].invoiceNumber;
					// vm.invoices[index].proNumber = null;
					// clearInvoice(vm.invoices[index]);
					// vm.invoices[index].invoiceNumber = invoiceNumber ? invoiceNumber : ''
				}
			}


			function fail(e) {
				logger.error('Issue checking if invoice exists')
			}

			if (vm.invoices[index].swNumber !== vm.invoices[index].swNumberRead) {
				if (vm.invoices[index].swNumber !== null && vm.invoices[index].swNumber.length > 0) {
					$http.get('/api/invoices/pwExists/' + vm.invoices[index].swNumber)
						.then(success)
						.catch(fail);
				}
			}
		};

		vm.InvoiceExistsWithProNumber = function (index) {
			function success(response) {
				if (!response.data) {
					vm.lookupQuoteWithPro(index);
				} else {
					logger.error('Invoice with PRO #: ' + vm.invoices[index].proNumber + ' has already been submitted.');
					vm.getInvoiceByProNumber(index);
					// vm.invoices[index].swNumber = null;
					// var invoiceNumber = vm.invoices[index].invoiceNumber;
					// clearInvoice(vm.invoices[index]);
					// vm.invoices[index].invoiceNumber = invoiceNumber ? invoiceNumber : ''
				}
			}

			function fail(e) {
				logger.error('Issue checking if invoice exists')
			}

			if (vm.invoices[index].proNumber !== vm.invoices[index].proNumberRead) {
				if (vm.invoices[index].proNumber !== null && vm.invoices[index].proNumber.length > 0) {
					// vm.invoices[index].proNumberRead = vm.invoices[index].proNumber;
					$http.get('/api/invoices/proExists/' + vm.invoices[index].proNumber)
						.then(success)
						.catch(fail);
				}
			}
		};

		vm.recalculate = function() {
			console.log('RECALCULATING');
			vm.setOrderItemsToInvoice(vm.invoiceToReview);
			vm.updateActualTotal();

			//uncomment when thoroughly fixed not working as intended
		}

		vm.checkForTolerance = function (index) {
			function success(response) {
				vm.invoices[index] = response.data;
				vm.invoices[index].accepted = 0;// doing this because M and X will show up otherwise. Wont save unless user saves, or accepts.
				vm.invoices[index].organizationId = vm.invoices[index].quoteResponse.customer_name;
				vm.orderItemsSummary = summarizeOrderItems(vm.invoices[index]);
				indexBeingReviewed = index;
				vm.setOrderItemsToInvoice(vm.invoices[indexBeingReviewed]);
				if (!vm.invoices[index].quoteResponse.notes) {
					$http.get('/api/orders/' + vm.invoices[index].quoteResponse.id + '/notes?rnd=' + new Date().getTime()).then((response) => {
						vm.invoices[index].quoteResponse.notes = response.data;
					}, (response) => {
						console.log(response);
					});
				}
			}

			function fail(e) {
				return logger.error('getTolerance failed: ' + e);
			}

			if (vm.invoices[index].expectedAP && vm.invoices[index].actualAP) {
				$http.post('/api/reconciliation/reconcile/', vm.invoices[index])
					.then(function (response) {
						success(response)
					})
					.catch(fail);
			}
		};

		vm.addNewLineIfNeeded = function () {
			var last = vm.invoices.length - 1;
			if ((vm.invoices[last].proNumber || vm.invoices[last].swNumber)
				&& vm.invoices[last].expectedAP
				&& vm.invoices[last].actualAP) {
				vm.invoices.push({});
				last = vm.invoices.length - 1;
				vm.invoices[last].deferred = false;
			}
		};

		vm.addNewItemLineIfNeeded = function () {
			var last = vm.invoiceToReview.quoteResponse.details.request.bill.details.charges.length - 1;
			// in case all items were deleted and tried to add new item
			// need foreach in refactor to check if all have descriptions, just doing the most recent one right now
			if ((last <= -1) || (vm.invoiceToReview.quoteResponse.details.request.bill.details.charges[last].description && vm.invoiceToReview.quoteResponse.details.request.bill.details.charges[last].chksum !== null && vm.invoiceToReview.quoteResponse.details.request.bill.details.charges[last].category)) {
				vm.invoiceToReview.quoteResponse.details.request.bill.details.charges.push({});
				var newLast = vm.invoiceToReview.quoteResponse.details.request.bill.details.charges.length - 1;
				vm.invoiceToReview.quoteResponse.details.request.bill.details.charges[newLast].expectedAR = 0;
				vm.invoiceToReview.quoteResponse.details.request.bill.details.charges[newLast].expectedAP = 0;
				vm.invoiceToReview.quoteResponse.details.request.bill.details.charges[newLast].chksum = 0;
				vm.invoiceToReview.quoteResponse.details.request.bill.details.charges[newLast].amount = 0;
				vm.invoiceToReview.quoteResponse.details.request.bill.details.charges[newLast].code = 'MANUAL_ADD'
			} else if (!vm.invoiceToReview.quoteResponse.details.request.bill.details.charges[last].description) {
				logger.error('Enter a description for the new item');
			} else if (!vm.invoiceToReview.quoteResponse.details.request.bill.details.charges[last].category) {
				logger.error('Select a category for the new item');
			} else if (vm.invoiceToReview.quoteResponse.details.request.bill.details.charges[last].chksum === null) {
				logger.error('Enter an actual amount for the new item');
			}
			// vm.updateActualTotal();
		};

		vm.getCategories = function () {

			function success(response) {
				if (response.data) {
					var categories = response.data;
					_.each(categories, function (category) {
						vm.availableCategories.push({category: category, label: _.startCase(category)})
					});
				}
			}

			function fail() {
				vm.availableCategories = [
					'base_freight',
					'insurance',
					'brokerage',
					'fuel_surcharge',
					'accessorial',
					'internal'
				]
			}

			$http.get('/api/invoices/categories')
				.then(function (response) {
					success(response)
				})
				.catch(fail);
		};

		vm.removeItemFromInvoice = function (index) {
			vm.invoiceToReview.quoteResponse.details.request.bill.details.charges.splice(index, 1);
			vm.updateActualTotal(index);
		};

		vm.changeToProperCase = function (itemCategory) {
			return _.startCase(itemCategory);
		};

		vm.displayReview = function (index) {
			// todo: use this if we need to get org name by id instead of strait off the quote
			// function fail(e) {
			// 	return logger.error('getTolerance failed: ' + e);
			// }
			// function success(org) {
			// 	vm.invoiceToReview[index].organizationName = org.name;
			// }
			// return $http.get('/api/organization/' + vm.invoices[index].organizationId)
			// 	.then(success)
			// 	.catch(fail);

			vm.state = 'review';
			if (vm.invoices[index].quoteResponse.details.request.shipment.origin.additional_services && Array.isArray(vm.invoices[index].quoteResponse.details.request.shipment.origin.additional_services) && vm.invoices[index].quoteResponse.details.request.shipment.origin.additional_services.length > 0) {
				vm.invoices[index].quoteResponse.details.request.shipment.origin.additional_services = vm.populateAccessorialDescription(vm.invoices[index].quoteResponse.details.request.shipment.origin.additional_services);
			}

			// this needs changed somehow, when edit invoice actual amount and click accept or defer,
			// then come back to review again, ng-blur has to fire to enable buttons again. I don't like that.    ... me neither +1
			vm.totalActualAP = 0;
			vm.totalActualAR = 0;
			vm.totalExpectedAR = 0;

			indexBeingReviewed = index;

			vm.actualInvocieAPTotal = vm.invoices[index].actualAP;
			vm.totalExpectedAP = vm.invoices[index].expectedAP;

			// clone object in case user wants to cancel and go back to original invoice
			vm.invoiceToReview = _.cloneDeep(vm.invoices[index]);
			vm.invoiceToReview.organizationId = vm.invoiceToReview.quoteResponse.details.request.organization_id;
			if (!vm.invoiceToReview.origin_postal_code) {
				vm.invoiceToReview.origin_postal_code = vm.invoiceToReview.quoteResponse.origin_postal_code;
			}
			if (!vm.invoiceToReview.destination_postal_code) {
				vm.invoiceToReview.destination_postal_code = vm.invoiceToReview.quoteResponse.destination_postal_code;
			}

			var currentBillCopy = vm.invoiceToReview.quoteResponse.details.request.bill.details;

			/*
            * TODO : Lewis, Brad, Jesse
            *
            * Clean this up. I think the VM could probably just use properties off the
            * charge_item because it's already present? What we need:
            *
            * expected cost (AP) = charge_item.chksum  : charge_item.expectedAP?
            * expected customer charge (AR) = charge_item.amount : charge_item.expectedAR
            * */
			_.forEach(currentBillCopy.charges, function (charge_item) {
				charge_item.calculatedAR = 0; //TODO: replace with markup at appropriate spots
				if (!charge_item.category) {
					charge_item.category = "base_freight";
				}
				// if(charge_item.code !== 'ADJUSTMENT') {
				// 	// charge_item.expectedAP = charge_item.expectedAP;
				// 	// charge_item.expectedAR = charge_item.expectedAR;
				// }
				// if(vm.invoiceStatusExactMatchAmounts(index))
				// {
				// 	charge_item.actualAP = charge_item.chksum
				// charge_item.calculatedAR = charge_item.amount;
				// }
			});


			// _.forEach(vm.invoiceToReviewBill.charges, function (charge_item) {
			// 	charge_item.actualAP = charge_item.chksum;
			// 	charge_item.expectedAR = charge_item.amount;
			// });

			vm.orderItemsSummary = summarizeOrderItems(vm.invoiceToReview);
			vm.updateActualTotal(index);
			vm.sortItemsByExpectedAp();
			vm.getCategories();
		};

		vm.sortItemsByExpectedAp = function () {
			var sorted = _.sortBy(vm.invoiceToReview.quoteResponse.details.request.bill.details.charges, function (charge) {
				return -charge.expectedAP;
			});
			if (vm.invoiceToReview.quoteResponse.details.request.bill.details.charges.length === sorted.length) {
				vm.invoiceToReview.quoteResponse.details.request.bill.details.charges = sorted;
			} else {
				// Something went wrong, don't mess with it
			}
		};

		// invoice row click logic, only display review if valid invoice
		vm.displayReviewIfValid = function (index, previousState, displayErrors) {
			if (vm.invoiceValid(index, displayErrors)) {
				vm.previousState = previousState;
				vm.displayReview(index);
			}
		};

		vm.invoiceValid = function (index, displayErrors) {
			if (vm.invoices[index].actualAP) {
				if (vm.invoices[index].expectedAP) {
					if (!isNaN(vm.invoices[index].actualAP)) {
						return true;
					} else {
						displayErrors ? logger.error("Actual AP must be a number") : '';
						return false;
					}
				} else {
					displayErrors ? logger.error("Expected AP missing") : '';
					return false;
				}
			} else {
				displayErrors ? logger.error("Actual AP required") : '';
				return false;
			}
		};

		vm.localToleranceCheck = function (invoice) {
			var valueRation = invoice.actualAP / invoice.expectedAP;
			var valueDiff = invoice.actualAP - invoice.expectedAP;
			if (valueRation >= 1.05 || valueRation <= .95) {
				return false;
			} else return valueDiff <= Math.abs(valueDiff);
		};

		vm.decideExactMatch = function (invoice) {
			if (invoice.tolerance === true) {
				invoice.exactMatch = invoice.expectedAP === +invoice.actualAP;
			}
			else {
				invoice.exactMatch = false;
			}
		};

		vm.invoiceStatusInToleranceNotEqual = function (index) {
			if (vm.invoices[index].deferredFromDatabase === 1) {
				return vm.invoices[index].accepted === 0 && vm.invoices[index].tolerance === true && !vm.invoices[index].exactMatch;
			}
			return vm.invoices[index].tolerance === true && vm.invoices[index].exceptionApproved !== true && !vm.invoices[index].exactMatch;
		};

		vm.invoiceStatusExactMatchAmounts = function (index) {
			if (vm.invoices[index].deferredFromDatabase === 1) {
				return vm.invoices[index].accepted === 0 && vm.invoices[index].tolerance === true && vm.invoices[index].exactMatch;
			}
			return vm.invoices[index].tolerance === true && vm.invoices[index].exceptionApproved !== true && vm.invoices[index].exactMatch;
		};

		vm.invoiceStatusExceptionApproved = function (index) {
			if (vm.invoices[index].deferredFromDatabase === 1) {
				if(vm.previousState === 'invoice'){
					return vm.invoices[index].accepted === 1 && vm.invoices[index].exceptionApproved === true;
				}
				return vm.invoices[index].accepted === 1;
			} else {
				return  vm.invoices[index].exceptionApproved === true;
			}
		};

		vm.outOfTolerance = function (index) {
			if (vm.invoices[index].deferredFromDatabase === 1) {
				return vm.invoices[index].accepted === 0 && vm.invoices[index].tolerance === false;
			} else {
				return vm.invoices[index].tolerance === false;
			}

		};

		vm.toggleEditManifestNumber = function () {
			vm.editManifestNumber = !vm.editManifestNumber;
			vm.manifestNumber = null;
		};
		vm.toggle = function (invoice, list) {
			var index = list.indexOf(invoice);
			if (index > -1) {
				list.splice(index, 1);
			}
			else {
				list.push(invoice);
			}
		};

		vm.exists = function (invoice, list) {
			return list.indexOf(invoice) > -1;
		};

		vm.updateInvoiceStatus = function () {
			$http.post('/api/invoices/UpdateInvoiceStatus', vm.selectedDeferredInvoices)
				.then(success)
				.catch(fail);

			function success(response) {
				logger.success('Invoice Status Successfully Updated');
				vm.getDeferredInvoices();
			}

			function fail(e) {
				return logger.error('Failed to update Invoice Status');
			}
		};

		function calculateActualTotals() {
			vm.invoiceToReview.arActual = vm.invoiceToReview.quoteResponse.details.request.bill.details.total;
			var currentBillCopy = vm.invoiceToReview.quoteResponse.details.request.bill.details;
			var actualAmountTotal = 0;
			var arTotal = 0;

			for (var x = 0; x < currentBillCopy.charges.length; x++) {
				var chksum = 0;
				if (isNaN(currentBillCopy.charges[x].chksum)) {
					chksum = 0;
					currentBillCopy.charges[x].chksum = 0;
					logger.error('Actual Amount must be a Number');
				} else {
					chksum = +currentBillCopy.charges[x].chksum;
				}
				actualAmountTotal += chksum;
				// seems to have this regardless
				if (vm.invoiceToReview.overrideAr) {
					if (!currentBillCopy.charges[x].approvedAR) {
						currentBillCopy.charges[x].approvedAR = +currentBillCopy.charges[x].amount;
					}
					if (isNaN(+currentBillCopy.charges[x].approvedAR)) {
						currentBillCopy.charges[x].approvedAR = 0;
						logger.error('Override AR must be a Number');
					}
					arTotal += +currentBillCopy.charges[x].approvedAR;
				} else {
					arTotal += +currentBillCopy.charges[x].amount;
					currentBillCopy.charges[x].approvedAR = +currentBillCopy.charges[x].amount;
				}
				// set expecteds to zero if they don't exits on the object, mostly for minimum charges coming back
				if (currentBillCopy.charges[x].expectedAR === undefined) {
					currentBillCopy.charges[x].expectedAR = 0;
				}
				if (currentBillCopy.charges[x].expectedAP === undefined) {
					currentBillCopy.charges[x].expectedAP = 0;
				}
			}

			vm.totalActualAP = _.round(actualAmountTotal, 2);
			vm.totalActualAR = _.round(arTotal, 2);
			vm.invoiceToReview.arActualOveride = _.round(arTotal, 2);
			UpdateTotalExpectedValues(currentBillCopy);
		}

		vm.updateActualTotal = function () {
			function success(response) {
				vm.invoiceToReview.quoteResponse.details.request = response.data;
				calculateActualTotals();
			}

			function fail(e) {
				return logger.error('There was a problem getting markup: ' + e);
			}

			if ((vm.invoiceToReview.quoteResponse.is_manual === true || vm.invoiceToReview.quoteResponse.is_manual == 'true') || (vm.invoiceToReview.overrideAr === true || vm.invoiceToReview.overrideAr == 'true')) {
				console.log('manual calculation');
				calculateActualTotals();
			} else {
				console.log('auto calculation');
				let shipmentDetails = _.cloneDeep(vm.invoiceToReview.quoteResponse.details.request);
				shipmentDetails.pickup_date = vm.invoiceToReview.quoteResponse.pickup_date;
				return $http.post('/api/invmarkup/', shipmentDetails)
					.then(success)
					.catch(fail);
			}
		};

		vm.setOrderItemsToInvoice = function (invoice) {
			var item = vm.orderItemsSummary;
			// if null or wrong type, default to whats on quote
			invoice.items[0].actualContainerCount = (item[0].actualValue) ? +item[0].actualValue : +item[0].expectedValue;
			invoice.items[0].actualWeightTotal = (item[1].actualValue) ? +item[1].actualValue : +item[1].expectedValue;
			invoice.items[0].actualClass = (item[2].actualValue) ? item[2].actualValue : item[2].expectedValue;
			invoice.items[0].actualNmfc = (item[3].actualValue) ? item[3].actualValue : item[3].expectedValue;
			invoice.items[0].actualCubicFeetTotal = (item[4].actualValue) ? +item[4].actualValue : +item[4].expectedValue;

			//add items to bill Area
			if (invoice.quoteResponse && invoice.quoteResponse.details && invoice.quoteResponse.details.request && invoice.quoteResponse.details.request.bill) {
				if (!invoice.quoteResponse.details.request.bill.overrides) {
					invoice.quoteResponse.details.request.bill.overrides = {};
				}

				invoice.quoteResponse.details.request.bill.overrides.actualContainerCount = (item[0].actualValue) ? +item[0].actualValue : +item[0].expectedValue;
				invoice.quoteResponse.details.request.bill.overrides.actualWeightTotal = (item[1].actualValue) ? +item[1].actualValue : +item[1].expectedValue;
				invoice.quoteResponse.details.request.bill.overrides.actualClass = (item[2].actualValue) ? item[2].actualValue : item[2].expectedValue;
				invoice.quoteResponse.details.request.bill.overrides.actualNmfc = (item[3].actualValue) ? item[3].actualValue : item[3].expectedValue;
				invoice.quoteResponse.details.request.bill.overrides.actualCubicFeetTotal = (item[4].actualValue) ? +item[4].actualValue : +item[4].expectedValue;
			}
		};

		/**
		 * @return {boolean}
		 */
		vm.ItemsValid = function () {
			var charges = vm.invoiceToReview.quoteResponse.details.request.bill.details.charges;
			var descriptionError = 0;
			var categoryError = 0;
			_.each(charges, function (charge) {
				!charge.category ? categoryError += 1 : '';
				!charge.description ? descriptionError += 1 : '';
			});
			descriptionError > 0 ? logger.error("description required") : '';
			categoryError > 0 ? logger.error('Must select a category') : '';
			return categoryError === 0 && descriptionError === 0;
		};

		vm.acceptException = function () {
			if (vm.ItemsValid()) {
				vm.invoiceToReview.accepted = 1;
				(vm.previousState === 'deferred') ? vm.updateItems() : '';
				//set copy of edited invoice to the real invoice
				vm.invoices[indexBeingReviewed] = vm.invoiceToReview;
				vm.invoices[indexBeingReviewed].tolerance = true;
				vm.invoices[indexBeingReviewed].exceptionApproved = true;
				vm.invoices[indexBeingReviewed].deferred = false;
				//set actual items amounts
				vm.setOrderItemsToInvoice(vm.invoices[indexBeingReviewed]);
				(vm.previousState === 'deferred') ? vm.state = 'deferred' : vm.state = 'invoice';
				vm.invoices[indexBeingReviewed].indexBeingReviewed = -1;
				vm.availableCategories = [];
			}
		};

		vm.cancelReview = function () {
			vm.invoiceToReview = null;
			indexBeingReviewed = -1;
			vm.state = '';
		};

		vm.deferInvoice = function () {
			//set copy of edited invoice to the real invoice
			vm.invoices[indexBeingReviewed] = vm.invoiceToReview;
			vm.setOrderItemsToInvoice(vm.invoices[indexBeingReviewed]);
			deferThisInvoice(vm.invoices[indexBeingReviewed]);
			vm.state = 'invoice';
			indexBeingReviewed = -1;
			vm.availableCategories = [];
		};

		vm.updateInvoiceOrderItems = function () {
			function fail() {
				logger.error('Failed to Save invoice summary For Invoice items at Index: ' + indexBeingReviewed);
			}

			function success() {
				// logger.info('Saved ITEMS SUMMARY For Invoice # ' + vm.invoiceToReview.invoiceNumber);
				vm.invoices[indexBeingReviewed] = vm.invoiceToReview;
				vm.setOrderItemsToInvoice(vm.invoices[indexBeingReviewed]);
				vm.invoices[indexBeingReviewed].tolerance = vm.localToleranceCheck(vm.invoices[indexBeingReviewed]);
				vm.decideExactMatch(vm.invoices[indexBeingReviewed]);
				(vm.previousState === 'deferred') ? vm.state = 'deferred' : vm.state = 'invoice';
				vm.invoices[indexBeingReviewed].indexBeingReviewed = -1;
				vm.availableCategories = [];
			}

			vm.orderItemsSummary.override = vm.invoiceToReview.overrideAr;
			vm.setOrderItemsToInvoice(vm.invoiceToReview);
			//todo: get actualAP from items
			var newActualAR = 0;
			_.each(vm.invoiceToReview.quoteResponse.details.request.bill.details.charges, function (item) {
				(vm.invoiceToReview.overrideAr) ? newActualAR += +item.approvedAR : newActualAR += +item.amount;
			});
			vm.invoiceToReview.arActual = +newActualAR;

			$http.put('/api/invoices/', vm.invoiceToReview)
				.then(success)
				.catch(fail);
		};

		vm.updateItems = function () {
			if (vm.ItemsValid()) {
				if(+vm.totalActualAP !== +vm.actualInvocieAPTotal){
					vm.invoiceToReview.accepted = 0;
				}
				var currentInvoiceItems = [];
				var billCharges = vm.invoiceToReview.quoteResponse.details.request.bill.details.charges;
				for (var x = 0; x < billCharges.length; x++) {
					var itemActualAr = billCharges[x].amount;
					if (vm.invoiceToReview.overrideAr) {//vm.invoiceToReview.overrideAr is the check mark on review page
						itemActualAr = billCharges[x].approvedAR;
					}

					currentInvoiceItems.push(
						{
							'name': billCharges[x].description,
							// TODO also - Expected AP is chksum, expected AR is amount
							'actual_ar': itemActualAr,
							'actual_ap': billCharges[x].chksum,
							'expected_ar': billCharges[x].expectedAR,
							'expected_ap': billCharges[x].expectedAP,
							'code': billCharges[x].code,
							'invoice_id': vm.invoiceToReview.id,
							'category': billCharges[x].category
						}
					);
				}

				$http.put('/api/invoices/items', currentInvoiceItems)
					.then(success)
					.catch(fail);
			}

			function fail() {
				logger.error('Failed to Save ITEMS For Invoice items at Index: ' + indexBeingReviewed);
			}

			function success(response) {
				logger.info('Saved ITEMS For Invoice # ' + vm.invoiceToReview.invoiceNumber);
				vm.invoiceToReview.quoteResponse.details.request.bill.details.charges = response.data;
				vm.updateInvoiceOrderItems();
			}
		};

		vm.createInvoice = function (index) {
			if (vm.invoices[index].overrideAr) {
				vm.invoices[index].arActual = vm.invoices[index].arActualOveride;
			}
			var manifestNumber = null;
			if (vm.editManifestNumber) {
				manifestNumber = vm.manifestNumber;
			}

			var currentInvoiceItems = [];
			var billCharges = vm.invoices[index].quoteResponse.details.request.bill.details.charges;
			for (var x = 0; x < billCharges.length; x++) {
				var itemActualAr = billCharges[x].amount;
				if (vm.invoices[index].overrideAr) {
					itemActualAr = billCharges[x].approvedAR;
				}

				currentInvoiceItems.push(
					{
						'name': billCharges[x].description,
						// TODO also - Expeced AP is chksum, expected AR is amount
						'actual_ar': itemActualAr,
						'actual_ap': billCharges[x].chksum,
						'expected_ar': billCharges[x].expectedAR,
						'expected_ap': billCharges[x].expectedAP,
						'code': billCharges[x].code,
						'invoice_id': vm.invoices[index].id,
						'category': billCharges[x].category
					}
				);
			}

			var invoice = {
				'swNumber': vm.invoices[index].swNumber,
				'actualAP': vm.invoices[index].actualAP,
				'invoiceNumber': vm.invoices[index].invoiceNumber,
				'deferred': vm.invoices[index].deferred,
				'arActual': vm.invoices[index].arActual,
				'proNumber': vm.invoices[index].proNumber,
				'expectedAP': vm.invoices[index].expectedAP,
				'expectedAR': vm.invoices[index].expectedAR,
				'override': vm.invoices[index].overrideAr,
				'service_code': vm.invoices[index].quoteResponse.details.request.bill.service_code,
				'organizationId': vm.invoices[index].quoteResponse.details.request.organization_id,
				// should i be getting this info from the order.details.request.items?
				'totalContainers': vm.invoices[index].items[0].actualContainerCount,
				'totalWeight': vm.invoices[index].items[0].actualWeightTotal,
				'class': vm.invoices[index].items[0].actualClass,
				'nmfc': vm.invoices[index].items[0].actualNmfc,
				'cubicFeet': vm.invoices[index].items[0].actualCubicFeetTotal,
				'carrier_code': vm.invoices[index].quoteResponse.carrier_code,
				'manifestNumber': manifestNumber,
				'items': currentInvoiceItems,
				'origin_postal_code': vm.invoices[index].origin_postal_code,
				'destination_postal_code': vm.invoices[index].destination_postal_code
			};
			$http.post('/api/invoices', invoice)
				.then(success)
				.catch(fail);

			function success(response) {
				vm.invoices.splice(index, 1);
				if (vm.invoices.length === 1) {
					vm.toggleEditManifestNumber();
				}
			}

			function fail(err) {
				if (err.data.code === 'ER_DUP_ENTRY') {
					logger.error("Invoice with Order ID: " + vm.invoices[index].swNumber + " has already been submitted.");
				} else {
					logger.error("The invoices remaining could not be submitted for some reason, review logs.");
				}
			}
		};

		vm.submitDeferredInvoices = function () {
			// todo: update deferred flag , delete items by invoice id, save new items, create ar ap's
			for (var x = 0; x < vm.selectedDeferredInvoices.length; x++) {
				vm.updateDeferredInvoice(vm.selectedDeferredInvoices[x], x);
			}
		};

		vm.updateDeferredInvoice = function (deferredInvoice, index) {
			function fail() {
				logger.error('Failed to update Deferred invoice at index: ' + index);
			}

			function success(response) {
				if (response.data === "Success") {
					var currentDeferredInvoiceIndex = vm.selectedDeferredInvoices.indexOf(deferredInvoice);
					var currentInvoiceIndex = vm.invoices.indexOf(deferredInvoice);
					if (currentDeferredInvoiceIndex > -1) {
						vm.selectedDeferredInvoices.splice(index, 1); // remove from selection list
					}
					if (currentInvoiceIndex > -1) {
						vm.invoices.splice(index, 1); // remove from invoices list
					}

					logger.info('Updated Deferred invoice');

					// if all selected invoices were successful, reload and get new deferreds, if any.
					if (vm.selectedDeferredInvoices.length === 0) {
						vm.getDeferredInvoices();
					}
				} else {
					logger.error("Success but something did'nt go right...")
					// idk...catch other errors here
				}
			}

			$http.post('/api/invoices/deferred', deferredInvoice)
				.then(success)
				.catch(fail);
		};

		vm.createAllInvoices = function () {
			for (var i = vm.invoices.length - 1; i >= 0; i--) {
				if (isValidInvoice(vm.invoices[i])) {
					if (vm.invoices[i].tolerance !== true && vm.invoices[i].exceptionApproved !== true) {
						deferThisInvoice(vm.invoices[i])
					}
					vm.createInvoice(i);
				}
			}
		};

		vm.postItemCompleteActions = function (index) {
			if (+vm.invoices[index].actualAP && (+vm.invoices[index].actualAP !== +vm.invoices[index].actualAPRead)) {
				if (!isNaN(vm.invoices[index].actualAP)) {
					// remove invoice from submit list and uncheck if things change for the deferred invoice
					if (vm.state === 'deferred') {
						var invoiceLocation = vm.selectedDeferredInvoices.indexOf(vm.invoices[index]);
						(invoiceLocation > -1) ? vm.selectedDeferredInvoices.splice(invoiceLocation, 1) : '';
					}

					vm.checkForTolerance(index);

					if (vm.state === 'invoice') {
						vm.addNewLineIfNeeded();
					}
					vm.invoices[index].overrideAr = false;
				}
			}
			vm.invoices[index].actualAPRead = vm.invoices[index].actualAP;
		};

		function isValidInvoice(invoice) {
			if (!invoice.actualAP) return false;
			else if (!invoice.invoiceNumber) {
				logger.error('Invoice Number Required');
				return false;
			}
			else if (!invoice.swNumber && !invoice.proNumber) return false;
			else if (!invoice.expectedAP) return false;
			return true;
		}

		function createAccounts(payload) {
			$http.post('/api/accounts', payload)
				.then(success)
				.catch(fail);

			function success(response) {
				logger.info('Inserted invoice ' + payload.invoice_id + ' to AR');
			}

			function fail(e) {
				return logger.error('Failed to Add Invoice ' + payload.invoice_id + ' to AR')(e);
			}
		}

		function deferThisInvoice(invoice) {
			invoice.deferred = true;
			invoice.exceptionApproved = false;
			invoice.tolerance = false;
		}

		vm.validateType = function (index) {
			// allow class and nmfc to be strings
			if (index !== 2 && index !== 3) {
				if (isNaN(vm.orderItemsSummary[index].actualValue)) {
					logger.error('Must be a Number');
					vm.orderItemsSummary[index].actualValue = vm.orderItemsSummary[index].expectedValue;
				}
			}
		};

		function summarizeOrderItems(invoice) {

			var quoteItems = invoice.quoteResponse.details.request.shipment.line_items;
			var overrideItems = invoice.items;

			if (quoteItems.length === 0) {
				return [];
			}

			var containerCount = 0;
			var cubicFeetTotal = 0;
			var weightTotal = 0;

			for (var i = 0, len = quoteItems.length; i < len; i++) {
				containerCount += +quoteItems[i].piece_count;
				weightTotal += +quoteItems[i].estimated_weight;
				cubicFeetTotal += (+quoteItems[i].length * +quoteItems[i].width * +quoteItems[i].height) / 1728;
			}
			cubicFeetTotal = +cubicFeetTotal || 0;
			var result = [];
			result.push({
				description: 'Total Containers:',
				expectedValue: containerCount,
				actualValue: overrideItems[0].actualContainerCount || containerCount
			});

			result.push({
				description: 'Total Weight (lbs):',
				expectedValue: weightTotal,
				actualValue: overrideItems[0].actualWeightTotal || weightTotal
			});

			result.push({
				description: 'CL:',
				expectedValue: quoteItems[0].class,
				actualValue: overrideItems[0].actualClass || quoteItems[0].class
			});

			result.push({
				description: 'NMFC:',
				expectedValue: quoteItems[0].nmfc,
				actualValue: overrideItems[0].actualNmfc || quoteItems[0].nmfc
			});

			result.push({
				description: 'Cubic Feet:',
				expectedValue: cubicFeetTotal,
				actualValue: overrideItems[0].actualCubicFeetTotal || cubicFeetTotal
			});

			return result;
		}

		function clearInvoice(invoice) {
			invoice.quoteDetails = null;
			invoice.expectedAP = null;
			invoice.items = null;
			invoice.expectedAR = null;
			invoice.arActual = null;
			invoice.arActualOveride = null;
			invoice.actualAP = null;
			invoice.tolerance = null;
			invoice.exceptionApproved = null;
			invoice.exactMatch = null;
			invoice.invoiceNumber = null;
			(invoice.quoteResponse ) ? invoice.quoteResponse = null : ''
		}

		function clearProNumber(invoice) {
			invoice.proNumber = null;
			invoice.proNumberRead = null;
		}

		function clearSwNumber(invoice) {
			invoice.swNumber = null;
			invoice.swNumberRead = null;
		}

		function PostProSwLookup(response, invoice) {
			invoice.proNumber = response.data.pro_number;
			invoice.proNumberRead = response.data.pro_number;
			invoice.swNumber = response.data.id;
			invoice.swNumberRead = response.data.id;
			invoice.quoteDetails = response.data.details.request.quote.details;
			invoice.quoteResponse = response.data;
			invoice.expectedAP = response.data.details.request.quote.details.chksum;
			invoice.items = response.data.details.request.shipment.line_items;
			invoice.expectedAR = response.data.details.request.quote.details.total;
			invoice.actualAPRead = null;
			//todo: may need to change this placement
			invoice.carrier_code = response.data.carrier_code;
			invoice.is_manual = response.data.is_manual == 'true';
		}

		function markUpActualAr(invoice) {
			function success(response) {
				invoice.quoteResponse.details.request = response.data;
				invoice.arActual = invoice.quoteResponse.details.request.bill.details.total;
			}

			function fail(e) {
				return logger.error('There was a problem getting markup: ' + e);
			}

			invoice.quoteResponse.details.request.bill.details.chksum = invoice.actualAP;
			return $http.post('/api/invmarkup/', invoice.quoteResponse.details.request)
				.then(success)
				.catch(fail);
		}

		function UpdateTotalExpectedValues(currentBillCopy) {
			var expectedAR = 0;
			var expectedAP = 0;

			for (var x = 0; x < currentBillCopy.charges.length; x++) {
				// if (currentBillCopy.charges[x].code !== 'ADJUSTMENT') {
				// 	calcTotal += currentBillCopy.charges[x].expectedAR;
				// 	expectedAP += +currentBillCopy.charges[x].expectedAP;
				// } else {
				expectedAR += +currentBillCopy.charges[x].expectedAR;
				expectedAP += +currentBillCopy.charges[x].expectedAP;
				// }
			}
			vm.totalExpectedAR = _.round(expectedAR, 2);
			vm.totalExpectedAP = _.round(expectedAP, 2);

		}
	}
})();
