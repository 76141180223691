// import { json } from "body-parser";

// import { version } from "moment";

(function () {
	'use strict';

	angular
		.module('app.address')
		.controller('AddressController', AddressController);

	AddressController.$inject = ['$http', 'logger', 'currentUser', 'currentCustomer', 'addressDataservice', '$q', '$state', 'auth'];
	/* @ngInject */
	function AddressController($http, logger, currentUser, currentCustomer, addressDataservice, $q, $state, auth) {
		var vm = this;
		vm.title = $state.current.title;
		vm.id = $state.params.id;
		vm.currentUser = currentUser;
		// console.log(currentUser);
		// console.log(currentCustomer);
		vm.customer = {};
		if (+currentCustomer.id <= 0) {  // if empty, set it
			// console.log('non-zero org id', currentCustomer.id);
			currentCustomer.setCurrentCustomer(
				(!!vm.currentUser.organizationId) ? vm.currentUser.organizationId : null,
				(!!vm.currentUser.organizationName) ? vm.currentUser.organizationName : null
			)
		} // else assume passed in
		vm.customer.id = currentCustomer.id;
		vm.customer.name = currentCustomer.name;
		// console.log(currentUser);
		// console.log(vm.customer);

		activate();

		//////////////////// 

		function activate() {
			var promises = [];
			switch ($state.current.name) {
				case 'address.list':
					vm.addresses = [];
					vm.search = { term: '', shipping: true, destination: true, billTo: true, orgId: vm.customer.id };
					vm.organizations = [];
					vm.changeCustomer = changeCustomerList;
					vm.addressSearch = addressSearch;
					vm.formatAddress = formatAddress;
					vm.formatPhone = formatPhone;
					vm.removeAddress = removeAddress;
					promises.push(loadOrganizations());
					promises.push(loadAddresses());
					break;
				case 'address.create':
				case 'address.details':  // just to minimize lines of code, identical except for createAddress/updateAddress
					vm.address = {};
					vm.organizations = [];
					vm.validation = [];
					vm.isInternational = false;
					vm.changesMade = false;
					vm.changeCustomer = changeCustomerDetail;
					vm.updateAddress = updateAddress;
					vm.lookupCityDetails = lookupCityDetails;
					vm.isValid = isValid;
					vm.isChanged = isChanged;
					vm.addContact = addContact;
					vm.removeContact = removeContact;
					promises.push(loadOrganizations());
					if ($state.current.name === 'address.create') {
						promises.push(createAddress());
					} else if ($state.current.name === 'address.details') {
						promises.push(getAddressDetails());
					} else {
						break;  // shouldn't be any other options
					}
					break;
			}
			return $q.all(promises).then(function () {
			});
		}

		function initCustomer() {
			vm.customer.id = vm.address.organization_id;
			vm.customer.name = vm.address.orgname;
			currentCustomer.setCurrentCustomer(vm.customer.id, vm.customer.name);
		}
		
		function changeCustomerList() {
			vm.customer = (!!vm.customer) ? vm.customer : { };
			currentCustomer.setCurrentCustomer(vm.customer.id, vm.customer.name);
			loadAddresses();
		}
		function changeCustomerDetail() {
			vm.address.organization_id = vm.customer.id;
			vm.address.orgname = vm.customer.name;
			currentCustomer.setCurrentCustomer(vm.customer.id, vm.customer.name);
		}

	  function loadAddresses() {
			// customer.id !== 1 is a hack, systemUser(s) have org id = 1
			if ((!vm.customer.id) || (vm.customer.id === 1)) {
				// no customer/system user, get all
				return addressDataservice.getAddresses().then(function (data) {
				  vm.addresses = data;
				  _.each(vm.addresses, function (a) { breakOutContacts(a); });
					return vm.addresses;
				});
			} else {
				return addressDataservice.getAddressesByOrgId(vm.customer.id).then(function (data) {
					vm.addresses = data;
				  _.each(vm.addresses, function (a) { breakOutContacts(a); });
					return vm.addresses;
				});
			}
		return [];
		}

		function loadOrganizations() {
			if (vm.currentUser.isSystemUser) {
				if (vm.organizations) {
					if (vm.organizations.length === 0) {
						return $http.get('/api/organizations').then(function (response) {
							vm.organizations = response.data;
						}, function (response) {
							console.log('error loading organizations');
							console.log(response);
						});
					}
				}
			}
		}

	  function addressSearch() {
			vm.search.orgId = vm.customer.id;
			return addressDataservice.search(vm.search).then(function (data) {
				vm.addresses = data;
			  _.each(vm.addresses, function (a) { breakOutContacts(a); });
				return vm.addresses;
			});
		return [];
		}

		function createAddress() {
			// create an empty address object
			vm.address = {
				id: null,
				organization_id: vm.customer.id,
				location_id: '',
				name: '',
				address_1: '',
				address_2: '',
				city: '',
				state_code: '',
				postal_code: '',
				country: 'US ',
				contacts: '{ "contacts": [] }', // TODO  now just a json string
				is_shipping: 1,
				is_destination: 1,
				is_billto: 0,
				is_deleted: 0,
				contactsList: [],
			};
			insertBooleans(vm.address);
			return vm.address;
		}

		function getAddressDetails() {
			return addressDataservice.getAddressById(vm.customer.id, vm.id).then(function (data) {
				vm.address = data;
				initCustomer();
				breakOutContacts(vm.address);
				insertBooleans(vm.address);
				return vm.address;
			});
		}

		function updateAddress(address) {
			address.contacts = '{ "contacts": ' + JSON.stringify(address.contactsList) + "}";
			// remove the expedients before writing
			delete address.contactsList;
			delete address.shipping_boolean;
			delete address.destination_boolean;
			delete address.billto_boolean;
			delete address.orgname;
			if ((!address.id) || +address.id === 0) { // no id, it's an add
				return addressDataservice.createAddress(address).then(function (data) {
					logger.success('Address created: ' + address.name);
					$state.go('address.list', {}, { reload: true });
				});
			} else { // update
				return addressDataservice.updateAddress(address).then(function (data) {
					logger.success('Address updated: ' + address.name);
					$state.go('address.list', {}, { reload: true });
				});
			}
		}

		function removeAddress(id) {
			return addressDataservice.removeAddress(id).then(function (data) {
				logger.success('Address removed: ' + id);
				$state.go('address.list', {}, { reload: true });
			});
		}

		function breakOutContacts(address) {
			var c = [];
			if (!!address.contacts) {
				const jc = JSON.parse(address.contacts);
				if (!!jc) {
				  if (!!jc.contacts) {
						_.each(jc.contacts, function (i) { c.push(i); });
					}
				}
			}
			address.contactsList = c;
		}

		function addContact() {
			vm.address.contactsList.push(
				{ "name": "", "phone": "", "email": "", "fax": "" }
			);
		}
		function removeContact(i) {
			vm.address.contactsList.splice(i, 1);
			isValid();
		}

		function formatAddress(address) {
			// expects object w these members
			var formatted = '';
			formatted += (!!address.address_1) ? address.address_1 + '\r\n' : '';
			formatted += (!!address.address_2) ? address.address_2 + '\r\n' : '';
			formatted += (!!address.city) ? address.city : '';
			formatted += (!!address.state_code) ? ' ' + address.state_code : '';
			formatted += (!!address.postal_code) ? ' ' + address.postal_code : '';
			formatted += (!!address.city || !!address.state_code || !!address.postal_code) ? '\r\n' : '';
			formatted += (!!address.country && address.country !== 'USA') ? ' ' + address.country + '\r\n' : '';
			formatted += '(';
			formatted += (address.is_shipping) ? ' Shipping' : '';
			formatted += (address.is_shipping && address.is_destination) ? ',' : '';
			formatted += (address.is_destination) ? ' Destination' : '';
			formatted += ((address.is_shipping || address.is_destination) && address.is_billto) ? ',' : '';
			formatted += (address.is_billto) ? ' BillTo' : '';
			formatted += ')';
			return formatted;
		}

		function insertBooleans(address) {
			address.shipping_boolean = (!!address.is_shipping);
			address.destination_boolean = (!!address.is_destination);
			address.billto_boolean = (!!address.is_billto);
		}

		function isValid() {
			isChanged();
			vm.validation = [];

			if (!vm.address.organization_id ||
				(vm.address.organization_id === 1)) {
				vm.validation.push('A customer must be selected');
			}
			if (!vm.isInternational) {
				if ((!vm.address.postal_code) || (vm.address.postal_code.length < 2)) {
					vm.validation.push('A zip code is required');
				}
				if ((!vm.address.state_code) || (vm.address.state_code.length < 2)) {
					vm.validation.push('A state or province is required');
				}
			}
			if ((!vm.address.address_1) || (vm.address.address_1.length < 2)) {
				vm.validation.push('An address is required');
			}
			if ((!vm.address.city) || (vm.address.city.length < 2)) {
				vm.validation.push('A city is required');
			}
			if (vm.address.contactsList.length < 1) {
				vm.validation.push('At least one contact is required');
			} else {
				if ((!vm.address.contactsList[0].name) || (vm.address.contactsList[0].name.length < 3)) {
					vm.validation.push('A contact name is required');
				}
				if (!!vm.address.contactsList[0].email) {
					// check format
					if (!isValidEmail(vm.address.contactsList[0].email)) {
						vm.validation.push('Contact email format is not valid');
					}
				}
				if ((!vm.address.contactsList[0].phone) || (vm.address.contactsList[0].phone.length < 3)) {
					vm.validation.push('A contact phone is required');
				}
			}

			return vm.validation.length === 0;
		}

		function isValidEmail(email) {
			var valid = true;
			valid = (!!email);
			valid = (valid) ? email.indexOf('@') : valid;
			valid = (valid) ? email.indexOf('.') : valid;
			valid = (valid) ? (email.indexOf('.') < email.length) : valid;
			valid = (valid) ? (email.indexOf('@') < email.lastIndexOf('.')) : valid;
			return valid;
		}

		function isChanged() {
			vm.changesMade = true;
		}

		function formatPhone(tel) {
			if (!tel) { return ''; }

			if (vm.isInternational) return tel;

			var value = tel.toString().trim().replace(/^\+/, '');
			if (value.match(/[^0-9]/)) {
				return tel;
			}
			var country, city, number;
			switch (value.length) {
				case 1:
				case 2:
				case 3:
					city = value;
					break;

				default:
					city = value.slice(0, 3);
					number = value.slice(3);
			}
			if (number) {
				if (number.length > 3) {
					number = number.slice(0, 3) + '-' + number.slice(3, 7);
					return ("(" + city + ") " + number).trim();
				}
				else {
					return ("(" + city + ") " + number).trim();
				}
			} else {
				return "(" + city;
			}
		};

		function formatContacts(contacts) {
			// excpects json like  { "contacts": [ { "name": "example name", "phone": "1231231234", "email": "a@b.c", "fax": "1231231234" } ] }
			var f = JSON.parse(contacts);
		  var formatted = '';

		  _.each(f.contacts, function (c) {
				formatted += c.name;
				formatted += (!!c.phone) ? ', ' + formatPhone(c.phone) : '';
				formatted += (!!c.email) ? ', ' + c.email : '';
				// formatted += (!!c.fax) ? ', ' + c.fax : '';
				formatted += '\r\n';
		  });

			return formatted;

		  return '';
		}

		function lookupCityDetails(address) {
			if (!vm.isInternational) {
				if (address.postal_code) {
					var zip = address.postal_code.replace("-", "");
					if (zip.length === 5 && /^\d+$/.test(zip)) {
						$http.get('/api/utility/city-lookup/' + zip).then(function successCallback(response) {
							if (response.data) {
								address.city = response.data.city;
								address.state_code = response.data.state;
							}
						});
					}
				}
			}
		}
	}

})();

