(function() {
  'use strict';

  angular
    .module('app.bolEntry')
    .run(appRun);

  appRun.$inject = ['routerHelper'];
  /* @ngInject */
  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [
			{
				state: 'bolEntry',
				config: {
					url: '/BOL',
					templateUrl: 'app/areas/bolEntry/bolEntry.html',
					controller: 'BolEntryController',
					controllerAs: 'vm',
					title: 'BOL Entry'
				}
			},
			{
				state: 'bolEntry-quote',
				config: {
					url: '/BOL/:id/quote',
					templateUrl: 'app/areas/bolEntry/bolEntry.html',
					controller: 'BolEntryController',
					controllerAs: 'vm',
					title: 'BOL Quote Entry'
				}
			},
			{
				state: 'bolEntry-edit',
				config: {
					url: '/BOL/:id/edit',
					templateUrl: 'app/areas/bolEntry/bolEntry.html',
					controller: 'BolEntryController',
					controllerAs: 'vm',
					title: 'BOL Quote Entry'
				}
			},
			{
				state: 'bolEntry-clone',
				config: {
					url: '/BOL/:id/clone',
					templateUrl: 'app/areas/bolEntry/bolEntry.html',
					controller: 'BolEntryController',
					controllerAs: 'vm',
					title: 'BOL Quote Entry'
				}
			},
			{
				state: 'bolEntry-quote-print',
				config: {
					url: '/BOL/:id/quote',
					templateUrl: 'app/areas/bolEntry/bolEntry-quote.html',
					controller: 'BolEntryController',
					controllerAs: 'vm',
					title: 'BOL Quote',
				}
	  },
	  {
		state: 'bolEntry-order-quote',
		config: {
		  url: '/order/:id/quote',
		  templateUrl: 'app/areas/bolEntry/bolEntry-order-quote.html',
		  title: 'Order Quote',
		  controller: 'BolEntryController',
		  controllerAs: 'vm',
		}
	  }
    ];
  }
})();
