(function () {
	'use strict';

	angular
		.module('app.address')
		.factory('addressDataservice', addressDataservice);

	addressDataservice.$inject = ['$http', '$q', 'exception', 'logger'];
	/* @ngInject */
	function addressDataservice($http, $q, exception, logger) {

		var service = {
			getAddresses: getAddresses,
			getAddressById: getAddressById,
			getAddressesByOrgId: getAddressesByOrgId,
			match: match,
			exactMatch: exactMatch,
			search: search,
			createAddress: createAddress,
			removeAddress: removeAddress,
			updateAddress: updateAddress,
		};
		return service;


		function getAddresses() {
			return $http.get('/api/addresses') 
				.then(success)
				.catch(fail);

			function success(response) {
				// console.log('addresses', response.data);
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getAddresses')(e);
			}
		}


		function getAddressById(orgid, addrid) {
			return $http.get('/api/addresses/' + orgid + '/' + addrid)
				.then(success)
				.catch(fail);

			function success(response) {
				// console.log(response.data);
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getAddressById')(e);
			}
		}

		function getAddressesByOrgId(id) {
			return $http.get('/api/addresses/' + id)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				if (e === 'not found') { return {} } else {
					return exception.catcher('XHR Failed for getAddressesByOrgId')(e);
				}
			}
		}

		function match(search) {
			return $http.post('/api/addresses/match', search)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for match')(e);
			}
		}
		function exactMatch(search) {
			return $http.post('/api/addresses/verify', search)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for exactMatch')(e);
			}
		}
		function search(search) {
			return $http.post('/api/addresses/search', search)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for search')(e);
			}
		}

		function createAddress(address) {
			// $http.body = address;
			return $http.post('/api/addresses/', address).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for createAddress')(e);
			}
		}

		function removeAddress(id) {

			return $http.delete('/api/addresses/' + id).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for removeAddress')(e);
			}
		}

		function updateAddress(address) {
			return $http.put('/api/addresses/' + address.id, address).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for updateAddress')(e);
			}
		}

	}
})();
