(function () {
	'use strict';

	angular.module('app', [
		'app.core',
		'app.components',
		'app.filters',
		'app.areas',
		'app.directives'
	]).config(configure)
		.run(run);


	configure.$inject = ['$httpProvider'];
	/* @ngInject */
	function configure($httpProvider) {
		// The authInterceptor adds JWT headers to our server calls
		$httpProvider.interceptors.push('authInterceptor');
	}


	run.$inject = ['$rootScope', '$state', 'currentUser'];
	/* @ngInject */
	function run($rootScope, $state, currentUser) {
		/**
		 * This hooks into the angular ui-router and makes sure the user is authenticated before allowing
		 * navigation to a non-public page. By default all routes require authentication. Some routes are
		 * for admins or superusers only.
		 *
		 * To allow a page/state to bypass authentication set `public: true,` on the route configuration.
		 * To require admin privileges on a page, set `adminsOnly: true,` on the route configuration.
		 * To require superuser privileges on a page, set `supersOnly: true,` on the route configuration.
		 */
		$rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
			if (!toState.public && !currentUser.isLoggedIn()) {
				$state.transitionTo("profile.login");
				event.preventDefault();
			}
			if (toState.adminsOnly && !(currentUser.isAdmin || currentUser.isSystemUser)) {
				$state.transitionTo("404");
				event.preventDefault();
			}
			if (toState.supersOnly && !currentUser.isSuper) {
				$state.transitionTo("404");
				event.preventDefault();
			}
		});
	}


})();
