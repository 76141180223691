(function () {
	'use strict';

	angular
		.module('app.markup')
		.run(appRun);

	appRun.$inject = ['routerHelper'];
	/* @ngInject */
	function appRun(routerHelper) {
		routerHelper.configureStates(getStates());
	}

	// handy article on skinny controllers
	// https://scotch.io/tutorials/making-skinny-angularjs-controllers

	function getStates() {
		return [
			{
				state: 'markup',
				config: {
					url: '',
					abstract: true,
					template: '<ui-view/>',
					adminsOnly: true,
				}
			},
			{
				state: 'markup.list',
				config: {
					url: '/markup',
					templateUrl: 'app/areas/markup/markup-list.html',
					title: 'Markups',
					controller: 'MarkupController',
					controllerAs: 'vm',
				}
			},
			{
				state: 'markup.create',
				config: {
					url: '/markup/create',
					templateUrl: 'app/areas/markup/markup-details.html',
					title: 'Create New Markup',
					controller: 'MarkupController',
					controllerAs: 'vm',
				}
			},
			{
				state: 'markup.details',  // editor page
				config: {
					url: '/markup/:id',
					templateUrl: 'app/areas/markup/markup-details.html',
					title: 'Markup Details',
					controller: 'MarkupController',
					controllerAs: 'vm',
				}
			}
		];
	}
})();
