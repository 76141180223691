(function () {
	'use strict';

	angular
		.module('app.filters')
		.filter('userRole', userRole);

	function userRole() {

		return userRoleFilter;

		function userRoleFilter(input) {
			switch (input) {
				case 255: return 'Superuser';
				case 120: return 'Admin';
				case 60:  return 'User';
				default:  return 'Guest';
			}
		}
	}

}());
