(function () {
	"use strict";

	var module = angular.module("app.components");

	// function controller($http) {
	function controller() {
		/*jshint validthis: true */
		var vm = this;
	}

	module.component("pageHeader", {
		bindings: {
			heading: "<?",
			subheading: "<?",
			icon: "<?",
			tooltip: "<?",
		},
		controllerAs: "vm",
		controller: [controller],
		transclude: true,
		templateUrl: "app/components/layout/pageHeader.component.html"
	});

}());
