(function () {
  'use strict';

  angular
    .module('app.layout')
    .controller('ShellController', ShellController);

  ShellController.$inject = ['logger', 'currentUser'];
  /* @ngInject */
  function ShellController(logger, currentUser) {
    var vm = this;

    vm.currentUser = currentUser;



    activate();
    ////////////////////////

    function activate() {
			//add any code here to execute when the application launches
    }

  }
})();
