(function () {
	'use strict';

	angular
		.module('app.admin')
		.controller('AdminSystemCarrierController', AdminSystemCarrierController);

	AdminSystemCarrierController.$inject = ['logger', 'currentUser', 'adminSystemCarrierDataservice', '$q', '$state', '$uibModal', 'auth', '$window', '$stateParams'];
	/* @ngInject */
	function AdminSystemCarrierController(logger, currentUser, adminSystemCarrierDataservice, $q, $state, $uibModal, auth, $window, $stateParams) {
		var vm = this;
		vm.title = $state.current.title;
		vm.currentUser = currentUser;
		vm.carriers = [];
		vm.carrierType = ["LTL","PCL"];
		vm.page = {
			icon: 'users',
			heading: 'System Carriers',
			tooltip: ''
		};
		vm.createUpdateCarrier = createUpdateCarrier;
		vm.createCarrier = createCarrier;
		vm.removeCarrier = removeCarrier;
		vm.newCarrier = { active: 1, type: 'LTL' };
		vm.entryFeedback = [];

		if($stateParams && $stateParams.id && !isNaN($stateParams.id)){
			getCarrier($stateParams.id);
		}

		activate();

		////////////////////

		function activate() {
			var promises = [loadAll()];
			return $q.all(promises).then(function () {
				// logger.info('Activated Admin Accounts View');
			});
		}

		function loadAll() {
			return adminSystemCarrierDataservice.get().then(function (data) {
				vm.carriers = data;
				return vm.carriers;
			});
		}

		function getCarrier(id) {
			return adminSystemCarrierDataservice.getById(id).then(function (data) {
				vm.newCarrier = data;
				return vm.newCarrier;
			});
		}

		function createUpdateCarrier() {
			cleanUpForm();
			if(!validateForm())
				return;

			if(vm.newCarrier.id && !isNaN(vm.newCarrier.id)){
				updateCarrier();
			}else{
				createCarrier();
			}
		}	

		function cleanUpForm() {
			if(vm.newCarrier.type === 'LTL'){
				vm.newCarrier.account_id = null;
				vm.newCarrier.access_key = null;
				if(vm.newCarrier.is_p44 === 0){
					vm.newCarrier.custom = null;
				}
			} else {
				vm.newCarrier.custom = null;
				vm.is_p44 = false;
			}
		}
		
		function validateForm() {
			vm.entryFeedback = [];

			if(!vm.newCarrier.name || vm.newCarrier.name.length === 0)
				vm.entryFeedback.push('Name is required');			

			if(!vm.newCarrier.code || vm.newCarrier.code.length === 0)
				vm.entryFeedback.push('Code is required');

			if(vm.newCarrier.type === 'LTL') { // LTL
				if(vm.newCarrier.is_p44 === 1){
					if(!vm.newCarrier.custom || vm.newCarrier.custom.length === 0)
						vm.entryFeedback.push('P44 Shippers require a SCAC');
				} else {

				}
			}else{ // Parcels

			}

			return vm.entryFeedback.length === 0;
		}

		function createCarrier() {
			if(vm.newCarrier && vm.newCarrier.name && vm.newCarrier.name.length > 0) {
				return adminSystemCarrierDataservice.create(vm.newCarrier).then(function (data) {
					if(data) {
						logger.success('System Carrier created: ' + data.name);
						$state.go('admin.syscarrier.list', null, {reload: true});
					}
				});
			} else {
				logger.error('Name cannot be left blank');
			}
			vm.newUser = {};
		}

		function updateCarrier() {
			if(vm.newCarrier && vm.newCarrier.name && vm.newCarrier.name.length > 0) {
				return adminSystemCarrierDataservice.update(vm.newCarrier).then(function (data) {
					if(data) {
						logger.success('System Carrier updated: ' + data.name);
						$state.go('admin.syscarrier.list', null, {reload: true});
					}
				});
			} else {
				logger.error('Name cannot be left blank');
			}
			vm.newUser = {};
		}

		function removeCarrier(carrier) {
			return adminSystemCarrierDataservice.removeCarrier(carrier).then(function (data) {
				logger.success('Carrier removed: ' + carrier.name);
				$state.go('admin.syscarrier.list', null, {reload: true});
			});
		}
	}
})();
