(function () {
	'use strict';

	angular
		.module('app.dashboard')
		.run(appRun);

	appRun.$inject = ['routerHelper'];
	/* @ngInject */
	function appRun(routerHelper) {
		routerHelper.configureStates(getStates());
	}

	function getStates() {
		return [
			{
				state: 'dashboard',
				config: {
					url: '/?status',
					templateUrl: 'app/areas/dashboard/dashboard.html',
					title: 'Home',
					controller: 'DashboardController',
					controllerAs: 'vm'
				}
			},
			{
				state: 'dashboard-test',
				config: {
					url: '/order/test',
					template: '<h5>testing</h5>',
					title: 'Test',
					controller: 'DashboardController',
					controllerAs: 'vm'
				}
			},
			{
				state: 'dashboard-details',
				config: {
					url: '/order/:id',
					templateUrl: 'app/areas/dashboard/dashboard-detail.html',
					title: 'Order Details',
					controller: 'DashboardController',
					controllerAs: 'vm',
				}
			},
			{
				state: 'dashboard-order-labels-all',
				config: {
					url: '/order/:id/labels',
					templateUrl: 'app/areas/dashboard/dashboard-order-labels-all.html',
					title: 'Order Labels',
					controller: 'DashboardController',
					controllerAs: 'vm',
				}
			},
			{
				state: 'dashboard-order-quote',
				config: {
					url: '/order/:id/quote',
					templateUrl: 'app/areas/dashboard/dashboard-order-quote.html',
					title: 'Order Quote',
					controller: 'DashboardController',
					controllerAs: 'vm',
				}
			},
			{
				state: 'dashboard-order-label',
				config: {
					url: '/order/:id/labels/:labelNumber',
					templateUrl: 'app/areas/dashboard/dashboard-order-label.html',
					title: 'Order Labels',
					controller: 'DashboardController',
					controllerAs: 'vm',
					public: true
				}
			},
			{
				state: 'dashboard-bol-print',
				config: {
					url: '/order/:id/bol',
					templateUrl: 'app/areas/dashboard/dashboard-order-bol.html',
					title: 'BOL',
					controller: 'DashboardController',
					controllerAs: 'vm',
				}
			},
			{
				state: 'dashboard-shipping-pallet-label-print-avery',
				config: {
					url: '/order/:id/palletLabels/avery',
					templateUrl: 'app/areas/dashboard/dashboard-order-pallet-labels.html',
					title: 'Order Labels',
					controller: 'DashboardController',
					controllerAs: 'vm'
				}
			},
			{
				state: 'rlms-shipping-pallet-label-print-avery',
				config: {
					url: '/rlms/order/:id/palletLabels/avery',
					templateUrl: 'app/areas/dashboard/dashboard-order-pallet-labels.html',
					title: 'RLMS Order Labels',
					controller: 'DashboardController',
					controllerAs: 'vm',
					public: true,
				}
			},
			{
				state: 'dashboard-shipping-pallet-label-print-full',
				config: {
					url: '/order/:id/palletLabels/full',
					templateUrl: 'app/areas/dashboard/dashboard-order-pallet-labels.html',
					title: 'Order Labels',
					controller: 'DashboardController',
					controllerAs: 'vm'
				}
			},
			{
				state: 'rlms-shipping-pallet-label-print-full',
				config: {
					url: '/rlms/order/:id/palletLabels/full',
					templateUrl: 'app/areas/dashboard/dashboard-order-pallet-labels.html',
					title: 'RLMS Order Labels',
					controller: 'DashboardController',
					controllerAs: 'vm',
					public: true,
				}
			},
			{
				state: 'rlms-bol-print',
				config: {
					url: '/rlms/order/:id/bol',
					templateUrl: 'app/areas/dashboard/rlms-order-bol.html',
					title: 'RLMS BOL',
					controller: 'DashboardController',
					controllerAs: 'vm',
					public: true,
				}
			},
			{
				state: 'rlms-order-labels-all',
				config: {
					url: '/rlms/order/:id/labels',
					templateUrl: 'app/areas/dashboard/rlms-order-labels-all.html',
					title: 'RLMS Order Labels',
					controller: 'DashboardController',
					controllerAs: 'vm',
					public: true,
				}
			},
			{
				state: 'rlms-order-label',
				config: {
					url: '/rlms/order/:id/labels/:labelNumber',
					templateUrl: 'app/areas/dashboard/rlms-order-label.html',
					title: 'RLMS Order Labels',
					controller: 'DashboardController',
					controllerAs: 'vm',
					public: true,
				}
			},
			{
				state: 'supplier-shipping-pallet-label-print-avery',
				config: {
					url: '/supplier/order/:id/palletLabels/avery',
					templateUrl: 'app/areas/dashboard/dashboard-order-pallet-labels.html',
					title: 'Supplier Order Labels',
					controller: 'DashboardController',
					controllerAs: 'vm',
					public: true,
				}
			},
			{
				state: 'supplier-shipping-pallet-label-print-full',
				config: {
					url: '/supplier/order/:id/palletLabels/full',
					templateUrl: 'app/areas/dashboard/dashboard-order-pallet-labels.html',
					title: 'Supplier Order Labels',
					controller: 'DashboardController',
					controllerAs: 'vm',
					public: true,
				}
			},
			{
				state: 'supplier-bol-print',
				config: {
					url: '/supplier/order/:id/bol',
					templateUrl: 'app/areas/dashboard/supplier-order-bol.html',
					title: 'Supplier BOL',
					controller: 'DashboardController',
					controllerAs: 'vm',
					public: true,
				}
			},
			{
				state: 'supplier-order-labels-all',
				config: {
					url: '/supplier/order/:id/labels',
					templateUrl: 'app/areas/dashboard/supplier-order-labels-all.html',
					title: 'Supplier Order Labels',
					controller: 'DashboardController',
					controllerAs: 'vm',
					public: true,
				}
			},
			{
				state: 'supplier-order-label',
				config: {
					url: '/supplier/order/:id/labels/:labelNumber',
					templateUrl: 'app/areas/dashboard/supplier-order-label.html',
					title: 'Supplier Order Labels',
					controller: 'DashboardController',
					controllerAs: 'vm',
					public: true,
				}
			}
		];
	}
})();
