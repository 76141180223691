(function () {
	"use strict";

	var module = angular.module("app.components");

	/* @ngInject */
	function controller() {
		/*jshint validthis: true */
		var vm = this;

		// set defaults
		vm.icon = false;
		vm.title = false;
		vm.cssClass = 'default';
		vm.isCollapsible = true;
		vm.collapsed = false;
		vm.footer = false;

		// click events
		vm.toggle = function () { vm.collapsed = !vm.collapsed; };

	}

	module.component("panel", {
		bindings: {
			icon: "<?",
			title: "<",
			cssClass: "<?",
			isCollapsible: "<?",
			collapsed: "<?",
			footer: "<?",
		},
		controllerAs: "vm",
		controller: [controller],
		transclude: true,
		templateUrl: "app/components/layout/panel.component.html"
	});

}());
