(function () {
	'use strict';

	angular
		.module('app.profile')
		.factory('ProfileDataservice', ProfileDataservice);

	ProfileDataservice.$inject = ['$http', '$q', 'exception', 'logger', '$window'];
	/* @ngInject */
	function ProfileDataservice($http, $q, exception, logger, $window) {
		var service = {
			siteurl: $window.location.origin,
			login: login,
			changePassword: changePassword
		};

		if (!window.location.origin) {
			window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
			service.siteurl = window.location.origin;
		}

		return service;

		/////////////////////

		// nice overview of $http and promises in angular
		// http://davidcai.github.io/blog/posts/angular-promise/

		function login(username, password) {
			return $http.post(service.siteurl + '/api/auth/token', {
				username: username,
				password: password
			}).then(loginSuccess).catch(loginFailed);

			function loginSuccess(response, status, headers, config) {
				return response.data;
			}

			function loginFailed(response) {
				return $q.reject(response);
			}
		}

		function changePassword(userId, newPassword) {
			return $http.put(service.siteurl + '/api/users/' + userId + '/password', {
				newPassword: newPassword
			}).then(changePasswordSuccess).catch(changePasswordFailed);

			function changePasswordSuccess(response, status, headers, config) {
				return response.data;
			}

			function changePasswordFailed(response) {
				return $q.reject(response);
			}
		}



	}
})();
