(function () {
	'use strict';

	angular
		.module('app.admin')
		.controller('AdminSystokenController', AdminSystokenController);

	AdminSystokenController.$inject = ['logger', 'currentUser', 'adminSysTokenDataservice', '$q', '$state', '$uibModal', 'auth', '$window'];
	/* @ngInject */
	function AdminSystokenController(logger, currentUser, adminSysTokenDataservice, $q, $state, $uibModal, auth, $window) {
		var vm = this;
		vm.title = $state.current.title;
		vm.tokens = [];
		vm.page = {
			icon: 'server',
			heading: 'System Tokens',
			tooltip: ''
		};
		vm.create = create;
		vm.showTokenDetails = showTokenDetails;
		vm.testToken = testToken;

		activate();

		////////////////////

		function activate() {
			var promises = [loadSystemTokens()];
			return $q.all(promises).then(function () {
				// logger.info('Activated Admin Accounts View');
			});
		}

		function loadSystemTokens() {
			return adminSysTokenDataservice.getSystemTokens().then(function (data) {
				vm.tokens = data;
				return vm.tokens;
			});
		}

		function create() {
			return adminSysTokenDataservice.getNewSystemToken().then(function (data) {
				logger.info('System token created: ' + data.name);
				vm.tokens.unshift(data);
				return data;
			});
		}

		function testToken(token, tokenName) {
			return adminSysTokenDataservice.testSystemToken(token).then(function (data) {
				logger.success('Success! Token was accepted: ' + tokenName);
			}).catch(function (e) {
				logger.error('Error! Token was denied: ' + tokenName);
			});

		}

		function showTokenDetails(token) {
			var tokenData = auth.parseJwt(token);
			// console.log(tokenData);
			var expiration = moment.unix(tokenData.exp).toString();
			var phpExample = getPhpCodeExample($window.location.origin, token);
			var modalInstance = $uibModal.open({
				backdrop: 'static',
				component: 'basicModal',
				windowClass: 'modal-wide',
				resolve: {
					config: function () {
						return {
							showOk: true, // false to hide the OK button
							showCancel: false, // true if you want to show a cancel button...
							bodyTemplateUrl: 'app/areas/admin/systoken/modalBody.html' // false to display data.content using ngBindHtml on a nested div
						};
					},
					data: function () {
						return {
							title: 'API Token',
							expiration: expiration,
							token: token,
							phpExample: phpExample
						};
					}
				}
			});
			// If you want to know if they clicked Ok or Cancel
			// }).result.then(function (ok) {
			// 	// ok was clicked
			// }, function () {
			// 	// cancel was clicked
			// });
		}

		function getPhpCodeExample(baseurl, token) {
			var str = '';
			str += '<?php' + '\n';
			str += '$ch = curl_init();' + '\n';
			str += '\n';
			str += '// The /api/ping endpoint allows callers to check their token' + '\n';
			str += 'curl_setopt($ch, CURLOPT_URL, "' + baseurl + '/api/ping");' + '\n';
			str += '\n';
			str += '// Populate the Authorization header with JWT token' + '\n';
			str += '$authorizationHeader = "Authorization: JWT ' + token + '";' + '\n';
			str += 'curl_setopt($ch, CURLOPT_HTTPHEADER, array($authorizationHeader));' + '\n';
			str += '\n';
			str += 'curl_setopt($ch, CURLOPT_RETURNTRANSFER, 1);                // return the transfer as a string' + '\n';
			str += '$result = curl_exec($ch);                                   // Call the api' + '\n';
			str += '$responseHttpCode = curl_getinfo($ch, CURLINFO_HTTP_CODE);  // Capture the HTTP response code' + '\n';
			str += '\n';
			str += 'if ($responseHttpCode == 200) {' + '\n';
			str += '  $resultObj = json_decode($result, true);                  // Decode the result JSON into a PHP object' + '\n';
			str += '  print_r($resultObj);' + '\n';
			str += '} else {' + '\n';
			str += '  echo "Oops! Request failed.";' + '\n';
			str += '  print_r(curl_getinfo($ch));' + '\n';
			str += '}' + '\n';
			str += 'curl_close($ch);                                            // close curl resource to free up system resources' + '\n';
			str += '\n';
			str += '// eof' + '\n';
			return str;
		}

	}

})();
