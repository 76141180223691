(function () {
	'use strict';

	angular
		.module('app.areas', [
			'app.admin',
			'app.dashboard',
			'app.layout',
			'app.organization',
			'app.address',
			'app.products',
			'app.markup',
			'app.proEntry',
			'app.profile',
		  'app.bolEntry',
			'app.rateEstimate',
			'app.report',
			'app.invoiceReport',
			'app.accrualReport',
			'app.qbErrorReport',
			'app.weightReport'
		]);
})();
