(function () {
	'use strict';

	angular
		.module('app.products')
		.factory('productsDataservice', productsDataservice);

	productsDataservice.$inject = ['$http', '$q', 'exception', 'logger'];
	/* @ngInject */
	function productsDataservice($http, $q, exception, logger) {

		var service = {
			getProducts: getProducts,
			getProductById: getProductById,
			getProductsByOrgId: getProductsByOrgId,
			match: match,
			exactMatch: exactMatch,
			search: search,
			createProduct: createProduct,
			removeProduct: removeProduct,
			updateProduct: updateProduct,
		};
		return service;


		function getProducts() {
			return $http.get('/api/product')
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getProducts')(e);
			}
		}


		function getProductById(orgid, addrid) {
			return $http.get('/api/product/' + orgid + '/' + addrid)
				.then(success)
				.catch(fail);

			function success(response) {
				console.log(response.data);
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getProductById')(e);
			}
		}

		function getProductsByOrgId(id) {
			return $http.get('/api/product/' + id)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				if (e === 'not found') { return {} } else {
					return exception.catcher('XHR Failed for getProductsByOrgId')(e);
				}
			}
		}

		function match(search) {
			return $http.post('/api/product/match', search)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for match')(e);
			}
		}
		function exactMatch(search) {
			return $http.post('/api/product/verify', search)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for exactMatch')(e);
			}
		}		
		function search(search) {
			return $http.post('/api/product/search', search)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for search')(e);
			}
		}

		function createProduct(product) {
			// $http.body = product;
			return $http.post('/api/product/', product).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for createProduct')(e);
			}
		}

		function removeProduct(id) {

			return $http.delete('/api/product/' + id).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for removeProduct')(e);
			}
		}

		function updateProduct(product) {
			return $http.put('/api/product/' + product.id, product).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for updateProduct')(e);
			}
		}

	}
})();
