(function () {
	'use strict';

	var module = angular.module('app.components');

	// /* @ngInject */
	// function controller($http) {
	function controller($state) {

		/*jshint validthis: true */
		var vm = this;
		vm.labelNumber = $state.params.labelNumber;

		// Handle component lifecycle events
		vm.$onInit = function () {
      /**
       * Called on each controller after all the controllers on an element have been constructed
       * and had their bindings initialized (and before the pre & post linking functions for the
       * directives on this element). This is a good place to put initialization code for your
       * controller.
       */
			//doSomeDefaultStuff();
		};

		////////////////////////
		// function doSomeDefaultStuff(){
		//   vm.entity.name = "doSomeDefaultStuff Was Also Here";
		// }
	}

	module.component('printShippingLabel', {
		bindings: {
			entity: '<'
		},
		controllerAs: 'vm',
		controller: ['$state', controller], // list of DI items with controller method as the last item
		// controller: ['$http', controller], // list of DI items with controller method as the last item
		templateUrl: 'app/components/printing/printShippingLabel.component.html'
	});

}());
