(function () {
	'use strict';

	var module = angular.module('app.components');

	// /* @ngInject */
	function controller($http) {
	//function controller() {

		/*jshint validthis: true */
		var vm = this;
		vm.state = '';
		vm.validation = [];

		// Handle component lifecycle events
		vm.$onInit = function () {
      /**
       * Called on each controller after all the controllers on an element have been constructed
       * and had their bindings initialized (and before the pre & post linking functions for the
       * directives on this element). This is a good place to put initialization code for your
       * controller.
       */
			//doSomeDefaultStuff();
		};

		vm.validate = function () {
			if (isValid()) {
				vm.state = '';
			}
		}

		////////////////////////
		// function doSomeDefaultStuff(){
		//   vm.entity.name = "doSomeDefaultStuff Was Here";
		// }
		function isValid() {
			vm.validation = [];

			if (!vm.entity.name) {
				vm.validation.push('Bill to name is required');
			}
			if (!vm.entity.address_line_1) {
				vm.validation.push('An address is required');
			}
			if (!vm.entity.city || !vm.entity.state_code || !vm.entity.postal_code) {
				vm.validation.push('A city, state, and zip code are required');
			}
			if (!vm.entity.contact_name) {
				vm.validation.push('A contact is required');
			}
			if (!vm.entity) {
				vm.validation.push('A zip code is required');
				return false;
			}
			else {
				if (!vm.entity.postal_code) {
					vm.validation.push('A zip code is required');
				}
			}

			vm.entity.inputIsValid = vm.validation.length === 0;
			return vm.validation.length === 0;
		}
	}

	module.component('billToEntity', {
		bindings: {
			entity: '<',
			title: '@',
			cityLookup: '&'
		},
		controllerAs: 'vm',
		controller: [controller], // list of DI items with controller method as the last item
		// controller: ['$http', controller], // list of DI items with controller method as the last item
		templateUrl: 'app/components/orderEntry/billToEntity.component.html'
	});

	module.filter('tel', function () {
		return function (tel) {
			if (!tel) { return ''; }

			var value = tel.toString().trim().replace(/^\+/, '');

			if (value.match(/[^0-9]/)) {
				return tel;
			}

			var country, city, number;

			switch (value.length) {
				case 1:
				case 2:
				case 3:
					city = value;
					break;

				default:
					city = value.slice(0, 3);
					number = value.slice(3);
			}

			if (number) {
				if (number.length > 3) {
					number = number.slice(0, 3) + '-' + number.slice(3, 7);
				}
				else {
					number = number;
				}

				return ("(" + city + ") " + number).trim();
			}
			else {
				return "(" + city;
			}

		};
	});

}());
