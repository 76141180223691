(function () {
	'use strict';

	angular
		.module('app.address')
		.run(appRun);

	appRun.$inject = ['routerHelper'];
	/* @ngInject */
	function appRun(routerHelper) {
		routerHelper.configureStates(getStates());
	}

	// handy article on skinny controllers
	// https://scotch.io/tutorials/making-skinny-angularjs-controllers

	function getStates() {
		return [
			{
				state: 'address',
				config: {
					url: '',
					abstract: true,
					template: '<ui-view/>',
					adminsOnly: true,
				}
			},
			{
				state: 'address.list',
				config: {
					url: '/address',
					templateUrl: 'app/areas/address/address-list.html',
					title: 'Addresses',
					controller: 'AddressController',
					controllerAs: 'vm',
				}
			},
			{
				state: 'address.create',
				config: {
					url: '/address/create',
					templateUrl: 'app/areas/address/address-details.html',
					title: 'Create New Address',
					controller: 'AddressController',
					controllerAs: 'vm',
				}
			},
			{
				state: 'address.details',  // editor page
				config: {
					url: '/address/:id',
					templateUrl: 'app/areas/address/address-details.html',
					title: 'Address Details',
					controller: 'AddressController',
					controllerAs: 'vm',
				}
			}
		];
	}
})();
