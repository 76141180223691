(function () {
	'use strict';

	angular
		.module('app.admin')
		.factory('adminSysTokenDataservice', adminSysTokenDataservice);

	adminSysTokenDataservice.$inject = ['$http', '$q', 'exception', 'logger'];
	/* @ngInject */
	function adminSysTokenDataservice($http, $q, exception, logger) {

		var service = {
			getSystemTokens: getSystemTokens,
			getNewSystemToken: getNewSystemToken,
			testSystemToken: testSystemToken
		};

		return service;

		function getSystemTokens() {
			return $http.get('/api/auth/systoken')
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getSystemTokens')(e);
			}
		}

		function getNewSystemToken() {
			return $http.post('/api/auth/systoken')
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getSystemToken')(e);
			}
		}

		function testSystemToken(token) {
			var config = {
				skipAuthToken: true,
				headers: {
					Authorization: 'JWT ' + token
				}
			};
			return $http.get('/api/ping', config)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for testSystemToken')(e);
			}
		}


	}
})();
