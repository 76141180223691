(function () {
	'use strict';

	angular
		.module('app.organization')
		.run(appRun);

	appRun.$inject = ['routerHelper'];
	/* @ngInject */
	function appRun(routerHelper) {
		routerHelper.configureStates(getStates());
	}

	// handy article on skinny controllers
	// https://scotch.io/tutorials/making-skinny-angularjs-controllers

	function getStates() {
		return [
			{
				state: 'organization',
				config: {
					url: '',
					abstract: true,
					template: '<ui-view/>',
					adminsOnly: true,
				}
			},
			{
				state: 'organization.list',
				config: {
					url: '/organization',
					templateUrl: 'app/areas/organization/organization-list.html',
					title: 'Organizations',
					controller: 'OrganizationController',
					controllerAs: 'vm',
				}
			},
			{
				state: 'organization.create',
				config: {
					url: '/organization/create',
					templateUrl: 'app/areas/organization/organization-details.html',
					title: 'Create New Organization',
					controller: 'OrganizationController',
					controllerAs: 'vm',
					supersOnly: true,
				}
			},
			{
				state: 'organization.details',
				config: {
					url: '/organization/:id',
					templateUrl: 'app/areas/organization/organization-details.html',
					title: 'Organization Details',
					controller: 'OrganizationController',
					controllerAs: 'vm',
				}
			},
			{
				state: 'organization.newUser',
				config: {
					url: '/organization/:id/newuser',
					templateUrl: 'app/areas/organization/organization-create-user.html',
					title: 'Create New User',
					controller: 'OrganizationController',
					controllerAs: 'vm',
				} 
			},
			{
				state: 'organization.editUser',
				config: {
					url: '/organization/:id/user/:userId',
					templateUrl: 'app/areas/organization/organization-create-user.html',
					title: 'User Details',
					controller: 'OrganizationController',
					controllerAs: 'vm',
				}
			}
		];
	}
})();
