(function () {
	"use strict";

	var module = angular.module("app.components");

	/* @ngInject */
	function controller($uibModalInstance) {
		/*jshint validthis: true */
		var vm = this;
		vm.config = {
			showOk: true,
			showCancel: false,
			bodyTemplateUrl: false
		};
		vm.data = {
			title: '',
			content: ''
		};

		vm.$onInit = function () {
			_.assignIn(vm.config, vm.resolve.config);
			_.assignIn(vm.data, vm.resolve.data);
		};

		vm.ok = function () {
			vm.close({ $value: 'ok' });
		};

		vm.cancel = function () {
			vm.dismiss({ $value: 'cancel' });
		};
	}

	module.component("basicModal", {
		bindings: {
			resolve: '<',
			close: '&',
			dismiss: '&'
		},
		controllerAs: "vm",
		controller: [controller],
		templateUrl: "app/components/layout/basicModal.component.html"
	});

}());
