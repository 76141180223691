(function () {
	'use strict';

	angular
		.module('app.report', ['ngSanitize', 'ngCsv'])
		.controller('ReportController', ReportController);
	ReportController.$inject = ['logger', 'currentUser', '$http', '$window'];

	/* @ngInject */
	function ReportController(logger, currentUser, $http, $window) {
		var vm = this;
		vm.state = '';
		vm.systemUser = false;
		vm.organizations = [];
		vm.gridOptions = {};
		vm.gridHeight = getGridHeight();
		vm.customer = null;
		vm.columnHeaders = [];

		var start_date = new Date();
		start_date.setDate(start_date.getDate() - 1);

		var end_date = new Date();

		vm.start_date = start_date;
		vm.end_date = end_date;

		function getGridHeight() {
			return $window.innerHeight - 240;
		}

		if (currentUser.isSystemUser) {
			vm.columnHeaders = [
				'Order ID', 'Customer Name', 'Carrier Name', 'Pro #', 'Dealer #', 'Return #', 'Total', 'Status',
				'Origin Name', 'Origin Address1', 'Origin City', 'Origin State', 'Origin Zip',
				'Destination Name', 'Destination Address1', 'Destination City', 'Destination State', 'Destination Zip',
				'Order Date', 'Pickup Date', 'Est Del Date', 'Car Est Del Date', 'Del Date', 'Notes'
			];
		} else {
			vm.columnHeaders = [
				'Order ID', 'Customer Name', 'Carrier Name', 'Pro #', 'Dealer #', 'Return #', 'Status',
				'Origin Name', 'Origin Address1', 'Origin City', 'Origin State', 'Origin Zip',
				'Destination Name', 'Destination Address1', 'Destination City', 'Destination State', 'Destination Zip',
				'Order Date', 'Pickup Date', 'Est Del Date', 'Car Est Del Date', 'Del Date', 'Notes'
			];
		}
		vm.gridOptions = {
			enableHorizontalScrollbar: 2,
			enableFiltering: false,
			enableSorting: true,
			flatEntityAccess: true,
			showGridFooter: true,
			fastWatch: true,
		};

		vm.gridOptions.columnDefs = [
			{name: 'pw_number', displayName: 'Order ID', width: 100},
			{name: 'customer_name', displayName: 'Customer', width: 100},
			{name: 'carrier_name', displayName: 'Carrier Name', width: 200},
			{name: 'pro_number', displayName: 'Pro #', width: 150},
			{name: 'dealer_number', displayName: 'Dealer #', width: 150},
			{name: 'return_number', displayName: 'Return #', width: 150},
			{name: 'status', displayName: 'Status', width: 100},
			{name: 'origin_name', displayName: 'Origin Name', width: 200},
			{name: 'origin_address_line_1', displayName: 'Origin Address1', width: 200},
			{name: 'origin_city', displayName: 'Origin City', width: 100},
			{name: 'origin_state_code', displayName: 'Origin State', width: 100},
			{name: 'origin_zip', displayName: 'Origin Zip', width: 100},
			{name: 'destination_name', displayName: 'Destination Name', width: 200},
			{name: 'destination_address_line_1', displayName: 'Destination Address1', width: 200},
			{name: 'destination_city', displayName: 'Destination City', width: 100},
			{name: 'destination_state_code', displayName: 'Destination State', width: 100},
			{name: 'destination_zip', displayName: 'Destination Zip', width: 100},
			{name: 'order_date', displayName: 'Order Date', width: 100},
			{name: 'pickup_date', displayName: 'Pickup Date', width: 100},
			{name: 'estimated_delivery_date', displayName: 'Est Del Date', width: 100},
			{name: 'carrier_estimated_delivery_date', displayName: 'Car Est Del Date', width: 100},
			{name: 'delivery_date', displayName: 'Del Date', width: 100},
			{name: 'notes', displayName: 'Notes', width: 500}
		];
		if (currentUser.isSystemUser) {
			//vm.gridOptions.columnDefs.splice(6, 0, {name: 'total', displayName: 'Total', width: 150});
			vm.gridOptions.columnDefs = [
					{name: 'pw_number', displayName: 'Order ID', width: 100},
					{name: 'customer_name', displayName: 'Customer', width: 100},
					{name: 'carrier_name', displayName: 'Carrier Name', width: 200},
					{name: 'pro_number', displayName: 'Pro #', width: 150},
					{name: 'dealer_number', displayName: 'Dealer #', width: 150},
					{name: 'return_number', displayName: 'Return #', width: 150},
					{name: 'total', displayName: 'Total', width: 150},
					{name: 'status', displayName: 'Status', width: 100},
					{name: 'origin_name', displayName: 'Origin Name', width: 200},
					{name: 'origin_address_line_1', displayName: 'Origin Address1', width: 200},
					{name: 'origin_city', displayName: 'Origin City', width: 100},
					{name: 'origin_state_code', displayName: 'Origin State', width: 100},
					{name: 'origin_zip', displayName: 'Origin Zip', width: 100},
					{name: 'destination_name', displayName: 'Destination Name', width: 200},
					{name: 'destination_address_line_1', displayName: 'Destination Address1', width: 200},
					{name: 'destination_city', displayName: 'Destination City', width: 100},
					{name: 'destination_state_code', displayName: 'Destination State', width: 100},
					{name: 'destination_zip', displayName: 'Destination Zip', width: 100},
					{name: 'order_date', displayName: 'Order Date', width: 100},
					{name: 'pickup_date', displayName: 'Pickup Date', width: 100},
					{name: 'estimated_delivery_date', displayName: 'Est Del Date', width: 100},
					{name: 'carrier_estimated_delivery_date', displayName: 'Car Est Del Date', width: 100},
					{name: 'delivery_date', displayName: 'Del Date', width: 100},
					{name: 'notes', displayName: 'Notes', width: 500}
			];
		} else {
			vm.gridOptions.columnDefs = [
				{name: 'pw_number', displayName: 'Order ID', width: 100},
				{name: 'customer_name', displayName: 'Customer', width: 100},
				{name: 'carrier_name', displayName: 'Carrier Name', width: 200},
				{name: 'pro_number', displayName: 'Pro #', width: 150},
				{name: 'dealer_number', displayName: 'Dealer #', width: 150},
				{name: 'return_number', displayName: 'Return #', width: 150},
				{name: 'status', displayName: 'Status', width: 100},
				{name: 'origin_name', displayName: 'Origin Name', width: 200},
				{name: 'origin_address_line_1', displayName: 'Origin Address1', width: 200},
				{name: 'origin_city', displayName: 'Origin City', width: 100},
				{name: 'origin_state_code', displayName: 'Origin State', width: 100},
				{name: 'origin_zip', displayName: 'Origin Zip', width: 100},
				{name: 'destination_name', displayName: 'Destination Name', width: 200},
				{name: 'destination_address_line_1', displayName: 'Destination Address1', width: 200},
				{name: 'destination_city', displayName: 'Destination City', width: 100},
				{name: 'destination_state_code', displayName: 'Destination State', width: 100},
				{name: 'destination_zip', displayName: 'Destination Zip', width: 100},
				{name: 'order_date', displayName: 'Order Date', width: 100},
				{name: 'pickup_date', displayName: 'Pickup Date', width: 100},
				{name: 'estimated_delivery_date', displayName: 'Est Del Date', width: 100},
				{name: 'carrier_estimated_delivery_date', displayName: 'Car Est Del Date', width: 100},
				{name: 'delivery_date', displayName: 'Del Date', width: 100},
				{name: 'notes', displayName: 'Notes', width: 500}
			];
		}

		activate();

		function activate() {
			logger.info('Activated Report View');
			loadOrganizations();
			vm.systemUser = currentUser.isSystemUser;
		}

		function loadOrganizations() {
			if(currentUser.isSystemUser){
				$http.get('/api/organizations').then(function successCallback(response) {
					vm.organizations = response.data;
				}, function failureCallback(response) {
					console.log(response);
				});
			}else{
				$http.get('/api/organizations/' + currentUser.organizationId).then(function successCallback(response) {
					vm.customer = response.data;
				}, function failureCallback(response) {
					console.log(response);
				});
			}
		}

		vm.getReport = function() {
			if(validateForm())
				addOrderData('');
		}

		function validateForm() {
			//if(!vm.customer) {
			// 	return false;
			// }

			return true;
		}

		function getOrders(status) {
			var _helsenkiOffset = 5*60*60000;
			var parms = { organization_id: vm.customer ? vm.customer.id : 0, begin_date: new Date(vm.start_date.setHours(0,0,0,0)-_helsenkiOffset), end_date: new Date(vm.end_date.setHours(23,59,59,999)-_helsenkiOffset) };
			return $http.post('/api/reports', parms)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getOrders')(e);
			}
		}

		function addOrderData(selectedStatus) {
			return getOrders(selectedStatus).then(
				function (data) { // success callback
					vm.orders = [];
					var response = data;

					if (response.length > 0) {
						_.forEach(response, function (order) {
							//change to let order =
							//let newOrder = {
							if (currentUser.isSystemUser) {
								vm.orders.push({
									pw_number: order.pw_number,
									customer_name: order.customer_name,
									carrier_name: order.carrier_name,
									pro_number: order.pro_number,
									dealer_number: order.dealer_number,
									return_number: order.return_number,
									total: parseFloat(Math.round(order.total * 100) / 100).toFixed(2),
									status: order.status,
									origin_name: order.origin_name,
									origin_address_line_1: order.origin_address_line_1,
									origin_city: order.origin_city,
									origin_state_code: order.origin_state_code,
									origin_zip: order.origin_zip,
									destination_name: order.destination_name,
									destination_address_line_1: order.destination_address_line_1,
									destination_city: order.destination_city,
									destination_state_code: order.destination_state_code,
									destination_zip: order.destination_zip,
									order_date: order.order_date,
									pickup_date: order.pickup_date,
									estimated_delivery_date: order.estimated_delivery_date,
									carrier_estimated_delivery_date: order.carrier_estimated_delivery_date,
									delivery_date: order.delivery_date,
									notes: order.notes
								});
							} else {
								vm.orders.push({
									pw_number: order.pw_number,
									customer_name: order.customer_name,
									carrier_name: order.carrier_name,
									pro_number: order.pro_number,
									dealer_number: order.dealer_number,
									return_number: order.return_number,
									status: order.status,
									origin_name: order.origin_name,
									origin_address_line_1: order.origin_address_line_1,
									origin_city: order.origin_city,
									origin_state_code: order.origin_state_code,
									origin_zip: order.origin_zip,
									destination_name: order.destination_name,
									destination_address_line_1: order.destination_address_line_1,
									destination_city: order.destination_city,
									destination_state_code: order.destination_state_code,
									destination_zip: order.destination_zip,
									order_date: order.order_date,
									pickup_date: order.pickup_date,
									estimated_delivery_date: order.estimated_delivery_date,
									carrier_estimated_delivery_date: order.carrier_estimated_delivery_date,
									delivery_date: order.delivery_date,
									notes: order.notes
								});
							}
						});
					}
					//Add the data to the grid
					vm.gridOptions.data = vm.orders;
				},
				function (response) { // error callback
					vm.state = 'error';
					vm.errorFeedback = 'Unable to add order data to orders. Status: ' + response.status;
				});
		}
	}
})();
