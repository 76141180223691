(function () {
	'use strict';

	angular
		.module('app')
		.factory('currentUser', currentUser);

	currentUser.$inject = ['auth'];

	/* @ngInject */
	function currentUser(auth) {

		var _isLoggedIn = false;

		var service = {
			reset: reset,
			isLoggedIn: isLoggedIn,
			logout: logout,
			username: null,
			userId: null,
			organizationId: null,
			isAdmin: false,
			isSuper: false,
			isSystemUser: false,
		};

		return service;

    /**
     * We made isLoggedIn a function instead of a property because we need to call auth.isAuthed
     * on a regular basis to validate that the user's token hasn't timed out. By caching the user's
     * status in _isLoggedIn we can know when things have changed and when we need to update the
     * data on currentUser.
     */
		function isLoggedIn() {
			if (_isLoggedIn === auth.isAuthed()) { return _isLoggedIn; }

			if (auth.isAuthed()) {
				populateCurrentUserFromAuthClaims();
			} else {
				clearCurrentUser();
			}
			return _isLoggedIn;
		}

		function logout() {
			auth.logout();
		}

		function reset() {
			_isLoggedIn = false;
			isLoggedIn();
		}

		function populateCurrentUserFromAuthClaims() {
			_isLoggedIn = true;
			service.username = auth.claims.uname;
			service.userId = auth.claims.sub;
			service.organizationId = auth.claims.orgid;
			service.organizationName = auth.claims.orgname;
			service.isAdmin = auth.claims.admin;
			service.isSuper = auth.claims.super;
			service.isSystemUser = auth.claims.sysusr;
		}

		function clearCurrentUser() {
			_isLoggedIn = false;
			service.username = null;
			service.userId = null;
			service.accountId = null;
			service.accountName = null;
			service.isAdmin = false;
			service.isSuper = false;
			service.isSystemUser = false;
		}
	}

})();
//https://thinkster.io/tutorials/angularjs-jwt-auth
