(function() {
  'use strict';

  angular
    .module('app.admin')
    .run(appRun);

  appRun.$inject = ['routerHelper'];
  /* @ngInject */
  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  // handy article on skinny controllers
  // https://scotch.io/tutorials/making-skinny-angularjs-controllers

  function getStates() {
    return [
      {
        state: 'admin.syscarrier',
        config: {
          url: '',
          abstract: true,
          template: '<ui-view/>',
          supersOnly: true,
        }
      },
      {
			state: 'admin.syscarrier.list',
			config: {
				url: '/admin/syscarrier',
				templateUrl: 'app/areas/admin/syscarrier/admin-syscarrier-list.html',
				title: 'Admin: System Carriers',
				controller: 'AdminSystemCarrierController',
				controllerAs: 'vm',
			}
       },
       {
			state: 'admin.syscarrier.create',
			config: {
				url: '/admin/syscarrier/new',
				templateUrl: 'app/areas/admin/syscarrier/admin-syscarrier-create.html',
				title: 'Admin: Create System Carrier',
				controller: 'AdminSystemCarrierController',
				controllerAs: 'vm',
			}
      	},
		{
			state: 'admin.syscarrier.update',
			config: {
				url: '/admin/syscarrier/edit/:id',
				templateUrl: 'app/areas/admin/syscarrier/admin-syscarrier-create.html',
				title: 'Admin: Edit System Carrier',
				controller: 'AdminSystemCarrierController',
				controllerAs: 'vm',
			}
		}
    ];
  }
})();
