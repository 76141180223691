(function() {
  'use strict';

  angular
    .module('app.admin')
    .run(appRun);

  appRun.$inject = ['routerHelper'];
  /* @ngInject */
  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  // handy article on skinny controllers
  // https://scotch.io/tutorials/making-skinny-angularjs-controllers

  function getStates() {
    return [
      {
        state: 'admin.override',
        config: {
          url: '',
          abstract: true,
          template: '<ui-view/>',
          supersOnly: true,
        }
      },
      {
			state: 'admin.override.list',
			config: {
				url: '/admin/override',
				templateUrl: 'app/areas/admin/override/admin-override-list.html',
				title: 'Admin: Shipper Overrides',
				controller: 'AdminOverrideController',
				controllerAs: 'vm',
			}
       },
       {
			state: 'admin.override.create',
			config: {
				url: '/admin/override/new',
				templateUrl: 'app/areas/admin/override/admin-override-details.html',
				title: 'Admin: Create Override',
				controller: 'AdminOverrideController',
				controllerAs: 'vm',
			}
      	},
		{
			state: 'admin.override.details',
			config: {
				url: '/admin/override/edit/:id',
				templateUrl: 'app/areas/admin/override/admin-override-details.html',
				title: 'Admin: Edit Override',
				controller: 'AdminOverrideController',
				controllerAs: 'vm',
			}
		}
    ];
  }
})();
