(function() {
  'use strict';

  angular
    .module('app.admin')
    .run(appRun);

  appRun.$inject = ['routerHelper'];
  /* @ngInject */
  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  // handy article on skinny controllers
  // https://scotch.io/tutorials/making-skinny-angularjs-controllers

  function getStates() {
    return [
      {
        state: 'admin.acctshipper',
        config: {
          url: '',
          abstract: true,
          template: '<ui-view/>',
          supersOnly: true,
        }
      },
      {
			state: 'admin.acctshipper.list',
			config: {
				url: '/admin/acctshipper',
				templateUrl: 'app/areas/admin/acctshipper/admin-acct-shipper-list.html',
				title: 'Admin: Customer Carriers',
				controller: 'AdminAcctShipperController',
				controllerAs: 'vm',
			}
       },
       {
			state: 'admin.acctshipper.create',
			config: {
				url: '/admin/acctshipper/new',
				templateUrl: 'app/areas/admin/acctshipper/admin-acct-shipper-details.html',
				title: 'Admin: Create Customer Carrier',
				controller: 'AdminAcctShipperController',
				controllerAs: 'vm',
			}
      	},
		{
			state: 'admin.acctshipper.details',
			config: {
				url: '/admin/acctshipper/edit/:id',
				templateUrl: 'app/areas/admin/acctshipper/admin-acct-shipper-details.html',
				title: 'Admin: Edit Customer Carrier',
				controller: 'AdminAcctShipperController',
				controllerAs: 'vm',
			}
		}
    ];
  }
})();
