(function () {
	'use strict';

	angular
		.module('app.dashboard')
		.factory('dashboardDataservice', dashboardDataservice);

	dashboardDataservice.$inject = ['$http', '$q', 'exception', 'logger'];

	/* @ngInject */
	function dashboardDataservice($http, $q, exception, logger) {

		var service = {
			getOrders: getOrders,
			getOrderBySwNumber: getOrderBySwNumber,
			getAllStatus: getAllStatus,
		  getAccessorialDescriptions: getAccessorialDescriptions,
		  updateOrder: updateOrder
		};
		return service;

		function getOrders(status, includeCancelledShipments, includeDeliveredShipments) {
			return $http.get('/api/orders?status=' + status + '&cancelled=' + includeCancelledShipments + '&delivered=' + includeDeliveredShipments)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getOrders')(e);
			}
		}

		function getAllStatus() {
			return $http.get('/api/orders/status/counts')
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getAllStatus')(e);
			}
		}

		function getAccessorialDescriptions(services) {
			if (!services) return;

			return $http.get('/api/accessorials/')
				.then(success)
				.catch(fail);

				function success(response) {
					var accessorialsToDisplay = [];

					services.forEach(function (service) {
						var match = response.data.filter(function (accessorial) { return accessorial.code === service.code });
						if (match.length) {
							service.description = match[0].label;
							accessorialsToDisplay.push(service);
						}
					});

					return accessorialsToDisplay;
				}

				function fail(e) {
					return exception.catcher('XHR Failed for getAccessorialDescriptions')(e);						
				}
		}

		function getOrderBySwNumber(swNumber, useRlmsRoute) {
			var route = '/api/orders/';
			if (useRlmsRoute) route = '/api/rlms/orders/';
			return $http.get(route + swNumber)
				.then(success)
				.catch(fail);

			function success(response) {
				if(response.data.order_date)
					response.data.order_date = new Date(response.data.order_date)
				if(response.data.ready_date)
					response.data.ready_date = new Date(response.data.ready_date);
				if(response.data.pickup_date)
					response.data.pickup_date = new Date(response.data.pickup_date);
				if(response.data.estimated_delivery_date)
					response.data.estimated_delivery_date = new Date(response.data.estimated_delivery_date);
				if(response.data.delivery_date)
					response.data.delivery_date = new Date(response.data.delivery_date);

				return response.data;
			}

			function fail(e) {
				if (e.data.name === 'OrderNotFound' || e.data.name === 'OrderDetailsNotFound') {
					return exception.catcher('')(e);
				}else{
				return exception.catcher('XHR Failed for getOrderBySwNumber')(e);
				}
			}
		}

	  function updateOrder(orderToUpdate) {
			window.kou = orderToUpdate;
			$http.put('api/orders/' + orderToUpdate.id, orderToUpdate).then(function successCallback(response) {
				window.kresponse = response;
				console.log('all was v successful');
			}, function errorCallback(response) {
				window.kresponse = response;
				console.log('all was v bad');
			});

	  }
	}
})();



