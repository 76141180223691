(function () {
	'use strict';

	angular
		.module('app.weightReport', ['ngSanitize', 'ngCsv'])
		.controller('WeightReportController', WeightReportController);
	WeightReportController.$inject = ['logger', 'currentUser', '$http', '$window'];

	/* @ngInject */
	function WeightReportController(logger, currentUser, $http, $window) {
		var vm = this;
		vm.state = '';
		vm.systemUser = false;
		vm.organizations = [];
		vm.gridOptions = {};
		vm.gridHeight = getGridHeight();
		vm.customer = null;

		var start_date = new Date();
		start_date.setDate(start_date.getDate() - 1);

		var end_date = new Date();

		vm.start_date = start_date;
		vm.end_date = end_date;

		function getGridHeight() {
			return $window.innerHeight - 240;
		}

		vm.gridOptions = {
			enableHorizontalScrollbar: 2,
			enableFiltering: false,
			enableSorting: true,
			flatEntityAccess: true,
			showGridFooter: true,
			fastWatch: true,
		};

		vm.gridOptions.columnDefs = [
			{name: 'pw_number', displayName: 'Order ID', width: 100},
			{name: 'pro_number', displayName: 'Pro #', width: 175},
			{name: 'actual_total_ar', displayName: 'Total AR', width: 125},
			{name: 'actual_insurance_ar', displayName: 'Total Insurance AR', width: 175},
			{name: 'actual_brokerage_ar', displayName: 'Total brokerage AR', width: 175},
			{name: 'actual_fuel_surcharge_ar', displayName: 'Total Fuel Surcharge AR', width: 175},
			{name: 'actual_base_freight_ar', displayName: 'Total Base Freight AR', width: 175},
			{name: 'actual_accessorial_ar', displayName: 'Total Accessorial AR', width: 175},
			{name: 'accessorialDesc', displayName: 'Accessorial Details', width: 500}
		];

		activate();

		function activate() {
			logger.info('Activated Report View');
			loadOrganizations();
			vm.systemUser = currentUser.isSystemUser;
		}

		function loadOrganizations() {
			if(currentUser.isSystemUser){
				$http.get('/api/organizations').then(function successCallback(response) {
					vm.organizations = response.data;
				}, function failureCallback(response) {
					console.log(response);
				});
			}else{
				$http.get('/api/organizations/' + currentUser.organizationId).then(function successCallback(response) {
					vm.customer = response.data;
				}, function failureCallback(response) {
					console.log(response);
				});
			}
		}

		vm.getReport = function() {
			if(validateForm())
				addOrderData('');
		}

		function validateForm() {
			if(!vm.customer) {
				return false;
			}

			return true;
		}

		function getOrders(status) {
			var _helsenkiOffset = 5*60*60000;
			var parms = { organization_id: vm.customer.id, begin_date: new Date(vm.start_date.setHours(0,0,0,0)-_helsenkiOffset), end_date: new Date(vm.end_date.setHours(23,59,59,999)-_helsenkiOffset) };
			return $http.post('/api/reports/weight', parms)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getOrders')(e);
			}
		}

		function addOrderData(selectedStatus) {
			return getOrders(selectedStatus).then(
				function (data) { // success callback
					vm.orders = [];
					var response = data;

					if (response.length > 0) {
						_.forEach(response, function (order) {
							console.log(order);
							vm.orders.push({
								pw_number: order.pw_number,
								pro_number: order.pro_number,
								actual_total_ar: order.actual_total_ar,
								actual_insurance_ar: order.actual_insurance_ar,
								actual_brokerage_ar: order.actual_brokerage_ar,
								actual_fuel_surcharge_ar: order.actual_fuel_surcharge_ar,
								actual_base_freight_ar: order.actual_base_freight_ar,
								actual_accessorial_ar: order.actual_accessorial_ar,
								accessorialDesc: order.accessorialDesc
							});
						});
					}
					//Add the data to the grid
					vm.gridOptions.data = vm.orders;
				},
				function (response) { // error callback
					vm.state = 'error';
					vm.errorFeedback = 'Unable to add order data to orders. Status: ' + response.status;
				});
		}
	}
})();
