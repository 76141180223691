(function () {
  'use strict';

  var module = angular.module('app.components');

  // /* @ngInject */
  function controller($scope) {

	/*jshint validthis: true */
	var vm = this;
	window.mkvm = vm;

	vm.scope = $scope;
	vm.state = 'edit';
	vm.validation = [];
	vm.carrier = {};
	vm.carrierDispatchable = true;

	//vm.quote = {};
	vm.manualQuoteSellRate = 0;
	vm.manualQuoteBuyRate = 0;
	vm.manualQuoteFuelSurcharge = 0;

	vm.shouldCheck = true;

	vm.$onChanges = function () {
	  console.log('IM CHANGING IM CHANGING!!!!!!!');
	}
	// Handle component lifecycle events
	vm.$onInit = function () {
	  /**
	   * Called on each controller after all the controllers on an element have been constructed
	   * and had their bindings initialized (and before the pre & post linking functions for the
	   * directives on this element). This is a good place to put initialization code for your
	   * controller.
	   */
	  doSomeDefaultStuff();
	};

	vm.$doCheck = function () {
	  console.log('doin check');
	  if (vm.shouldCheck) {
		if (vm.entity.carrier && vm.entity.carrier.id > 0) {
		  console.log('finished check');
		  vm.carrier = JSON.stringify(vm.entity.carrier);
		  vm.setCarrier();
		  vm.manualQuoteSellRate = vm.entity.manualQuoteSellRate || 0;
		  vm.manualQuoteBuyRate = vm.entity.manualQuoteBuyRate || 0;
		  vm.manualQuoteFuelSurcharge = vm.entity.manualQuoteFuelSurcharge || 0;
		  vm.shouldCheck = false;
		}
	  }
	}
	////////////////////////
	function doSomeDefaultStuff() {
	  vm.entity.quote = { "manual_delivery_date": (new Date()).toLocaleDateString() };
	  vm.entity.autoDispatch = 'F';
	  vm.entity.quote.trailer_status = 'TRANSIT';
	}

	vm.validate = function () {
	  if (isValid()) {
		vm.state = '';
	  }
	}

	vm.setCarrier = function () {
	  var s = JSON.parse(vm.carrier);
	  if (!vm.entity.quote)
		vm.entity.quote = {};
	  vm.entity.quote.account_code = s.code;
	  vm.entity.quote.carrier_code = s.code;
	  vm.entity.quote.carrier_name = s.name;
	  vm.carrierDispatchable = s.is_p44 === 1;
	  vm.entity.autoDispatch = vm.carrierDispatchable ? 'TRUE' : 'FALSE';
	  vm.entity.quote.trailer_status = 'TRANSIT';
	}

	vm.updateSellRate = function () {
	  if (!vm.entity.quote.details) {
		vm.entity.quote.details = {}
	  }
	  // default to 0 each time in case user enters nothing
	  vm.entity.quote.details.total = 0;
	  vm.entity.quote.details.chksum = 0;

	  vm.entity.quote.details.total = +vm.manualQuoteSellRate + +vm.manualQuoteFuelSurcharge;
	  vm.entity.quote.details.chksum = +vm.manualQuoteBuyRate;

	  vm.entity.quote.details.charges = [];
	  vm.entity.quote.details.charges.push({ "code": "ITEM", "amount": +vm.manualQuoteSellRate, "chksum": +vm.manualQuoteBuyRate, "weight": 0, "category": "base_freight", "description": "Item Charge", "weight_unit": "LBS" });
	  vm.entity.quote.details.charges.push({ "code": "FSC", "amount": +vm.manualQuoteFuelSurcharge, "chksum": 0, "weight": 0, "category": "fuel_surcharge", "description": "FUEL", "weight_unit": "LBS" });
	}

	function isValid() {
	  vm.validation = [];

	  if (!vm.entity.billing) {
		vm.validation.push('Please enter Bill To information');
		return;
	  }

	  if (!vm.entity.billing.name) {
		vm.validation.push('Billing name is required');
	  }
	  if (!vm.entity.billing.address_line_1) {
		vm.validation.push('An address is required');
	  }
	  if (!vm.entity.billing.city || !vm.entity.billing.state_code || !vm.entity.billing.postal_code) {
		vm.validation.push('A city, state, and zip code are required');
	  }
	  if (!vm.entity.billing.contact_name) {
		vm.validation.push('A contact is required');
	  }

	  vm.entity.inputIsValid = vm.validation.length === 0;
	  return vm.validation.length === 0;
	}
  }

  module.component('manualOrderEntity', {
	bindings: {
	  entity: '<',
	  title: '@'
	},
	controllerAs: 'vm',
	controller: [controller], // list of DI items with controller method as the last item
	// controller: ['$http', controller], // list of DI items with controller method as the last item
	templateUrl: 'app/components/orderEntry/manualOrderEntity.component.html'
  });

  module.filter('tel', function () {
	return function (tel) {
	  if (!tel) { return ''; }

	  var value = tel.toString().trim().replace(/^\+/, '');

	  if (value.match(/[^0-9]/)) {
		return tel;
	  }

	  var country, city, number;

	  switch (value.length) {
		case 1:
		case 2:
		case 3:
		  city = value;
		  break;

		default:
		  city = value.slice(0, 3);
		  number = value.slice(3);
	  }

	  if (number) {
		if (number.length > 3) {
		  number = number.slice(0, 3) + '-' + number.slice(3, 7);
		}
		else {
		  number = number;
		}

		return ("(" + city + ") " + number).trim();
	  }
	  else {
		return "(" + city;
	  }
	};
  });

}());
