(function () {
	'use strict';

	var module = angular.module('app.components');

	// /* @ngInject */
	// function controller($http) {
	function controller() {

		/*jshint validthis: true */
		var vm = this;
			vm.state = '';
			vm.referencesInUse = [];

		// Handle component lifecycle events
		vm.$onInit = function () {
		/**
		 * Called on each controller after all the controllers on an element have been constructed
		 * and had their bindings initialized (and before the pre & post linking functions for the
		 * directives on this element). This is a good place to put initialization code for your
		 * controller.
		 */
		//doSomeDefaultStuff();
		};
	
		vm.$onChanges = function (changesObj) {
			vm.setReferenceNumbers();
		}

		vm.switchToSummaryView = function() {
			vm.state = '';
			vm.setReferenceNumbers();
		};

		// function doSomeDefaultStuff(){
		//   vm.entity.name = "doSomeDefaultStuff Was Also Here";
		// }

		vm.setReferenceNumbers = function(){
			vm.referencesInUse = [];
			
			if (!angular.isUndefined(vm.entity.poNumber) && vm.entity.poNumber.length > 0) {
				vm.referencesInUse.push({
					label: 'PO #',
					type: 'PURCHASE_NUMBER',
					value: vm.entity.poNumber
				});
			}
			if (!angular.isUndefined(vm.entity.soNumber) && vm.entity.soNumber.length > 0) {
				vm.referencesInUse.push({
					label: 'SO #',
					type: 'SO_NUMBER',
					value: vm.entity.soNumber
				});
			}
			if (!angular.isUndefined(vm.entity.returnNumber) && vm.entity.returnNumber.length > 0) {
				vm.referencesInUse.push({
					label: 'Return #',
					type: 'RETURN_ID',
					value: vm.entity.returnNumber
				});
			}
			if (!angular.isUndefined(vm.entity.jobNumber) && vm.entity.jobNumber.length > 0) {
				vm.referencesInUse.push({
					label: 'Job #',
					type: 'JOB_NUMBER',
					value: vm.entity.jobNumber
				});
			}
			if (!angular.isUndefined(vm.entity.invoiceNumber) && vm.entity.invoiceNumber.length > 0) {
				vm.referencesInUse.push({
					label: 'Invoice #',
					type: 'INVOICE_NUMBER',
					value: vm.entity.invoiceNumber
				});
			}
			if (!angular.isUndefined(vm.entity.oem) && vm.entity.oem.length > 0) {
				vm.referencesInUse.push({
					label: 'OEM',
					type: 'OEM',
					value: vm.entity.oem
				});
			}
			if (!angular.isUndefined(vm.entity.dealerID) && vm.entity.dealerID.length > 0) {
				vm.referencesInUse.push({
					label: 'Dealer ID',
					type: 'DEALER_ID',
					value: vm.entity.dealerID
				});
			}
			if (!angular.isUndefined(vm.entity.otherReference)) {
				console.log(vm.entity.otherReference);
				if (vm.entity.otherReference.name && vm.entity.otherReference.value && vm.entity.otherReference.name.length > 0 && vm.entity.otherReference.value.length > 0) {
					vm.referencesInUse.push({
						label: vm.entity.otherReference.name,
						type: 'OTHER_' + vm.entity.otherReference.name.toUpperCase().replace(' ', '_'),
						value: vm.entity.otherReference.value
					});
				}
			}
			
			vm.entity.identifiers = [];
			for (var i = 0, len = vm.referencesInUse.length; i < len; i++) {
				var identifier = vm.referencesInUse[i];
				vm.entity.identifiers.push({ type: identifier.type, value: identifier.value });
			}
		}
	}

	module.component('referenceNumbersEntity', {
		bindings: {
			entity: '<',
			title: '@'
		},
		controllerAs: 'vm',
		controller: [controller], // list of DI items with controller method as the last item
		// controller: ['$http', controller], // list of DI items with controller method as the last item
		templateUrl: 'app/components/orderEntry/referenceNumbersEntity.component.html'
	});

}());
