(function () {
	'use strict';

	angular
		.module('app.admin')
		.controller('AdminOverrideController', AdminOverrideController);

	AdminOverrideController.$inject = ['$http', 'logger', 'currentUser', 'AdminOverrideDataservice', '$q', '$state', 'auth'];
	/* @ngInject */
	function AdminOverrideController($http, logger, currentUser, AdminOverrideDataservice, $q, $state, auth) {
		var vm = this;
		window.k = vm;
		vm.title = $state.current.title;
		vm.id = $state.params.id;
		vm.currentUser = currentUser;

		// ensures everything gets loaded before needed
		vm.states = [];
		vm.organizations = [];
		vm.shippers = [];
		vm.overrides = [];
		loadShippers().then(function () {
			loadStates().then(function () {
				loadOrganizations().then(function () {
					loadOverrides().then(function () {
						activate();
					})
				})
			})
		});

		// activate();

		////////////////////

		function activate() {
			var promises = [];
			switch ($state.current.name) {
				case 'admin.override.list':
					// vm.overrides = [];
					vm.orgSelection = {};
					vm.stateSelection = {};
					vm.searchZips = '';
					vm.filterState = filterState;
					vm.filterZips = filterZips;
					vm.filterOrg = filterOrg;
					vm.clearFilters = clearFilters;
					vm.removeOverride = removeOverride;
					break;
				case 'admin.override.create':
				case 'admin.override.details':  // just to minimize lines of code, identical except for createOverride/updateOverride
					console.log(vm.id);
					vm.override = {};
					vm.override.carriersObject = {};
					vm.override.carriersObject.acceptable = [];
					vm.shipperSelection = {};
					vm.orgSelection = {};
					vm.stateSelection = {};
					vm.validation = [];
					vm.changesMade = false;
					vm.isValid = isValid;
					vm.isChanged = isChanged;
					vm.stateChange = stateChange;
					vm.organizationChange = organizationChange;
					vm.shipperChange = shipperChange;
					vm.acceptableChange = acceptableChange;
					vm.addAcceptable = addAcceptable;
					vm.removeAcceptable = removeAcceptable;
					vm.cancelAddAcceptable = cancelAddAcceptable;
					vm.updateOverride = updateOverride;
					promises.push(loadOverrides());  // this refreshes vm.overrides to full list for duplicate checking
					if ($state.current.name === 'admin.override.create') {
						promises.push(createOverride());
					} else if ($state.current.name === 'admin.override.details') {
						promises.push(getOverrideDetails());
					} else {
						break;  // shouldn't be any other options
					}
					break;
			}
			return $q.all(promises).then(function () {
			});
		}

		function loadOverrides() {
			return AdminOverrideDataservice.getOverrides().then(function (data) {
				vm.overrides = data;
				// parse out the carrier maps
			  _.each(vm.overrides, function (o) {
					parseCarrierMap(o);
					parseZipCodes(o);
			  });
				return vm.overrides;
			});
		}

		function loadOrganizations() {
			if (vm.organizations.length === 0) {
				return $http.get('/api/organizations').then(function (response) {
					vm.organizations.push({ id: 0, name: 'All' })
					vm.organizations = vm.organizations.concat(response.data);
				}, function (response) {
					console.log('error loading organizations');
					console.log(response);
				});
			}
		}

		function loadStates() {
			if (vm.states.length === 0) {
				return $http.get('/api/states').then(function (response) {
					vm.states = response.data;
				}, function (response) {
					console.log('error loading states');
					console.log(response);
				});
			}
		}
		function loadShippers() {
			if (!vm.shippers || vm.shippers.length === 0) {
				return $http.get('/api/shippers').then(function (response) {
				  vm.shippers = vm.shippers.concat(_.filter(response.data, function (s) { return s.is_p44 === 1 && s.type === 'LTL'; }));
				}, function (response) {
					console.log('error loading shippers');
					console.log(response);
				});
			}
		}

		function filterState() {
			// just reduce the vm.overrides array
		  vm.overrides = _.filter(vm.overrides, function (o) { return o.state_code === vm.stateSelection.code; });
		}
		function filterZips() {
			// parse the list given
			var zipList = vm.searchZips.split(',');
		  zipList = _.map(zipList, function (z) { return z.trim(); });
			// filter to any matching zip
			vm.overrides = _.filter(vm.overrides, function(o) {
				var match = false;
			  _.each(zipList, function (z) {
					if (o.zip_codesDisplay.indexOf(z) > -1) {
						match = true;
					}
			  });
				return match;
			});
		}
		function filterOrg() {
			// just reduce the vm.overrides array
		  vm.overrides = _.filter(vm.overrides, function (o) { return o.organization_id === vm.orgSelection.id; });
		}
		function clearFilters() {
			vm.stateSelection = null;
			vm.orgSelection = null;
			vm.searchZips = null;
			loadOverrides();
		}

		// TODO:  remove this in favor of getShipperDetails
		function getShipperName(code) {
		  const shipper = _.find(vm.shippers, function (s) { return s.code === code; });
			return (!!shipper) ? shipper.name : 'unknown';
		}

		function addAcceptable() {
			vm.override.carriersObject.acceptable.push({});
			vm.addingAcceptable = true;
			vm.changesMade = true;
		}
		function removeAcceptable(code) {
			vm.override.carriersObject.acceptable.splice(vm.override.carriersObject.acceptable.indexOf(code), 1);
			vm.changesMade = true;
		}
		function cancelAddAcceptable() {
			vm.addingAcceptable = false;
			vm.override.carriersObject.acceptable.pop();
		}

		function getOverrideDetails() {
			return AdminOverrideDataservice.getById(vm.id).then(function (data) {
				vm.override = data;
				parseCarrierMap(vm.override);
				vm.shipperSelection = getShipperDetails(vm.override.carriersObject.preferred_carrier.code);
				vm.orgSelection = getOrgDetails(vm.override.organization_id);
				vm.stateSelection = getStateDetails(vm.override.state_code);
				parseZipCodes(vm.override);
				console.log(vm.override)

				return vm.override;
			});
		}

		function parseCarrierMap(override) {
			const o = JSON.parse(override.carrier_map);
			override.carriersObject = { preferred_carrier: {}, acceptable: [] };
			override.carriersObject.preferred_carrier = { code: o.preferred_carrier, name: getShipperName(o.preferred_carrier) };
			// override.carriersObject.preferred_carrier = o.preferred_carrier;
			// console.log(override.carriersObject);
			override.acceptableCarriersDisplay = '';
			if (!!o.acceptable_carriers) {
			  _.each(o.acceptable_carriers, function (a) {
					const ao = { code: a, name: '' };
					ao.name = getShipperName(ao.code);
					// console.log(ao);
					override.carriersObject.acceptable.push(ao);
					override.acceptableCarriersDisplay += ao.name + ', \r\n'  // new line
			  });

				override.acceptableCarriersDisplay = override.acceptableCarriersDisplay.slice(0, -4);
				// console.log(override.acceptableCarriersDisplay)
			}
		}

		function unParseCarrierMap(override) {
			const o = { preferred_carrier: override.carriersObject.preferred_carrier.code, acceptable_carriers: [] }
		  _.each(override.carriersObject.acceptable, function (c) { o.acceptable_carriers.push(c.code); });
			return JSON.stringify(o);
		}

		function parseZipCodes(override) {
			if (!!override.zip_codes && typeof override.zip_codes === 'string') {
				// it looks like the structure of override.zip_codes is highly variable, accounting for variations to prevent script execution from failing so we can evaluate operation
				if (override.zip_codes.indexOf('zip_codes') >= 0) {
					// is JSON containing a zip_codes property
					let parsed = JSON.parse(override.zip_codes);
					if (typeof parsed.zip_codes === 'string') {
						// internal zip_codes property is a string containing one or more zip codes
						override.zip_codesDisplay = parsed.zip_codes;
					} else {
						// internal zip_codes is an object or array containing zip codes
						override.zip_codesDisplay = parsed.zip_codes.toString();
					}
				} else {
					// is string containing raw zip codes and not JSON
					override.zip_codesDisplay = override.zip_codes;
				}
			} else {
				override.zip_codesDisplay = '';
			}
		}
		function unParseZipCodes(override) {
			if (override.zip_codesDisplay.trim().length > 0) {
				const o = { zip_codes: [] };
			  o.zip_codes = override.zip_codesDisplay.split(',');
			  o.zip_codes = _.map(o.zip_codes, function (z) { return z.trim(); });
				return JSON.stringify(o);
			} else { return null; }
		}

		function getShipperDetails(code) {
		  const shipper = _.find(vm.shippers, function (s) { return s.code === code; });
			return (!!shipper) ? shipper : {};
		}
		function getOrgDetails(id) {
		  const organization = _.find(vm.organizations, function (o) { return o.id === id; });
			return (!!organization) ? organization : {};
		}
		function getStateDetails(code) {
		  const state = _.find(vm.states, function (s) { return s.code === code; });
			return (!!state) ? state : {};
		}

		function stateChange() {
			vm.override.state_code = vm.stateSelection.code;
		}
		function organizationChange() {
			vm.override.organization_id = vm.orgSelection.id;
		}
		function shipperChange() {
		  const d = _.find(vm.override.carriersObject.acceptable, function (a) { return a.code === vm.shipperSelection.code; });
			if (!!d) {
				// don't allow dups in the carriersObect,
				const name = vm.shipperSelection.name;
				vm.shipperSelection = null;
				logger.error("Sorry, you've already selected " + name);
			} else {
				vm.override.carriersObject.preferred_carrier = vm.shipperSelection;
			}
		}

		function acceptableChange() {
		  const d = _.find(vm.override.carriersObject.acceptable, function (a) { return a.code === vm.acceptableSelection.code; });
			if ((!!d) ||
				(vm.override.carriersObject.preferred_carrier.code === vm.acceptableSelection.code)) {
				// don't allow dups in the carriersObect,
				const name = vm.acceptableSelection.name;
				vm.acceptableSelection = null;
				logger.error("Sorry, you've already selected " + name);
			} else {
				vm.override.carriersObject.acceptable[vm.override.carriersObject.acceptable.length - 1] =
					{ code: vm.acceptableSelection.code, name: vm.acceptableSelection.name };
				vm.addingAcceptable = false;
			}
		}

		function createOverride() {
			// create an empty override object
			vm.override = {
				id: null,
				state_code: '',
				zip_codes: null,
				organization_id: 0,
				group_id: 0,
				location_id: 0,
				shipper_id: 0,
				carrier_map: { preferred_carrier: { code: '', name: '' }, acceptable_carriers: [] },
				threshold: 0,
			};
			// extra stuff to be stripped before save
			vm.override.carriersObject = { preferred_carrier: {}, acceptable: [] };
			vm.override.zip_codesDisplay = '';

			return vm.override;
		}

		function updateOverride(override) {
			// convert to storage formats
			override.carrier_map = unParseCarrierMap(override);
			override.zip_codes = unParseZipCodes(override);
			// strip stuff not stored
			delete override.orgname;
			delete override.carriersObject;
			delete override.zip_codesDisplay;
			delete override.acceptableCarriersDisplay;
			// save
			if ((!override.id) || +override.id === 0) { // no id, it's an add
				console.log('create');
				console.log(override);
				return AdminOverrideDataservice.create(override).then(function (data) {
					logger.success('Override created: ' + override.state_code);
					$state.go('admin.override.list', {}, { reload: true });
				});
			} else { // update
				console.log('update');
				console.log(override);
				return AdminOverrideDataservice.update(override).then(function (data) {
					logger.success('Override updated: ' + override.state_code);
					$state.go('admin.override.list', {}, { reload: true });
				});
			}
		}

		function removeOverride(id) {
			return AdminOverrideDataservice.remove(id).then(function (data) {
				logger.success('Override removed: ' + id);
				$state.go('admin.override.list', {}, { reload: true });
			});
		}

		function isDupOverride() {
			// check for dups
			// remove the one we're editing from the list
		  var checkOverrides = _.filter(vm.overrides, function (o) { return o.id !== vm.override.id; });
			// get all overrides w same state, org as the current override
			var foundStatesAndOrgs = _.filter(checkOverrides, function(o) {
				return ((o.state_code === vm.override.state_code) &&
					(o.organization_id === vm.override.organization_id));  // organization_id might be 0 for either/both
			});
			if (!!foundStatesAndOrgs) {
				// check for match with no zip (or exact match the whole list)
				var foundZips = _.filter(foundStatesAndOrgs, function (o) {
					return (o.zip_codesDisplay.trim() ===
						vm.override.zip_codesDisplay.trim());
				});
				// console.log(foundZips);
				if (foundZips.length > 0) return true;
				// check for match with zips present
				var zipList = (!!vm.override.zip_codeDisplay) ? vm.override.zip_codeDisplay.split(',') : [];
			  zipList = _.map(zipList, function (z) { return z.trim(); });
				// find matching zip in other overrides
				foundZips = _.filter(foundStatesAndOrgs, function (o) {
					var match = false;
				  _.each(zipList, function (z) { if (o.zip_codesDisplay.indexOf(z) > -1) { match = true; } });
					return match;
				});
				// if there's still one in here, we're duplicating
				return (foundZips.length > 0);
			} else return false;  // did not match on state and org
		}

		function isValid() {
			isChanged();
			vm.validation = [];
			// check for dups
			// don't look unless state and carrier already present
			if ((!!vm.override.state_code) &&
				(!!vm.override.carriersObject.preferred_carrier.code)) {
				if (isDupOverride()) {
					vm.validation.push('Configuration already exist, only unique combinations of state, zip code, and customer are allowed');
				}
			}
			if (!vm.override.state_code) {
				vm.validation.push('A state must be selected');
			}
			// if (!vm.override.organization_id ||
			// 	(vm.override.organization_id === 1)) {
			// 	vm.validation.push('A customer must be selected');
			// }
			// check carrier duplication
			if (!vm.override.carriersObject.preferred_carrier.code) {
				vm.validation.push('A preferred carrier must be selected');
			}

			return vm.validation.length === 0;
		}

		function isChanged() {
			vm.changesMade = true;
		}

	}

})();


		// vm.customer = { id: null, name: null };
		// if (+currentCustomer.id <= 0) {  // if empty, set it
		// 	currentCustomer.setCurrentCustomer(
		// 		(!!vm.currentUser.organizationId) ? vm.currentUser.organizationId : null,
		// 		(!!vm.currentUser.organizationName) ? vm.currentUser.organizationName : null
		// 	)
		// } // else assume passed in
		// vm.customer.id = currentCustomer.id;
		// vm.customer.name = currentCustomer.name;
		// vm.containerObj = { name: "Pallet(s)", value: "Pallet" };
		// vm.containerOptions = [];


		// function initCustomer() {
		// 	vm.customer.id = vm.override.organization_id;
		// 	vm.customer.name = vm.override.orgname;
		// 	currentCustomer.setCurrentCustomer(vm.customer.id, vm.customer.name);
		// }

		// function changeCustomerList() {
		// 	vm.customer = (!!vm.customer) ? vm.customer : { };
		// 	// vm.customer = (!!vm.customer) ? vm.customer : { id: '', name: '' };
		// 	currentCustomer.setCurrentCustomer(vm.customer.id, vm.customer.name);
		// 	loadOverrides();
		// }
		// function changeCustomerDetail() {
		// 	if (!!vm.customer) {
		// 		vm.override.organization_id = (!!vm.customer.id) ? vm.customer.id : null;
		// 		vm.override.orgname = (!!vm.customer.name) ? vm.customer.name : null;
		// 	} else {
		// 		vm.customer = { id: null, name: null };
		// 		vm.override.organization_id = null;
		// 		vm.override.orgname = null;
		// 	}
		// 	currentCustomer.setCurrentCustomer(vm.customer.id, vm.customer.name);
		// }

		// function overrideSearch() {
		// 	vm.search.orgId = vm.customer.id;
		// 	return adminOverrideDataservice.search(vm.search).then(function (data) {
		// 		vm.overrides = data;
		// 		return vm.overrides;
		// 	});
		// }
