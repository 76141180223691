(function() {
  'use strict';

  angular
    .module('app.accrualReport')
    .run(appRun);

  appRun.$inject = ['routerHelper'];
  /* @ngInject */
  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [
			{
				state: 'accrualReport',
				config: {
					url: '/accrualReport',
					templateUrl: 'app/areas/report/accrualReport.html',
					controller: 'AccrualReportController',
					controllerAs: 'vm',
					title: 'Accrual Report'
				}
			}
    ];
  }
})();
