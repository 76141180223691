(function () {
	'use strict';

	angular
		.module('app.profile')
		.controller('ProfileController', ProfileController);

	ProfileController.$inject = ['logger', 'currentUser', '$state', 'ProfileDataservice'];
	/* @ngInject */
	function ProfileController(logger, currentUser, $state, ProfileDataservice) {
		var vm = this;

		vm.message = null;
		vm.currentUser = currentUser;
		vm.login = login;
		vm.changePassword = changePassword;

		activate();

		//////////////////

		function activate() {
			// logger.info('Activated  View');
		}

		function login() {
			return ProfileDataservice.login(vm.username, vm.password).then(
				function (data) { // success callback
					if (currentUser.isLoggedIn()) {
						$state.go('dashboard');
					}
				},
				function (response) { // error callback
					if (response.status === 401) {
						logger.error("Oops! We didn't find a matching username and password. Please try again.");
						return;
					}
					logger.error('Yikes! We got an error while trying to log in. (' + response.statusText + ')');
				});
		}


		function changePassword() {
			// do something simple with password-match then changePassword
			// http://stackoverflow.com/a/19559681/121466
			// and http://ericpanorel.net/2013/10/05/angularjs-password-match-form-validation/
			if (vm.password !== vm.confirm) {
				logger.log('Oops! Password and confirmation do not match.');
				return;
			}
			return ProfileDataservice.changePassword(vm.currentUser.userId, vm.password).then(
				function (data) { // success callback
					logger.success('Password changed');
					// $state.go('dashboard');
				},
				function (response) { // error callback
					logger.error('Yikes! We got an error while trying to change the password. (' + response.statusText + ')');
				});
		}
	}

})();
