(function () {
	'use strict';

	angular
		.module('app')
		.factory('authInterceptor', authInterceptor);

	authInterceptor.$inject = ['$injector', '$q', '$window'];

	/* @ngInject */
	function authInterceptor($injector, $q, $window) {
		// Using $injector instead of auth service because we can't inject that at config time where this gets loaded
		// Nice gist on interceptors https://gist.github.com/gnomeontherun/5678505

		var siteurl = $window.location.origin;
		var interceptor = {
			request: _handleRequest,
			response: _handleResponse,
			responseError: _handleResponseError
		};
		return interceptor;

		function _handleRequest(config) {
			// automatically attach Authorization header if we're calling our own api
			if (!config.skipAuthToken && (config.url.indexOf('/api/') === 0 || config.url.indexOf(siteurl + '/api/') === 0)) {
				var auth = $injector.get('auth');
				var token = auth.getToken();
				if (token) {
					config.headers.Authorization = 'JWT ' + token;
				}
			}
			return config || $q.when(config);
		}

		function _handleResponse(res) {
			// If a token was sent back, save it
			var tokenPath = '/api/auth/token';
			if (res.config.url.substr(-tokenPath.length) === tokenPath && res.data.token) {
				var auth = $injector.get('auth');
				auth.saveToken(res.data.token);
			}
			return res || $q.when(res);
		}

		function _handleResponseError(res) {
			if (res.status === 401) {
				// we could do something nifty here
			}
			return $q.reject(res); // if you don't $q.reject... then errors get processed as success. see: http://jsfiddle.net/gadr/G3F8K/
		}

	}

})();
