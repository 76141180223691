(function () {
	'use strict';

	angular
		.module('app.products')
		.controller('ProductController', ProductController);

	ProductController.$inject = ['$http', 'logger', 'currentUser', 'currentCustomer', 'productsDataservice', '$q', '$state', 'auth'];
	/* @ngInject */
	function ProductController($http, logger, currentUser, currentCustomer, productsDataservice, $q, $state, auth) {
		var vm = this;
		vm.title = $state.current.title;
		vm.id = $state.params.id;
		vm.currentUser = currentUser;
		vm.customer = { id: null, name: null };
		if (+currentCustomer.id <= 0) {  // if empty, set it
			currentCustomer.setCurrentCustomer(
				(!!vm.currentUser.organizationId) ? vm.currentUser.organizationId : null,
				(!!vm.currentUser.organizationName) ? vm.currentUser.organizationName : null
			)
		} // else assume passed in
		vm.customer.id = currentCustomer.id;
		vm.customer.name = currentCustomer.name;
		vm.containerObj = { name: "", value: "" };
		vm.containerOptions = [];

		activate();

		//////////////////// 

		function activate() {
			var promises = [];
			switch ($state.current.name) {
				case 'product.list':
					vm.products = [];
					vm.search = { term: '', orgId: vm.customer.id };
					vm.organizations = [];
					vm.productSearch = productSearch;
					vm.removeProduct = removeProduct;
					vm.changeCustomerList = changeCustomerList;
					promises.push(loadOrganizations());
					promises.push(loadProducts());
					break;
				case 'product.create':
				case 'product.details':  // just to minimize lines of code, identical except for createProduct/updateProduct
					vm.product = {};
					vm.organizations = [];
					vm.validation = [];
					vm.changesMade = false;
					vm.updateProduct = updateProduct;
					vm.isValid = isValid;
					vm.isChanged = isChanged;
					vm.changeCustomerDetail = changeCustomerDetail;
					promises.push(loadOrganizations());
					promises.push(loadContainers());
					if ($state.current.name === 'product.create') {
						promises.push(createProduct());
					} else if ($state.current.name === 'product.details') {
						promises.push(getProductDetails());
					} else {
						break;  // shouldn't be any other options
					}
					break;
			}
			return $q.all(promises).then(function () {
				// logger.info('Activated Products View');
			});
		}
		function initCustomer() {
			vm.customer.id = vm.product.organization_id;
			vm.customer.name = vm.product.orgname;
			currentCustomer.setCurrentCustomer(vm.customer.id, vm.customer.name);
		}

		function changeCustomerList() {
			vm.customer = (!!vm.customer) ? vm.customer : { };
			// vm.customer = (!!vm.customer) ? vm.customer : { id: '', name: '' };
			currentCustomer.setCurrentCustomer(vm.customer.id, vm.customer.name);
			loadProducts();
		}
		function changeCustomerDetail() {
			if (!!vm.customer) {
				vm.product.organization_id = (!!vm.customer.id) ? vm.customer.id : null;
				vm.product.orgname = (!!vm.customer.name) ? vm.customer.name : null;
			} else {
				vm.customer = { id: null, name: null };
				vm.product.organization_id = null;
				vm.product.orgname = null;
			}
			currentCustomer.setCurrentCustomer(vm.customer.id, vm.customer.name);
		}

		function loadProducts() {
			// customer.id !== 1 is a hack, systemUser(s) have org id = 1
			if ((!vm.customer.id) || (vm.customer.id === 1)) {
				// no customer/system user, get all
				return productsDataservice.getProducts().then(function (data) {
					vm.products = data;
					return vm.products;
				});
			} else {
				return productsDataservice.getProductsByOrgId(vm.customer.id).then(function (data) {
					vm.products = data;
					return vm.products;
				});
			}
		}

		function loadOrganizations() {
			if (vm.currentUser.isSystemUser) {
				if (vm.organizations) {
					if (vm.organizations.length === 0) {
						return $http.get('/api/organizations').then(function (response) {
							vm.organizations = response.data;
						}, function (response) {
							console.log('error loading organizations');
							console.log(response);
						});
					}
				}
			}
		}

		function productSearch() {
			vm.search.orgId = vm.customer.id;
			return productsDataservice.search(vm.search).then(function (data) {
				vm.products = data;
				return vm.products;
			});
		}

		function createProduct() {
			// create an empty product object
			vm.product = {
				id: null,
				organization_id: vm.customer.id,
				item_reference: '',
				description: '',
				package_type: '',
				nmfc: '',
				class: '',
				length: 0,
				width: 0,
				height: 0,
				weight: 0,
			};
			return vm.product;
		}

		function getProductDetails() {
			return productsDataservice.getProductById(vm.customer.id, vm.id).then(function (data) {
				vm.product = data;
				initCustomer();
				initContainerSelection();
				return vm.product;
			});
		}

		function updateProduct(product) {
			// strip stuff not stored
			delete vm.product.orgname;
			if ((!product.id) || +product.id === 0) { // no id, it's an add
				return productsDataservice.createProduct(product).then(function (data) {
					logger.success('Product created: ' + product.description);
					$state.go('product.list', {}, { reload: true });
				});
			} else { // update
				return productsDataservice.updateProduct(product).then(function (data) {
					logger.success('Product updated: ' + product.description);
					$state.go('product.list', {}, { reload: true });
				});
			}
		}

		function removeProduct(id) {
			return productsDataservice.removeProduct(id).then(function (data) {
				logger.success('Product removed: ' + id);
				$state.go('product.list', {}, { reload: true });
			});
		}

		function isValid() {
			isChanged();
			vm.validation = [];

			if (!vm.product.organization_id ||
				(vm.product.organization_id === 1)) {
				vm.validation.push('A customer must be selected');
			}
			if (!vm.product.description) {
				vm.validation.push('Description is required');
			}
			if (!vm.product.package_type) {
				vm.validation.push('Pkg Type is required');
			}
			if (!vm.product.nmfc) {
				vm.validation.push('NMFC # is required');
			}
			if (!vm.product.class) {
				vm.validation.push('Class is required');
			}
			if (!vm.product.weight) {
				vm.validation.push('Weight is required');
			}
			return vm.validation.length === 0;
		}

		function isChanged() {
			vm.changesMade = true;
		}

		function loadContainers() {
			$http.get('/api/containers/').then(function successCallback(response) {
				vm.containerOptions = response.data;
			}, function failureCallback(response) {
				console.log(response);
			});
		}

		vm.setContainerSelection = function () {
			vm.product.package_type = vm.containerObj.name;
		}

		function initContainerSelection() {
			vm.containerObj.name = ((!!vm.product.package_type) ? vm.product.package_type : vm.containerObj.name).trim();
		  const c = _.find(vm.containerOptions, function (a) { return a.name == vm.containerObj.name; });
			vm.containerObj.value = (!!c) ? c.value : vm.containerObj.value;
		}

	}

})();

