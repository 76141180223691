(function () {
	'use strict';

	var module = angular.module('app.components');

	// /* @ngInject */
	// function controller($http) {
	function controller() {

	  var vm = this;
		vm.state = 'edit';  // shows expanded address section
		vm.validation = [];
		vm.contactsList = [
			// { name: 'name 2', phone: '9879879876', email: 'a@b.c', fax: '4564567' }
		];
		vm.contact = {};
		initAddress(); // to defeat the lag in lookupCityDetails affecting the angular refresh loop
		initContact();
		if ((!!vm.entity) && (!!vm.entity.contacts)) {
			initContactsList(vm.entity.contacts);
			assignEntityContact();
		}
		vm.canSave = false;

		// Handle component lifecycle events
		vm.$onInit = function () {
			/**
			 * Called on each controller after all the controllers on an element have been constructed
			 * and had their bindings initialized (and before the pre & post linking functions for the
			 * directives on this element). This is a good place to put initialization code for your
			 * controller.
			 */
			//doSomeDefaultStuff();
		};

		vm.$onChanges = function () {
			// console.log('$onChanges', vm.title)
			// console.log(vm.entity.contacts)
			if (!vm.entity.contacts) {
				initContact();
				vm.contactsList = [];
			} 
			// console.log(vm.contactsList);
		}
		// vm.$doCheck = function () {
		// 	console.log('$doCheck')
		// }

		vm.closeEdit = function () {
			if (isValid()) {
				vm.state = '';  // this causes address section to close
			}
		}

		vm.validate = function () {
			if (isValid()) {
				vm.canSave = true;
				// vm.state = '';  // this causes address section to close
			}
		}

		function initAddress() {
			vm.entity = (!!vm.entity) ? vm.entity : {};
			vm.entity.city = (!!vm.entity.city) ? vm.entity.city : '';
			vm.entity.state_code = (!!vm.entity.state_code) ? vm.entity.state_code : '';
			vm.entity.postal_code = (!!vm.entity.postal_code) ? vm.entity.postal_code : '';
		}

	
		function isValid() {
			vm.validation = [];

			if (!vm.entity.isInternational) {
				if ((!vm.entity.postal_code) || (vm.entity.postal_code.length < 5)) {
					vm.validation.push('A zip code is required');
				}
				if ((!vm.entity.state_code) || (vm.entity.state_code.length < 2)) {
					vm.validation.push('A state or province is required');
				}
			}
			if ((!vm.entity.address_line_1) || (vm.entity.address_line_1.length < 5)) {
				vm.validation.push('An address is required');
			}
			if ((!vm.entity.city) || (vm.entity.city.length < 2)) {
				vm.validation.push('A city is required');
			}

			if ((!vm.entity.contact_name) || (vm.entity.contact_name.length < 3)) {
				vm.validation.push('A contact name is required');
			}
			if (vm.showEmail) {
				if (!!vm.entity.contact_email) {
					// check format
					if (!isValidEmail(vm.entity.contact_email)) {
						vm.validation.push('Contact email format is not valid');
					}
				}
			}
			if ((!vm.entity.contact_phone) || (vm.entity.contact_phone.length < 3)) {
				vm.validation.push('A contact phone is required');
			}

			vm.entity.inputIsValid = vm.validation.length === 0;
			return vm.validation.length === 0;
			// }, 10000);
		}


		vm.setAddressSelection = function (item) {
			vm.entity.address_id = item.id;
			vm.entity.name = item.name;
			vm.entity.address_line_1 = item.address_1;
			vm.entity.address_line_1 = item.address_1;
			vm.entity.city = item.city;
			vm.entity.state_code = item.state_code;
			vm.entity.postal_code = item.postal_code;
			vm.entity.country = item.country;
			vm.entity.is_shipping = item.is_shipping;
			vm.entity.is_destination = item.is_destination;
			vm.entity.is_billto = item.is_billto;
			initContactsList(item);
			assignEntityContact();
			isValid();
			vm.formCheck();
			vm.newAddress = false;
		}


		vm.setContactSelection = function () {
			assignEntityContact();
			vm.addingContact = false;
		}

		vm.addContact = function () {
			if (!vm.addingContact) return;
			var newContact = {
				name: vm.entity.contact_name,
				phone: vm.entity.contact_phone,
				extension: vm.entity.contact_extension,
				email: vm.entity.contact_email,
				fax: vm.entity.contact_fax,
			};
			vm.contactsList.push(newContact);
			vm.entity.contacts = '{ "contacts": ' + JSON.stringify(vm.contactsList) + "}";
			copyContact(vm.contactsList[vm.contactsList.length - 1], vm.contact);
			vm.addingContact = false;
		}

		function initContactsList(item) {
			vm.contactsList = breakOutContacts(item.contacts);
			if (vm.contactsList.length > 0) {
				copyContact(vm.contactsList[0], vm.contact);
				vm.addingContact = false;
			}
			else {
				initContact();
			}
		}
		function initContact() {
			vm.addingContact = true;
			copyContact({ name: '', phone: '', extension: '', email: '', fax: '' }, vm.contact);
		}

		function assignEntityContact() {
			vm.entity.contact_name = vm.contact.name;
			vm.entity.contact_phone = vm.contact.phone;
			vm.entity.contact_extension = vm.contact.extension;
			vm.entity.contact_email = vm.contact.email;
			vm.entity.contact_fax = vm.contact.fax;
		}

		function breakOutContacts(contacts) {
			// console.log(contacts);
			var c = [];
			var jc 
			// console.log(typeof contacts);
			(typeof contacts === 'object') ? jc = contacts : jc = JSON.parse(contacts);
			if (!!jc) {
				if (!!jc.contacts) {
					_.each(jc.contacts, function(i) {
						c.push(i);
					});
				}
			}
			// vm.contactsList = c;
			return c;
		}

		function copyContact(from, to) {
			// individual assignments work like a cloneDeep (but actually works)
			to.name = from.name;
			to.phone = from.phone;
			to.extension = from.extension;
			to.email = from.email;
			to.fax = from.fax;
		}

		function isValidEmail(email) {
			var valid = true;
			valid = (!!email);
			valid = email.indexOf('@');
			valid = email.indexOf('.');
			valid = email.indexOf('.') < email.length;
		  valid = email.indexOf('@') < email.lastIndexOf('.');
			return valid;
		}

	}

	module.component('shippingEntity', {
		bindings: {
			entity: '<',
			title: '@',
			cityLookup: '&',
			formCheck: '&',
			entityTypeName: '@',
			showEmail: '<',
			searchAddress: '&',
			matchingAddresses: '=',
			newAddress: '=',
		  saveAddress: '&',
			quick: '@'
		},
		controllerAs: 'vm',
		controller: [controller], // list of DI items with controller method as the last item
		templateUrl: 'app/components/orderEntry/shippingEntity.component.html'
	});

	module.filter('tel', function () {
		return function (tel) {
			if (!tel) { return ''; }

			var value = tel.toString().trim().replace(/^\+/, '');

			if (value.match(/[^0-9]/)) {
				return tel;
			}

			var country, city, number;

			switch (value.length) {
				case 1:
				case 2:
				case 3:
					city = value;
					break;

				default:
					city = value.slice(0, 3);
					number = value.slice(3);
			}

			if (number) {
				if (number.length > 3) {
					number = number.slice(0, 3) + '-' + number.slice(3, 7);
				}
				else {
					number = number;
				}

				return ("(" + city + ") " + number).trim();
			}
			else {
				return "(" + city;
			}

		};
	});

}());
