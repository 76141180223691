(function () {
	'use strict';

	angular
		.module('app.admin')
		.factory('AdminAcctShipperDataservice', AdminAcctShipperDataservice);

	AdminAcctShipperDataservice.$inject = ['$http', '$q', 'exception', 'logger'];
	/* @ngInject */
	function AdminAcctShipperDataservice($http, $q, exception, logger) {

		var service = {
			getAcctShippers: getAcctShippers,
			getById: getById,
			create: create,
			update: update,
			remove: remove
		};

		return service;

		function getAcctShippers() {
			return $http.get('/api/acctshippers')
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getAcctShippers')(e);
			}
		}

		function getById(id) {
			return $http.get('/api/acctshippers/' + id)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getById')(e);
			}
		}

		function create(newOverride) {
			return $http.post('/api/acctshippers', newOverride).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				if(e.status === 400)
				{
					logger.error('Customer Carrier already exists');
				} else {
					logger.error('Failed to create Customer Carrier');
				}

				return null;
			}
		}

		function update(newOverride) {
			return $http.put('/api/acctshippers/' + newOverride.id, newOverride).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				logger.error('Failed to update Customer Carrier');
				return null;
			}
		}

		function remove(id) {
			return $http.delete('/api/acctshippers/' + id).then(success).catch(fail);
			function success(response) {
				return response.data;
			}

			function fail(e) {
				return logger.error('Failed to delete Customer Carrier: ' + e);
			}
		}
	}
})();
