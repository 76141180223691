(function () {
	'use strict';

	angular
		.module('app.admin')
		.controller('AdminSystemUserController', AdminSystemUserController);

	AdminSystemUserController.$inject = ['logger', 'currentUser', 'adminSystemUserDataservice', '$q', '$state', '$uibModal', 'auth', '$window'];
	/* @ngInject */
	function AdminSystemUserController(logger, currentUser, adminSystemUserDataservice, $q, $state, $uibModal, auth, $window) {
		var vm = this;
		vm.title = $state.current.title;
		vm.currentUser = currentUser;
		vm.users = [];
		vm.page = {
			icon: 'users',
			heading: 'System Users',
			tooltip: ''
		};
		vm.createUser = createUser;
		vm.removeUser = removeUser;

		activate();

		////////////////////

		function activate() {
			var promises = [loadAll()];
			return $q.all(promises).then(function () {
				// logger.info('Activated Admin Accounts View');
			});
		}

		function loadAll() {
			return adminSystemUserDataservice.get().then(function (data) {
				vm.users = data;
				return vm.users;
			});
		}

		function createUser() {
			if(vm.newUser && vm.newUser.pass && vm.newUser.username) {
				return adminSystemUserDataservice.create(vm.newUser).then(function (data) {
					if(data) {
						logger.success('System User created: ' + data.username);
						$state.go('admin.sysuser.list', null, {reload: true});
					}
				});
			} else {
				logger.error('No inputs can be left blank');
			}
			vm.newUser = {};
		}

		function removeUser(user) {
			return adminSystemUserDataservice.removeUser(user.id).then(function (data) {
				logger.success('User removed: ' + user.username);
				$state.go('admin.sysuser.list', { id: vm.organizationId }, {reload: true});
			});
		}
	}
})();
