(function () {
	'use strict';

	angular
		.module('app.dashboard')
		.controller('DashboardController', DashboardController);

	DashboardController.$inject = ['logger', 'currentUser', 'dashboardDataservice', '$window', '$q', '$state', '$scope', '$timeout', 'uiGridConstants', 'localStorageService', 'ModalService'];

	/* @ngInject */
	function DashboardController(logger, currentUser, dashboardDataservice, $window, $q, $state, $scope, $timeout, uiGridConstants, localStorageService, ModalService) {
	  var vm = this;
	  window.kd = vm;
	  vm.currentUser = currentUser;
		vm.selectedStatus = '';
		vm.title = 'Dashboard';
		vm.state = '';
		vm.quickSearchValue = '';
		vm.filters = [];
		vm.orders = [];
		vm.orderDetails = {};
		vm.retrievedOrders = [];
		vm.gridOptions = {};
		vm.gridHeight = getGridHeight();
		vm.orderId = $state.params.id;
		vm.palletItems = [];
		vm.includeCancelledShipments = false;
	  vm.includeDeliveredShipments = false;
	  vm.weeklyGridColumns = ["Time Window", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
	  vm.weeklyGridData = {};
	  vm.selectedOrder = false;
	  vm.selectWeekProcessing = false;
	  vm.systemUser = currentUser.isSystemUser;

	  var today = new Date();
	  var cday = today.getDay();
	  if (cday > 0) {
			today.setDate(today.getDate() - cday);			
	  }

	  var getNextWeek = function (startingDate) {
			var tempDate = new Date(startingDate.getFullYear(), startingDate.getMonth(), startingDate.getDate());
			tempDate.setDate(tempDate.getDate() + 7);
			return tempDate;
	  };
	  var getPreviousWeek = function (startingDate) {
		var tempDate = new Date(startingDate.getFullYear(), startingDate.getMonth(), startingDate.getDate());
		tempDate.setDate(tempDate.getDate() - 7);
		return tempDate;
	  };

	  var firstSunday = today;
	  var secondSunday = getNextWeek(firstSunday);
	  var thirdSunday = getNextWeek(secondSunday);
	  var fourthSunday = getNextWeek(thirdSunday);
	  var fifthSunday = getNextWeek(fourthSunday);
	  var prevSunday = getPreviousWeek(firstSunday);

	  vm.sundays = [prevSunday, firstSunday, secondSunday, thirdSunday, fourthSunday, fifthSunday];
	  vm.selectedSunday = null;
	  vm.weeklyGridData = {};
	  vm.dayMap = {};
	  _.each(vm.sundays, function (aSunday) {
		var aSundayLocaleString = aSunday.toLocaleDateString();
		vm.weeklyGridData[aSundayLocaleString] = {
				"12AM - 6AM": {},
				"6AM - 12PM": {},
				"12PM - 6PM": {},
				"6PM - 12AM": {}
			};
			var day = new Date(aSunday.toISOString()); //make new date to prevent side effects

			for (var i = 0; i < 7; i++) {
				var dateString = day.toLocaleDateString();
			  vm.dayMap[day.toISOString().slice(0, 10)] = aSundayLocaleString;
			  vm.weeklyGridData[aSundayLocaleString]["12AM - 6AM"][dateString] = [{ id: false, date: dateString, status: "FAKE" }];
			  vm.weeklyGridData[aSundayLocaleString]["6AM - 12PM"][dateString] = [{ id: false, date: dateString, status: "FAKE" }];
			  vm.weeklyGridData[aSundayLocaleString]["12PM - 6PM"][dateString] = [{ id: false, date: dateString, status: "FAKE" }];
			  vm.weeklyGridData[aSundayLocaleString]["6PM - 12AM"][dateString] = [{ id: false, date: dateString, status: "FAKE" }];
				day.setDate(day.getDate() + 1);
			}
	  });

		var ics = localStorageService.get('includeCancelledShipments')
		if(ics) vm.includeCancelledShipments = ics;

		var ids = localStorageService.get('includeDeliveredShipments');
		if(ids) vm.includeDeliveredShipments = ids;

		vm.statusArray = [];
		vm.manualCount = 0;
		vm.dispatchedAwaitingPickupCount = 0;
		vm.dispatchedNotPickedUpCount = 0;
		vm.inTransitCount = 0;
		vm.inTransitNotDeliveredCount = 0;
		vm.delayedCount = 0;
		vm.deliveredCount = 0;
	  vm.cancelledCount = 0;
	  vm.modal = document.getElementById("weeklyOrderSummary");
		

		activate();

		vm.quickSearch = function () {
			if (vm.quickSearchValue) {
				getOrder(vm.quickSearchValue);
			}
		};
	  vm.openSummary = function (orderObj) {
			vm.selectedOrder = orderObj;
	  };
	  vm.closeSummary = function () {
			vm.selectedOrder = false;
	  };
	/*  vm.selectNewWeek = function () {
		if (vm.selectedSunday) {
		  console.log('new week selected');
				vm.selectWeekProcessing = true;

			  window.wgd = vm.weeklyGridData;
				if (!vm.weeklyGridData[vm.selectedSunday]) {
					vm.weeklyGridData[vm.selectedSunday] = {
						"12AM - 6AM": {},
						"6AM - 12PM": {},
						"12PM - 6PM": {},
						"6PM - 12AM": {}
					};
					var day = new Date(this.selectedSunday);
				  var relevantDays = [];
				  window.rds = relevantDays;
					for (var i = 0; i < 7; i++) {
						var dateString = day.toLocaleDateString();
						relevantDays.push(day.toISOString().slice(0, 10));
						vm.weeklyGridData[vm.selectedSunday]["12AM - 6AM"][dateString] = [{ id: false, date: dateString, status: "FAKE" }];
						vm.weeklyGridData[vm.selectedSunday]["6AM - 12PM"][dateString] = [{ id: false, date: dateString, status: "FAKE" }];
						vm.weeklyGridData[vm.selectedSunday]["12PM - 6PM"][dateString] = [{ id: false, date: dateString, status: "FAKE" }];
						vm.weeklyGridData[vm.selectedSunday]["6PM - 12AM"][dateString] = [{ id: false, date: dateString, status: "FAKE" }];
						day.setDate(day.getDate() + 1);
				  }
				  if (vm.orders && vm.orders.length > 0) {
					console.log('getting orders for new week');
						_.each(vm.orders, function (order) {
							if (_.indexOf(relevantDays, order.estimated_delivery_date) >= 0) {
							  dashboardDataservice.getOrderBySwNumber(order.orderId, false).then(function (orderDetail) {
									console.log('getting details for an order');
										try {
											if (orderDetail && orderDetail.details && orderDetail.details.request && orderDetail.details.request.quote && orderDetail.details.request.quote.delivery_window) {
											var po = _.find(orderDetail.details.response.shipmentIdentifiers, function (si) { return si.type === "PURCHASE_NUMBER"; });
											var so = _.find(orderDetail.details.response.shipmentIdentifiers, function (si) { return si.type === "PURCHASE_NUMBER"; });

											vm.weeklyGridData[vm.selectedSunday][orderDetail.details.request.quote.delivery_window][orderDetail.estimated_delivery_date.toLocaleDateString()].push({
												id: orderDetail.id,
												po: po ? po.value : '',
												name: orderDetail.details.request.shipment.origin.name,
												date: orderDetail.delivery_date ? orderDetail.delivery_date.toLocaleDateString() : orderDetail.estimated_delivery_date.toLocaleDateString(),
												time: orderDetail.details.request.quote.delivery_window,
												status: orderDetail.status,
												so: so ? so.value : '',
												pw: 'PW' + orderDetail.id,
												customer: orderDetail.customer_name,
												carrier: orderDetail.details.request.quote.carrier_name,
												bol: orderDetail.bol_number,
												cost: '$' + orderDetail.total,
												service: orderDetail.details.request.quote.service_code + ' - TRUCKLOAD (' + orderDetail.details.request.quote.transit_days + ')',
												destination: orderDetail.details.request.shipment.destination.name
											});
											console.log('added order to week grid');
										}
									} catch (err) {
										window.errs = window.errs || [];
										window.errs.push(err);
										window.ors = window.ors || [];
										window.ors.push({ order: order, detail: orderDetail });
										console.log('error selecting week, review errors');
									}
									vm.selectWeekProcessing = false;
									console.log('order details retrieved and week finished');
								});
							}
						});
				  } else {
						vm.selectWeekProcessing = false;
						console.log('no orders for week period');
				  }
				} else {
				  vm.selectWeekProcessing = false;
				  console.log('week period previously loaded');
				}
			}
	  };
*/
	  vm.changeStatus = function () {
			//console.log('status changed');
			//console.log(vm.selectedOrder.trailerStatus);
			if (vm.selectedOrder && vm.selectedOrder.id && vm.orderDetails[vm.selectedOrder.id]) {
				vm.orderDetails[vm.selectedOrder.id].details.request.quote.trailer_status = vm.selectedOrder.trailerStatus;
				dashboardDataservice.updateOrder(vm.orderDetails[vm.selectedOrder.id]);
				console.log('changed');
			} else {
				console.log('not changed');
			}
	  };

	  vm.deliverOrder = function () {
			if (vm.selectedOrder && vm.selectedOrder.id && vm.orderDetails[vm.selectedOrder.id]) {			
				vm.orderDetails[vm.selectedOrder.id].details.request.quote.trailer_status = 'DELIVERED';
				vm.selectedOrder.trailerStatus = 'DELIVERED';
				dashboardDataservice.updateOrder(vm.orderDetails[vm.selectedOrder.id]);
			}
	  };

	  vm.dropOrder = function () {
			if (vm.selectedOrder && vm.selectedOrder.id && vm.orderDetails[vm.selectedOrder.id]) {
				vm.orderDetails[vm.selectedOrder.id].details.request.quote.trailer_status = 'DROPPED';
				vm.selectedOrder.trailerStatus = 'DROPPED';
				dashboardDataservice.updateOrder(vm.orderDetails[vm.selectedOrder.id]);
			}
	  };

		vm.updateSearch = function () {
			addOrderData(vm.selectedStatus);
			getAllStatusCount();
		}

		function activate() {
			// logger.info('Activated Dashboard View');
			vm.selectedStatus = $state.params.status || '';
			var promises = [];
			switch ($state.current.name) {
				case 'dashboard':
					promises.push(configureFilters());
					promises.push(addOrderData(vm.selectedStatus));
					promises.push(getAllStatusCount());
					break;
				case 'dashboard-details':
					promises.push(getOrderDetailsWithAccessorials(vm.orderId));
					break;
				case 'dashboard-bol-print':
				case 'dashboard-shipping-pallet-label-print-avery':
				case 'dashboard-shipping-pallet-label-print-full':
				case 'dashboard-order-quote':
					promises.push(getOrderDetails(vm.orderId));
					break;
				case 'rlms-bol-print':
					promises.push(getOrderDetails(vm.orderId, true));
					break;
				case 'supplier-bol-print':
					promises.push(getOrderDetails(vm.orderId, true));
					break;
				case 'dashboard-order-label':
					promises.push(getOrderDetailsForLabel(vm.orderId, false));
					break;
				case 'dashboard-order-labels-all':
					promises.push(getOrderDetailsForLabel(vm.orderId, true));
					break;
				case 'rlms-order-labels-all':
				case 'rlms-order-label':
				case 'rlms-shipping-pallet-label-print-avery':
				case 'rlms-shipping-pallet-label-print-full':
					promises.push(getOrderDetailsForLabel(vm.orderId, true, true));
					break;
				case 'supplier-order-labels-all':
				case 'supplier-order-label':
				case 'supplier-shipping-pallet-label-print-avery':
				case 'supplier-shipping-pallet-label-print-full':
					promises.push(getOrderDetailsForLabel(vm.orderId, true, true));
					break;
			}
			return $q.all(promises).then(function () {
				// logger.info('Activated Dashboard View');
			});
		}

		function getGridHeight() {
			return $window.innerHeight - 240;
			// consider using a resizable directive like this http://microblog.anthonyestebe.com/2013-11-30/window-resize-event-with-angular/
			// or something like this https://stackoverflow.com/a/31458429/121466
		}

		function getOrder(orderId) {
			if (orderId) {
				$state.go('dashboard-details', { id: orderId }, { reload: true });
			}
		}

		function getMQForm(orderId) {
			if (orderId) {
				$state.go('bolEntry-quote', { id: orderId }, { reload: true });
			}
		}

		function getOrderDetailsForLabel(orderId, getFullOrder, useRlmsRoute) {
			return dashboardDataservice.getOrderBySwNumber(orderId, useRlmsRoute).then(
				function (data) {
					if (getFullOrder) {
						vm.orderDetails = data;
						if (!vm.orderDetails.infoMessages && vm.orderDetails.details.response.infoMessages) {
							vm.orderDetails.infoMessages = vm.orderDetails.details.response.infoMessages;
						}
						if (!vm.orderDetails.labels && vm.orderDetails.details.response.labels) {
							vm.orderDetails.labels = vm.orderDetails.details.response.labels;
						}
						if (!vm.orderDetails.pickupNote && vm.orderDetails.details.response.pickupNote) {
							vm.orderDetails.pickupNote = vm.orderDetails.details.response.pickupNote;
						}
						if (!vm.orderDetails.pickup_request_sent && vm.orderDetails.details.response.pickup_request_sent) {
							vm.orderDetails.pickup_request_sent = vm.orderDetails.details.response.pickup_request_sent;
						}
						if (!vm.orderDetails.shipmentIdentifiers && vm.orderDetails.details.response.shipmentIdentifiers) {
							vm.orderDetails.shipmentIdentifiers = vm.orderDetails.details.response.shipmentIdentifiers;
						}
						if (!vm.orderDetails.carrier_code && vm.orderDetails.details.response.carrier_code) {
							vm.orderDetails.carrier_code = vm.orderDetails.details.response.carrier_code;
						}
						/*if (!vm.orderDetails. && vm.orderDetails.details.response.) {
							vm.orderDetails. = vm.orderDetails.details.response.;
						}*/
					}
					else {
						vm.orderDetails = data.details.response;
						vm.orderDetails.carrier_code = data.carrier_code;
						vm.orderDetails.id = orderId;
					}
				},
				function (response) { // error callback
					vm.orderDetails.error = response.data.description; // gets displayed on the UI verbatim
					if (response.data.name !== 'OrderNotFound' && response.data.name !== 'OrderDetailsNotFound') {
						console.error(error);
					}
				});
		}

		function getOrderDetails(orderId, useRlmsRoute) {
			return dashboardDataservice.getOrderBySwNumber(orderId, useRlmsRoute).then(
				function (data) { // success callback
					vm.orderDetails = data;
				},
				function (response) { // error callback
					vm.orderDetails.error = response.data.description; // gets displayed on the UI verbatim
					if (response.data.name !== 'OrderNotFound' && response.data.name !== 'OrderDetailsNotFound') {
						console.error(error);
					}
				});
		}

		function getOrderDetailsWithAccessorials(orderId) {
			return dashboardDataservice.getOrderBySwNumber(orderId, false).then(
				function (data) { //success callback
					vm.orderDetails = data;
					addDescriptionsToAccessorials();
				},
				function (response) { // error callback
					vm.orderDetails.error = response.data.description // gets displayed on the UI verbatim					
				}
			);
		}

		function addDescriptionsToAccessorials() {
			if (!vm.orderDetails || !vm.orderDetails.details.request.shipment.origin.additional_services) return;

			return dashboardDataservice.getAccessorialDescriptions(vm.orderDetails.details.request.shipment.origin.additional_services).then(
				function (data) { // success callback
					vm.orderDetails.details.request.shipment.origin.additional_services = data;
				},
				function (response) { // error callback
					console.log(response);
				});
		}

		function getAllStatusCount() {
			return dashboardDataservice.getAllStatus()
				.then(function (response) {
					vm.statusArray = response;

					vm.allCount = 0;
					if (vm.statusArray.length > 0) {
						_.each(vm.statusArray, function (item) {
							switch (item.code) {
								case 'MQ':
									vm.manualCount = item.count;
									vm.allCount += item.count;
									break;
								case 'DISP-AP':
									vm.dispatchedAwaitingPickupCount = item.count;
									vm.allCount += item.count;
									break;
								case 'DISP-NPU':
									vm.dispatchedNotPickedUpCount = item.count;
									vm.allCount += item.count;
									break;
								case 'ON-TIME':
									vm.inTransitCount = item.count;
									vm.allCount += item.count;
									break;
								case 'NO-DEL':
									vm.inTransitNotDeliveredCount = item.count;
									vm.allCount += item.count;
									break;
								case 'DELAY':
									vm.delayedCount = item.count;
									vm.allCount += item.count;
									break;
								case 'DELIVERED':
									if(vm.includeDeliveredShipments){
										vm.deliveredCount = item.count;
										vm.allCount += item.count;	
									}
									break;
								case 'CX':
									if(vm.includeCancelledShipments){
										vm.cancelledCount = item.count;
										vm.allCount += item.count;	
									}
									break;
								default:
									break;
							}
						})
					}
				}, function (response) { // error callback
					vm.state = 'error';
					vm.errorFeedback = 'Unable to get all status counts. Status: ' + response.status;
				});
		}
		
		function addOrderData(selectedStatus) {
			localStorageService.set('includeCancelledShipments', vm.includeCancelledShipments);
			localStorageService.set('includeDeliveredShipments', vm.includeDeliveredShipments);
			return dashboardDataservice.getOrders(selectedStatus, vm.includeCancelledShipments, vm.includeDeliveredShipments).then(
				function (data) { // successful promise resolve
					vm.orders = [];
					var response = data;
				  vm.orderDetails = {};
			
				  if (response.length > 0) {
					_.each(response, function (order) {
					  if (vm.currentUser.organizationId === 72) {
							var orderDateString = new Date(order.estimated_delivery_date).toISOString().slice(0, 10);
							if (vm.dayMap[orderDateString]) {
								dashboardDataservice.getOrderBySwNumber(order.id, false).then(function (orderDetail) {
								try {
									var sundayString = vm.dayMap[orderDateString];
										if (orderDetail && orderDetail.details && orderDetail.details.request && orderDetail.details.request.quote && orderDetail.details.request.quote.delivery_window) {
											var po = _.find(orderDetail.details.response.shipmentIdentifiers, function (si) { return si.type === "PURCHASE_NUMBER"; });
											var so = _.find(orderDetail.details.response.shipmentIdentifiers, function (si) { return si.type === "PURCHASE_NUMBER"; });
										  vm.orderDetails[orderDetail.id] = orderDetail;
											vm.weeklyGridData[sundayString][orderDetail.details.request.quote.delivery_window][orderDetail.estimated_delivery_date.toLocaleDateString()].push({
												id: orderDetail.id,
												po: po ? po.value : '',
												name: orderDetail.details.request.shipment.origin.name,
												date: orderDetail.delivery_date ? orderDetail.delivery_date.toLocaleDateString() : orderDetail.estimated_delivery_date.toLocaleDateString(),
												time: orderDetail.details.request.quote.delivery_window,
												status: orderDetail.status,
												so: so ? so.value : '',
												pw: 'PW' + orderDetail.id,
												customer: orderDetail.customer_name,
												carrier: orderDetail.details.request.quote.carrier_name,
												bol: orderDetail.bol_number,
												cost: '$' + orderDetail.total,
												service: orderDetail.details.request.quote.service_code + ' - TRUCKLOAD (' + orderDetail.details.request.quote.transit_days + ')',
											  destination: orderDetail.details.request.shipment.destination.name,
											  trailerStatus: orderDetail.details.request.quote.trailer_status || 'TRANSIT'
											});
										}
									} catch (err) {
										window.errs = window.errs || [];
										window.errs.push(err);
										window.ors = window.ors || [];
										window.ors.push({ order: order, detail: orderDetail });
										console.log('error selecting week, review errors');
									}
								});
							}
					  } else {
							vm.orders.push({
								orderId: order.id,
								customer: order.customer_name,
								order_date: order.order_date,
								estimated_delivery_date: new Date(order.estimated_delivery_date).toISOString().slice(0, 10),
								carrier_estimated_delivery_date: order.carrier_estimated_delivery_date && order.carrier_estimated_delivery_date.length > 0 ? new Date(order.carrier_estimated_delivery_date).toISOString().slice(0, 10) : '',
								status: order.status,
								pieces: order.piece_count,
								weight: order.total_weight,
								origin: order.origin_postal_code,
								destination: order.destination_postal_code,
								carrier: order.carrier_name,
								is_manual: order.is_manual && order.is_manual === 'true' ? 'MANUAL' : 'AUTO',
								proNumber: order.pro_number,
								dealer_number: order.dealer_number,
								return_number: order.return_number,
								note_count: order.note_count
							});
					  }
					});
						if (vm.currentUser.organizationId !== 72) {
							//Add the data to the grid
						  vm.gridOptions.data = vm.orders;
							logger.info('Orders data has been refreshed');
						}
					}
				}).catch(
					function (response) {
						vm.state = 'error';
						vm.errorFeedback = 'Unable to add order data to orders. Status: ' + (response.status || 'Error');
				});
		}

		// todo: rework the way we are loading the data and then hook up the
		// columnDefs and the data at the same time in this controller
		// see this for other grid options when we do that http://ui-grid.info/docs/#/api/ui.grid.class:GridOptions

		vm.gridOptions = {
			enableFiltering: true,
			flatEntityAccess: true,
			showGridFooter: true,
			fastWatch: true,
		};

		// http://ui-grid.info/docs/#/api/ui.grid.class:GridColumn look for Properties heading
		vm.gridOptions.columnDefs = [
			{
				name: 'linkColumn', displayName: 'View Details',
				enableFiltering: false, enableSorting: false, enableColumnMenu: false,
				cellTemplate: '<div style="text-align: center"><a ng-href="#" ng-show="row.entity.status !== \'MQ\' && row.entity.status !== \'QT\'" ng-click="grid.appScope.vm.showOrderDetails(row.entity.orderId);">View Details</a><a ng-href="#" ng-show="row.entity.status === \'MQ\' || row.entity.status === \'QT\'" ng-click="grid.appScope.vm.showMQForm(row.entity.orderId);">Process</a></div>'
			},
			{name: 'orderId', displayName: 'Order Id', type: 'number', sort: { direction: uiGridConstants.DESC, ignoreSort: false, priority: 0 }},
			{name: 'customer'},
			{name: 'order_date', type: 'date'},			
			{name: 'estimated_delivery_date', type: 'date'},
			{name: 'carrier_estimated_delivery_date', type: 'date'},
			{name: 'status'},
			{name: 'pieces',type: 'number'},
			{name: 'weight',type: 'number'},
			{name: 'origin',type: 'number'},
			{name: 'destination',type: 'number'},
			{name: 'carrier'},
			{name: 'is_manual', displayName: 'Type'},			
			{name: 'proNumber', displayName: 'Pro #',type: 'number'},
			{name: 'dealer_number',type: 'number', displayName: 'Dealer #'},		
			{name: 'return_number',type: 'number', displayName: 'Return #'},					
			{name: 'note_count',type: 'number'}
		];

		vm.gridOptions.onRegisterApi = function(gridApi) {
			// Keep a reference to the gridApi.
			$scope.gridApi = gridApi;
		
			// Setup events so we're notified when grid state changes.
			// $scope.gridApi.colMovable.on.columnPositionChanged($scope, saveState);
			// $scope.gridApi.colResizable.on.columnSizeChanged($scope, saveState);
			// $scope.gridApi.grouping.on.aggregationChanged($scope, saveState);
			// $scope.gridApi.grouping.on.groupingChanged($scope, saveState);
			// $scope.gridApi.core.on.columnVisibilityChanged($scope, saveState);
			$scope.gridApi.core.on.filterChanged($scope, saveState);
			$scope.gridApi.core.on.sortChanged($scope, saveState);
		
			// Restore previously saved state.
			restoreState();
		}

		function saveState() {
			var state = $scope.gridApi.saveState.save();
			localStorageService.set('gridState', state);			
		}
		
		function restoreState() {
			$timeout(function() {
				var state = localStorageService.get('gridState');
				if (state) $scope.gridApi.saveState.restore($scope, state);
			});
		}

		vm.clearAllFilters = function() { 
			$scope.gridApi.grid.clearAllFilters();
			vm.updateSearch();
		}

		vm.showMQForm = function (orderId) {
			getMQForm(orderId);
		};

		vm.showOrderDetails = function (orderId) {
			getOrder(orderId);
		};

		function configureFilters() {
			vm.filters = [];

			// STATUS
			vm.filters.push({
				id: 'status_code',
				label: 'Status',
				options: [
					{
						id: 'new',
						description: 'New'
					},
					{
						id: 'dispatched',
						description: 'Dispatched'
					},
					{
						id: 'picked_up',
						description: 'Picked Up'
					},
					{
						id: 'in_transit',
						description: 'In Transit'
					},
					{
						id: 'delivered',
						description: 'Delivered'
					},
					{
						id: 'idk',
						description: 'Exceptions?'
					}
				]
			});

			// ORIGIN POSTAL CODE
			vm.filters.push({
				id: 'o_postal',
				label: 'Origin Postal'
			});

			// DESTINATION POSTAL CODE
			vm.filters.push({
				id: 'd_postal',
				label: 'Dest. Postal'
			});

			// CARRIER
			vm.filters.push({
				id: 'carrier_code',
				label: 'Carrier',
				options: [
					{
						id: 1,
						description: 'TODO from DB'
					}
				]
			});

			// ESTIMATED DELIVERY DATE
			vm.filters.push({
				id: 'estimated_delivery_date',
				label: 'Est Delivery',
			});

			// CARRIER ESTIMATED DELIVERY DATE
			vm.filters.push({
				id: 'carrier_estimated_delivery_date',
				label: 'Car Est Delivery',
			});

			// DEALER ID
			vm.filters.push({
				id: 'dealer_id',
				label: 'Dealer ID'
			});

			// ITEM REFERENCE
			vm.filters.push({
				id: 'invoice_number',
				label: 'Invoice #'
			});

			// EXCEPTIONS
			vm.filters.push({
				id: 'exceptions',
				label: 'Exceptions',
				options: [
					{
						id: 'dispatch',
						description: 'Failed to Dispatch'
					},
					{
						id: 'pickup',
						description: 'Failed to Pick up'
					},
					{
						id: 'status',
						description: 'Failed to Status'
					},
					{
						id: 'weather',
						description: 'Weather Alerts'
					},
					{
						id: 'oversized',
						description: 'Oversized Shipments'
					}
				]
			});

		}
	}
})();
