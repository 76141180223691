(function () {
  'use strict';

  var module = angular.module('app.components');

  // /* @ngInject */
  // function controller($http) {
  function controller($http) {

	/*jshint validthis: true */
	var vm = this;
	vm.totals = { pieces: 0, cubicFeet: 0, weight: 0 };
	vm.accessorials;

	// Handle component lifecycle events
	vm.$onInit = function () {
      /**
       * Called on each controller after all the controllers on an element have been constructed
       * and had their bindings initialized (and before the pre & post linking functions for the
       * directives on this element). This is a good place to put initialization code for your
       * controller.
       */
	  //doSomeDefaultStuff();

	};
	var timestamp = new Date(Date.now());
	var minutes = timestamp.getMinutes();
	var minuteString = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
	vm.dateTimeStamp = (timestamp.getMonth() + 1) + '/' + timestamp.getDate() + '/' + timestamp.getFullYear() + ', ' + timestamp.getHours() + ':' + minuteString;
	vm.dateStamp = (timestamp.getMonth() + 1) + '/' + timestamp.getDate() + '/' + timestamp.getFullYear();

	vm.TranslateManualTerms = function (terms) {
	  var result = '';

	  if (terms) {
		result = terms.toUpperCase();
		result = result.split('_').join(' ');
	  }
	  return result;
	}

	this.$onChanges = function (changesObj) {
	  if (!changesObj.entity.isFirstChange()) {
		assembleAccessorials();
	  }
	};

	////////////////////////
	// function doSomeDefaultStuff(){
	//   vm.entity.name = "doSomeDefaultStuff Was Also Here";
	// }

	function assembleAccessorials() {
	  if (!vm.entity.details || !vm.entity.details.request || !vm.entity.details.request.shipment) return;
	  var services = vm.entity.details.request.shipment.origin.additional_services;
	  if (!services) return;

	  $http.get('/api/accessorials/')
		.then(
		  function successCallback(response) {
			var accessorialsToDisplay = [];

			_.each(services, function (service) {
			  var match = response.data.filter(function (accessorial) { return accessorial.code === service.code });
			  if (match.length) {
				service.description = match[0].label;
				accessorialsToDisplay.push(service);
			  }
			});

			vm.accessorials = accessorialsToDisplay;
		  },
		  function errorCallback(response) {
			console.group('======= FAILED GETTING ACCESSORIALS ========');
			console.log(response);
			console.groupEnd();
		  })
		.catch(function caught(response) {
		  console.group('======= ERROR CAUGHT GETTING ACCESSORIALS ========');
		  console.log(response);
		  console.groupEnd();
		});
	}
  }

  module.component('printBOL', {
	bindings: {
	  entity: '<'
	},
	controllerAs: 'vm',
	controller: ['$http', controller], // list of DI items with controller method as the last item
	// controller: ['$http', controller], // list of DI items with controller method as the last item
	templateUrl: 'app/components/printing/printBOL.component.html'
  });

}());
