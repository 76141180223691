(function() {
  'use strict';

  angular
    .module('app.directives')
		.directive('pcOnEnterPress', function() {
			return function(scope, element, attrs) {
					element.bind("keydown keypress", function(event) {
						var keyCode = event.which || event.keyCode;

						// If enter key is pressed
						if (keyCode === 13) {
							scope.$apply(function() {
								// Evaluate the expression
								scope.$eval(attrs.pcOnEnterPress);
							});
							event.preventDefault();
						}
					});
			};
		});
})();
