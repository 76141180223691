(function () {
	'use strict';

	angular
		.module('app.filters')
		.filter('fromNow', fromNow);

	function fromNow() {

		return fromNowFilter;

		function fromNowFilter(input) {
			return moment(input).fromNow();
		}
	}

}());
