(function () {
	'use strict';

	angular
		.module('app.bolEntry')
		.controller('BolEntryController', BolEntryController);

	BolEntryController.$inject = ['$http', '$window', '$q', '$state', '$scope', '$timeout', '$location', '$anchorScroll', '$rootScope', 'logger', 'currentUser', 'bolEntryDataservice', 'dashboardDataservice', 'addressDataservice', 'productsDataservice'];
	/* @ngInject */
	function BolEntryController($http, $window, $q, $state, $scope, $timeout, $location, $anchorScroll, $rootScope, logger, currentUser, bolEntryDataservice, dashboardDataservice, addressDataservice, productsDataservice) {
	  var vm = this;
	  window.kbol = vm;
	  window.kscope = $scope;
		vm.state = ''; // options are: <empty>, confirmation, error, waitingForQuotes, selectQuote, printBOL, printCarrierConfirmation
		vm.mode = 'ltl'; // options are: ltl, manual, quote-resume, bol-edit, bol-clone
		vm.order = {};
		vm.readyInfo = {};
		vm.previousMode = '';
		vm.quoteResults = {};
		vm.manualDetails = {};
		vm.completedOrder = {};
		vm.customer = null;
		vm.errorDetails = '';
		vm.terms = '';
		vm.direction = '';
		vm.systemUser = false;
		vm.quickQuote = false;
		vm.return_service = true;
		vm.lockManual = false;
		vm.is_rlms = false;
		vm.status = '';
		vm.workflowState = 'ltl';
		vm.currentUser = currentUser;

		vm.readyInfo.ready_date = new Date(new Date().setHours(0, 0, 0, 0));
		vm.readyInfo.ready_time = new Date(new Date().setHours(8, 0, 0, 0));
		vm.readyInfo.close_time = new Date(new Date().setHours(17, 0, 0, 0));

		vm.insurance = {};
		vm.materialInformation = {};

		vm.orderItems = { totalPieces: 0, totalWeight: 0, items: [] };

		vm.referenceNumbers = {};
		vm.specialInstructions = '';
		vm.additionalServices = '';

		// elements for interacting with shipping-entity component
		vm.shippers = [];
		vm.organizations = [];
		vm.entryFeedback = [];
		vm.matchingShipAddresses = [];
		vm.matchingDestAddresses = [];
		vm.matchingBillAddresses = [];
		vm.newShipAddress = true;
		vm.newDestAddress = true;
		vm.newBillAddress = false;

		// elements for interacting with items-entity component
		vm.containerPkgOptions = []
		vm.matchingProducts = [];
		vm.productSearchTerm = '';
		vm.newProduct = true;
		vm.toSave = { test: 'test' };

		vm.dateOptions = {
			formatYear: 'yyyy'
		};
		vm.datePickerState = { readyDate: false };

		activate();

		vm.setCustomer = function () {
			if (!!vm.customer) console.log("Customer ID:", vm.customer.id, "External Customer ID:", vm.customer.external_id);
		}

		vm.clearData = function () {
			clearData();
		};
		
		vm.deleteQuote = function () {
			$http.delete('api/orders/' + $state.params.id + '?del=1')
				.then(
					function successCallback(response) {
						$location.path('/');
					},
					function errorCallback(response) {
						logger.info('Quote deletion failed');
					}
				)
				.catch(function caught(response) {
					logger.info('Quote deletion failed');
				});
		}

		vm.CreateManualQuote = function () {
			vm.entryFeedback = [];

			if (inputIsValidForManualOrder()) {
				// save any new updates made to addresses
				updateAddresses();

				// TODO: check non-manual fields too

				var orderJSON = buildQuoteJSON();

				// TODO: the below is copy+pasted from vm.selectQuote for quick test
				// TODO: come back and do this right, dummy

				var param = orderJSON;
				vm.errorDetails = '';

				var url = '/api/l/orders';
				$http.post(url, param)
					.then(
						function successCallback(response) {
							showOrderConfirmation(response.data);
						},
						function errorCallback(response) {
							if (response.data && response.data.errors && response.data.errors.length > 0) {
								vm.errorDetails = response.data.errors[0].message + ' ('
									+ response.data.errors[0].diagnostic + ')';
							}
							else {
								vm.errorDetails = JSON.stringify(response);
							}
							vm.state = 'error';
							console.group('======= FAILED ORDER ========');
							console.log(response);
							console.groupEnd();
						}
					)
					.catch(function caught(response) {
						vm.errorDetails = JSON.stringify(response);
						vm.state = 'error';
						console.group('======= ERROR CAUGHT ON ORDER ========');
						console.log(response);
						console.groupEnd();
					});
			}
		};

		vm.SwitchOrderMode = function (newMode) {
			vm.previousMode = vm.mode;
			vm.mode = vm.lockManual ? 'manual' : newMode;
			vm.entryFeedback = [];

			if (newMode === 'manual') {
				vm.manualDetails = {};

				vm.shippers = [];
				$http.get('/api/shippers').then(function successCallback(response) {
					vm.manualDetails.shippers = response.data;
				}, function successCallback(response) {
					console.log(response);
				});
			}
			if (vm.terms === 'PREPAID' || vm.terms === 'THIRD_PARTY') {
				if (vm.order.shipment) {
					vm.order.shipment.billing = returnDefaultBillingInfo();
				}
				else {
					vm.order = {
						shipment: {
							billing: returnDefaultBillingInfo(),
							origin: {},
							destination: {}
						}
					};
				}
			}

		}

		vm.getQuotes = function () {
		  if (inputIsValidForQuotes()) {
				vm.dispatch = true;
				// save any new updates made to addresses
				updateAddresses();
				var quoteJSON = buildQuoteJSON();
				vm.state = 'waitingForQuotes';
				vm.errorDetails = '';
				vm.quoteResults = {};
				if (vm.mode === 'manual') {
					vm.mode = 'ltl';
					vm.previousMode = '';
					vm.manualDetails = {};
				}

				var param = quoteJSON;
				var url = '/api/quotes';

				$http.post(url, param)
					.then(
						function successCallback(response) {
							if (vm.state.length > 0) {
								vm.state = 'selectQuote';
								vm.quoteResults = response;
								// filter out errors, unduplicate, resort by rate
								/*
								var errorFree = vm.quoteResults.data.rates.filter(function (f) {
									if (!!f.success) return f.success;
									if (!!f.service_code) return (f.service_code === "ERR");
								});*/
							  var errorFree = vm.quoteResults.data.rates;

								var sorted = errorFree.sort(function(a, b) { return a.details.chksum - b.details.chksum });
								vm.quoteResults.data.rates = sorted;
								$timeout(function () {
									$timeout(function () {
										$location.hash('quotesDiv');
										$anchorScroll(1550);
									});
								});
							}
						},
						function errorCallback(response) {
							if (vm.state.length > 0) {
								vm.state = 'error';
								console.group('======= FAILED QUOTE ========');  
								console.log(response);
								console.groupEnd();
							}
						}
					)
					.catch(function caught(response) {
						if (vm.state.length > 0) {
							vm.state = 'error';
							console.group('======= ERROR CAUGHT ON QUOTE ========');
							console.log(response);
							console.groupEnd();
						}
					});
			}
		}

		function unDupQuotes(quotes) {
			// sort in carrier, quote # order
		  var ud1 = quotes;
			ud1.sort(function (a, b) {
				var aVal = a.account_code + a.carrier_code + a.quote_number + a.details.chksum;
				var bVal = b.account_code + b.carrier_code + b.quote_number + b.details.chksum;
				if (aVal > bVal) return 1
				else if (aVal === bVal) return 0;
				else return -1
			});
			// console.log(ud1);
			// remove (now) adjacent dups
			var ud2 = [];
		  ud2.push(ud1[ud1.length - 1]); // get the last one, doesn't matter what sequence, they'll be resorted by rate later
		  var i;
		  var aVal2, bVal2;
			for (i = 0; i < ud1.length - 2; i++) {
				aVal2 = ud1[i].account_code + ud1[i].carrier_code + ud1[i].quote_number + ud1[i].details.chksum;
				bVal2 = ud1[i + 1].account_code + ud1[i + 1].carrier_code + ud1[i + 1].quote_number + ud1[i + 1].details.chksum;
				// console.log('aVal', aVal, 'bVal', bVal)
				if (aVal2 !== bVal2) ud2.push(ud1[i]); // else console.log('rejected');
		  }
			// console.log('ud2', ud2);
			return ud2;
		}

	  vm.loadQuickQuoteData = function () {
			vm.quickQuote = true;
			loadQuickQuote();
	  };

	  vm.convertQuickQuote = function () {
			vm.quickQuote = false;
			vm.state = '';
			vm.quoteResults = {};
			vm.order.shipment.origin.name = '';
			vm.order.shipment.origin.address_line_1 = '';
			vm.order.shipment.origin.contact_name = '';
			vm.order.shipment.origin.contact_phone = '';
			vm.order.shipment.origin.contacts = { "contacts": [{ "name": "", "phone": "", "extension": "", "email": "", "fax": "" }] };
			vm.order.shipment.destination.name = '';
			vm.order.shipment.destination.address_line_1 = '';
			vm.order.shipment.destination.contact_name = '';
			vm.order.shipment.destination.contact_phone = '';
			vm.order.shipment.destination.contacts = { "contacts": [{ "name": "", "phone": "", "extension": "", "email": "", "fax": "" }] };
			setTimeout(function () { $('#shippingName').focus(); }, 200);
		};

		// vm.loadDemoData = function () {
		// 	loadDemoData();
		// }

		//	can't just return results bc of sharing w shipping-entity component
		//  this 'switch' approach is clunky, but passing in the matchingXxxxAddresses fails  
		//		to sync w the shipping-entity component

		vm.matchAddress = function (address, type) {
			const dbAddress = bolToDbAddress(address);
			switch (type) {
				case 'shipping':
					vm.matchingShipAddresses = [];
					break;
				case 'destination':
					vm.matchingDestAddresses = [];
					break;
				case 'billto':
					vm.matchingBillAddresses = [];
					break;
			}
			if ((address.name && address.name.length >= 3) || 
				(address.address_line_1 && address.address_line_1.length >= 3) ||
				(address.address_line_2 && address.address_line_2.length >= 3) ||
				(address.city && address.city.length >= 3) ||
				(address.postal_code && address.postal_code.length >= 3)
			) {
				const search = { address: dbAddress, }
				return addressDataservice.match(search)
					.then(
						function (data) {
							switch (type) {
								case 'shipping':
									vm.matchingShipAddresses = data;
									break;
								case 'destination':
									vm.matchingDestAddresses = data;
									break;
								case 'billto':
									vm.matchingBillAddresses = data;
									break;
							}
						}, function (data) { } // no need to show error if no matching addresses
					);
			}

			return;
		}

		vm.verifyExactAddress = function (address, newFlag) {
			const dbAddress = bolToDbAddress(address);
			const search = { address: dbAddress, }
			// if an exact match is found, don't save this address, avoids duplicates
			return addressDataservice.exactMatch(search)
				.then(function (d) { newFlag = false; }, function (d) { });
			// no error if no matching addresses
		}


		vm.saveShipAddress = function () {
			if (vm.newShipAddress) {
				vm.verifyExactAddress(vm.order.shipment.origin, vm.newShipAddress).then(
					function (d) {
						if (vm.newShipAddress) {
							const dbAddress = bolToDbAddress(vm.order.shipment.origin);
							dbAddress.is_shipping = 1;
							return addressDataservice.createAddress(dbAddress).then(
								function (d) {
									vm.newShipAddress = false;
								},
								function (d) { });
						}
					}, function (d) { });  // not found, no action required
			} else {  // found, update needed?
				updateShipAddress();
			}
		}

		function updateShipAddress() {
			var ua = {};
			if (vm.order.shipment.origin.is_shipping !== 1) ua.is_shipping = 1;
			ua.contacts = vm.order.shipment.origin.contacts;
			ua.id = vm.order.shipment.origin.address_id;
			return addressDataservice.updateAddress(ua).then(function (d) { }, function (d) { });
		}

		vm.saveDestAddress = function () {
			if (vm.newDestAddress) {
				vm.verifyExactAddress(vm.order.shipment.destination, vm.newDestAddress).then(
					function (unused) {
						if (vm.newDestAddress) {
							const dbAddress = bolToDbAddress(vm.order.shipment.destination);
							dbAddress.is_destination = 1;
							return addressDataservice.createAddress(dbAddress).then(function (unused) { vm.newDestAddress = false; }, function (d) { });
						}
					}, function (d) { });  // not found, no action required
			} else {  // found, update needed?
				updateDestAddress();
			}
		}
		function updateDestAddress() {
			var ua = {};
			if (vm.order.shipment.destination.is_destination !== 1) ua.is_destination = 1;
			ua.contacts = vm.order.shipment.destination.contacts;
			ua.id = vm.order.shipment.destination.address_id;
			return addressDataservice.updateAddress(ua).then(function (d) { }, function (d) { });
		}
		vm.saveBillAddress = function () {
			if (vm.newBillAddress) {
				vm.verifyExactAddress(vm.order.shipment.billing, vm.newBillAddress).then(
					function (unused) {
						if (vm.newBillAddress) {
							const dbAddress = bolToDbAddress(vm.order.shipment.billing);
							dbAddress.is_billto = 1;
							return addressDataservice.createAddress(dbAddress).then(function (unused) { vm.newBillAddress = false; }, function (unused) { });
						}
					}, function (d) { });  // not found, no action required
			} else {  // found, update needed?
				// hack:  exclude default billing address
				if (vm.order.shipment.billing.name.trim() !== 'SRC Logistics, Inc.') updateBillAddress();
			}
		}
		function updateBillAddress() {
			var ua = {};
			if (vm.order.shipment.billing.is_billto !== 1) ua.is_billto = 1;
			ua.contacts = vm.order.shipment.billing.contacts;
			ua.id = vm.order.shipment.billing.address_id;
			return addressDataservice.updateAddress(ua).then(function (d) { }, function (d) { });
		}


		function updateAddresses() {
			updateShipAddress();
			updateDestAddress();
			updateBillAddress();
		}

		vm.saveProduct = function () {
			if (vm.newProduct) {
				vm.toSave.organization_id = (!!vm.customer) ? vm.customer.id : 0;
				const dbProduct = bolToDbProduct(vm.toSave);
				vm.verifyExactProduct(dbProduct, vm.newProduct).then(
					function (unused) {
						if (vm.newProduct) {
							return productsDataservice.createProduct(dbProduct)
								.then(function (unused) { vm.newProduct = false; }, function (unused) { });
						}
					}, function (d) { });  // not found, no action required
			} else {  // found, update needed?
				updateProduct();
			}
		}

		vm.verifyExactProduct = function (product, newFlag) {
			const search = { product: product, }
			// if an exact match is found, don't save this address, avoids duplicates
			return productsDataservice.exactMatch(search)
				.then(function (d) { newFlag = false; }, function (d) { });
			// no error if no matching product
		}

		vm.lookupCityDetails = function (shipEntity) {
			if (shipEntity.postal_code) {
				var zip = shipEntity.postal_code.replace("-", "");
				if (zip.length === 5 && /^\d+$/.test(zip)) {
					$http.get('/api/utility/city-lookup/' + zip).then(function successCallback(response) {
						if (response.data) {
							shipEntity.city = response.data.city;
							shipEntity.state_code = response.data.state;
						}
					});
				}
			}

			if (shipEntity.state_code && shipEntity.state_code.length > 0)
				forceManualForState(shipEntity.state_code);
		}

		//	can't just return results bc of sharing w items-entity component,
		// 		have to assign to the shared variable matchingProducts
		vm.matchProduct = function () {
			vm.newProduct = true;  // reset this flag, set to false again if they pick one
			const product = {
				organization_id: (!!vm.customer) ? vm.customer.id : 0,
				item_reference: vm.productSearchTerm.itemReference,
				description: vm.productSearchTerm.description
			};
			vm.matchingProducts = [];
			// exit if insufficient search terms
			if (((!product.description) || (product.description.length < 4))
				&& ((!product.item_reference) || (product.item_reference.length < 3))) return;
			const search = { product: product }
			return productsDataservice.match(search)
				.then(function (data) {
					vm.matchingProducts = data;
				}, function (data) { }); // not found is not an error
		}

		vm.updateShipmentRequest = function () {
			var orderJSON = buildQuoteJSON();

			vm.errorDetails = '';

			var url = '/api/orders/' + orderJSON.mq_id + '/update';

			$http.put(url, orderJSON)
				.then(
					function successCallback(response) {
						$location.path('/order/' + orderJSON.mq_id).replace();
						//showOrderConfirmation(response.data);
					},
					function errorCallback(response) {
						if (vm.state.length > 0) {
							if (response.data && response.data.errors && response.data.errors.length > 0) {
								vm.errorDetails = response.data.errors[0].message + ' ('
									+ response.data.errors[0].diagnostic + ')';
							}
							else {
								vm.errorDetails = JSON.stringify(response);
							}
							vm.state = 'error';
							console.group('======= FAILED UPDATE ========');
							console.log(response);
							console.groupEnd();
						}
					}
				)
				.catch(function caught(response) {
					if (vm.state.length > 0) {
						vm.state = 'error';
						console.group('======= ERROR CAUGHT ON ORDER UPDATE ========');
						console.log(response);
						console.groupEnd();
					}
				});
		}

		vm.saveQuote = function (quoteIndex) {
			vm.selectQuote(quoteIndex, true, false);
			vm.submitShipment();
		}

		vm.selectQuote = function (quoteIndex, save, dispatch) {
			vm.queuedShipment = buildQuoteJSON();
			vm.queuedShipment.save_quote = save;
			vm.queuedShipment.allow_auto_dispatch = dispatch;
			vm.queuedShipment.quote_id = vm.quoteResults.data.quote_id;

			// goodQuotes is a filter down to successful quotes. We are using the index of the grid row
			// but the index is of the good rates

			// STAN TODO:  expand this to filter error or duplicate quotes
			//	why error quotes already getting thru?  who sets .success
			var goodQuotes = vm.quoteResults.data.rates.filter(function (q) {
				return (q.success === true);
			});

			vm.queuedShipment.quote = goodQuotes[quoteIndex];
		}

		vm.submitShipment = function () {
			vm.errorDetails = '';

			var url = '/api/l/orders';

			$http.post(url, vm.queuedShipment)
				.then(
					function successCallback(response) {
						showOrderConfirmation(response.data);
					},
					function errorCallback(response) {
						if (vm.state.length > 0) {
							if (response.data && response.data.errors && response.data.errors.length > 0) {
								vm.errorDetails = response.data.errors[0].message + ' ('
									+ response.data.errors[0].diagnostic + ')';
							}
							else {
								vm.errorDetails = JSON.stringify(response);
							}
							vm.state = 'error';
							console.group('======= FAILED ORDER ========');
							console.log(response);
							console.groupEnd();
						}
					}
				)
				.catch(function caught(response) {
					if (vm.state.length > 0) {
						vm.state = 'error';
						console.group('======= ERROR CAUGHT ON ORDER ========');
						console.log(response);
						console.groupEnd();
					}
				});
		}

		vm.TermsHaveChanged = function () {
			if (vm.terms !== 'PREPAID' && vm.terms !== 'THIRD_PARTY') {
				vm.order.shipment.billing = {};
			}
			else {
				vm.order.shipment.billing = returnDefaultBillingInfo();
			}
		}

		//////
		function activate() {
			clearData();

			vm.mode = 'ltl';
			vm.systemUser = currentUser.isSystemUser;

			setDefaultTerms();
			setDefaultDirection();

			var promises = [];
			vm.workflowState = $state.current.name;
			switch ($state.current.name) {
				case 'bolEntry':
					promises.push(loadPkgContainers());  // TODO needed for each section below, too?
					promises.push(loadOrganizations());
					break;
			  case 'bolEntry-quote':
					promises.push(loadPkgContainers());
					promises.push(loadOrganizations()); // needed so validation check for customer entry will work
					promises.push(getOrderDetails($state.params.id));
					break;
			  case 'bolEntry-edit':
					promises.push(loadPkgContainers());
					promises.push(loadOrganizations()); // needed so validation check for customer entry will work
					promises.push(getOrderDetails($state.params.id));
					break;
			  case 'bolEntry-clone':
				promises.push(loadPkgContainers());
					promises.push(loadOrganizations()); // needed so validation check for customer entry will work
					promises.push(getOrderDetails($state.params.id));
					break;
			  case 'bolEntry-quote-print':
					promises.push(getOrderDetailsForQuote($state.params.id));
					break;
			}
			return $q.all(promises).then(function () {
			});
		}

		function forceManualForState(state_code) {
			switch (state_code.toUpperCase()) {
				case "AK":
				case "HI":
				case "PR":
				case "NT":
				case "YT":
					vm.SwitchOrderMode('manual');
					vm.lockManual = true;
					break;
				default:
					vm.lockManual = false;
					break;
			}
		}

		function getOrderDetailsForQuote(orderId, useRlmsRoute) {
			return dashboardDataservice.getOrderBySwNumber(orderId, useRlmsRoute).then(
				function (data) { // success callback
					vm.order = data;
				},
				function (response) { // error callback
					vm.order.error = response.data.description; // gets displayed on the UI verbatim
					if (response.data.name !== 'OrderNotFound' && response.data.name !== 'OrderDetailsNotFound') {
						console.log(error);
					}
				});
		}


		function getOrderDetails(orderId) {
			return bolEntryDataservice.getOrderByOrderId(orderId)
				.then(
					function (response) {
						if (response) {
							// console.log(response);
							vm.status = response.data.status.toUpperCase();
							switch (response.data.status.toUpperCase()) {
								case 'MQ':
									vm.is_rlms = true;
									vm.SwitchOrderMode('quote-resume');
								case 'QT':
									vm.SwitchOrderMode('quote-resume');
									break;
								default:
									switch ($state.current.name) {
										case 'bolEntry-edit':
											vm.SwitchOrderMode('bol-edit');
											break;
										case 'bolEntry-clone':
											vm.SwitchOrderMode('bol-clone');
											break;
										default:
											$location.path('/order/' + orderId).replace();
											//vm.SwitchOrderMode('manual');
											break;
									}
									break;
							}
							loadMQData(response.data.details.request, orderId, response.data.organization_id);
							if (vm.mode !== 'bol-clone') {
								if (response.data.details.request.shipment.identifiers)
									formatReferenceNumbers(response.data.details.request.shipment.identifiers);
							}
							if (response.data.details.request.shipment.origin.additional_services)
								formatAccessorials(response.data.details.request.shipment.origin.additional_services);
						}
					},
					function (response) { // error callback
						if (response.data.name !== 'OrderNotFound' && response.data.name !== 'OrderDetailsNotFound') {
							console.log(response);
							vm.errorDetails = response.data.description;
						}
					}
				);
		}

		function formatReferenceNumbers(identifiers) {
			if (identifiers.length > 0) {
				for (var i = 0; i < identifiers.length; i++) {
					var item = identifiers[i];
					switch (item.type.toUpperCase()) {
						case "PURCHASE_NUMBER":
							vm.referenceNumbers.poNumber = item.value;
							break;
						case "SO_NUMBER":
							vm.referenceNumbers.soNumber = item.value;
							break;
						case "RETURN_ID":
							vm.referenceNumbers.returnNumber = item.value;
							break;
						case "JOB_NUMBER":
							vm.referenceNumbers.jobNumber = item.value;
							break;
						case "INVOICE_NUMBER":
							vm.referenceNumbers.invoiceNumber = item.value;
							break;
						case "OEM":
							vm.referenceNumbers.oem = item.value;
							break;
						case "DEALER_ID":
							vm.referenceNumbers.dealerID = item.value;
							break;
						default:
							if (item.type.substring(0, 5) === 'OTHER') {
								vm.referenceNumbers.otherReference = {};
								vm.referenceNumbers.otherReference.name = item.type.substring(6, item.type.length);
								vm.referenceNumbers.otherReference.value = item.value;
							}
							break;
					}
				}
			}
		}

		function formatAccessorials(accessorials) {
			if (accessorials.length > 0) {
				for (var i = 0; i < accessorials.length; i++) {
					var item = accessorials[i];
					switch (item.code.toUpperCase()) {
						case "LGDEL":
							vm.additionalServices.deliveryLiftgate = true;
							break;
						case "NOTIFY":
							vm.additionalServices.deliveryNotification = true;
							break;
						case "ELS":
							vm.additionalServices.extremeLength = true;
							break;
						case "HAZM":
							vm.additionalServices.hazmat = true;
							break;
						case "INDEL":
							vm.additionalServices.insideDelivery = true;
							break;
						case "INPU":
							vm.additionalServices.insidePickup = true;
							break;
						case "LTDDEL":
							vm.additionalServices.limitedAccessDropoff = true;
							break;
						case "LTDPU":
							vm.additionalServices.limitedAccessPickup = true;
							break;
						case "LGPU":
							vm.additionalServices.pickupLiftgate = true;
							break;
						case "APPTPU":
							vm.additionalServices.pickupNotification = true;
							break;
						case "RESDEL":
							vm.additionalServices.residentialDelivery = true;
							break;
						case "RESPU":
							vm.additionalServices.residentialPickup = true;
							break;
						default:
							break;
					}
				}
			}
		}

		function clearData() {
			vm.state = '';
			vm.quickQuote = false;
			vm.order = { shipment: { origin: {}, destination: {}, billing: {} } };
			vm.orderItems = { totalPieces: 0, totalWeight: 0, items: [] };
			vm.specialInstructions = '';
			vm.referenceNumbers = {};
			vm.insurance = {};
			vm.materialInformation = {};
			vm.quoteResults = {};
			vm.entryFeedback = [];
			vm.completedOrder = {};
			vm.return_service = true;

			vm.matchingShipAddresses = [];
			vm.matchingDestAddresses = [];
			vm.matchingBillAddresses = [];
			vm.newShipAddress = true;
			vm.newDestAddress = true;
			vm.newBillAddress = false;
			vm.matchingProducts = [];

			if (vm.systemUser)
				vm.customer = null;

			vm.readyInfo.ready_date = new Date(new Date().setHours(0, 0, 0, 0));
			vm.readyInfo.ready_time = new Date(new Date().setHours(8, 0, 0, 0));
			vm.readyInfo.close_time = new Date(new Date().setHours(17, 0, 0, 0));

			setDefaultTerms();
			setDefaultDirection();
			if (vm.terms === 'PREPAID' || vm.terms === 'THIRD_PARTY') {
				vm.order = {
					shipment: {
						billing: returnDefaultBillingInfo(),
						origin: {},
						destination: {}
					}
				}
			}

			vm.additionalServices = {
				customsClearance: false,
				deliveryLiftgate: false,
				deliveryNotification: false,
				extremeLength: false,
				hazmat: false,
				insideDelivery: false,
				insidePickup: false,
				limitedAccessDropoff: false,
				limitedAccessPickup: false,
				pickupLiftgate: false,
				pickupNotification: false,
				residentialDelivery: false,
				residentialPickup: false
			};
		}

		function formatTimeForP44(time) {
			var hours = time.getHours().toString();
			var minutes = time.getMinutes().toString();

			if (hours.length === 1) {
				hours = '0' + hours;
			}

			if (minutes.length === 1) {
				minutes = minutes + '0';
			}

			return hours + ':' + minutes;
		}

		function formatDateForP44(date) {
			var month = (date.getMonth() + 1).toString();
			var day = date.getDate().toString();
			var year = date.getFullYear();

			if (month.length === 1) {
				month = '0' + month;
			}

			if (day.length === 1) {
				day = '0' + day;
			}

			return year + '-' + month + '-' + day;
		}

		function loadOrganizations() {
			if (currentUser.isSystemUser) {
				$http.get('/api/organizations').then(function successCallback(response) {
					vm.organizations = response.data;
				}, function failureCallback(response) {
					console.log('RESPONSE');
					console.log(response);
				});
			} else {
				$http.get('/api/organizations/' + currentUser.organizationId).then(function successCallback(response) {
					vm.customer = response.data;
				}, function failureCallback(response) {
					console.log('RESPONSE');
					console.log(response);
				});
			}
		}

		function loadPkgContainers() {
			$http.get('/api/containers/').then(function successCallback(response) {
				vm.containerPkgOptions = response.data;
			}, function failureCallback(response) {
				console.log('RESPONSE');
				console.log(response);
			});
		}

		function inputIsValidForCompletingQuotes() {
			return true;
		}

		function inputIsValidForManualOrder() {

			if (!vm.manualDetails) {
				vm.entryFeedback.push('Manual Order fields need to be entered');
				return false;
			}

			if (!vm.terms) {
				vm.entryFeedback.push('Freight Terms are missing');
			}

			if (!vm.manualDetails.quote) {
				vm.entryFeedback.push('Manual Quote Payables are missing');
			}
			else {
				if (!vm.manualDetails.quote.carrier_code && !vm.manualDetails.quote.carrier_name) {
					vm.entryFeedback.push('Carrier name is missing from manual quote');
				}
				if (!vm.manualDetails.quote.quote_number) {
					vm.entryFeedback.push('Quote Reference # is missing');
				}
				if (!vm.manualDetails.quote.details) {
					vm.entryFeedback.push('Manual Quote Rates are missing');
				}
				else {
					if (!vm.manualDetails.quote.details.chksum) {
						vm.entryFeedback.push('Manual Quote Buy Rate is missing');
					}
					if (!vm.manualDetails.quote.details.total) {
						vm.entryFeedback.push('Manual Quote Sell Rate is missing');
					}
				}
			}

			return vm.entryFeedback.length === 0;
		}

		function inputIsValidForQuotes() {
			// console.log('inputIsValidForQuotes')
			vm.entryFeedback = [];
			var safeToCrawlOrigin = false;
			var safeToCrawlDestination = false;

			if (vm.systemUser && !vm.customer) {
				vm.entryFeedback.push('A customer must be selected');
			}


			// make sure the basic stuff is available
			if (!vm.order) {
				vm.entryFeedback.push('Too little information to quote');
				return false;
			}
			else if (!vm.order.shipment) {
				vm.entryFeedback.push('Too little information to quote');
				return false;
			}
			else if (!vm.order.shipment.origin || !vm.order.shipment.destination) {
				vm.entryFeedback.push('Too little information to quote');
				return false;
			}

			// address validations (belt and suspenders:  these edits are in shippingEntity too)
			if (isValidAddress(vm.order.shipment.origin, 'Shipper', true)) safeToCrawlOrigin = true;
			if (isValidAddress(vm.order.shipment.destination, 'Destination', true)) safeToCrawlDestination = true;
			isValidAddress(vm.order.shipment.billing, 'Bill To'), true;

			// we also need pickup date and start/end times
			if (!vm.readyInfo.ready_date) {
				vm.entryFeedback.push('A Ready Date at the top of the order is required');
			}
 
			if (!vm.readyInfo.ready_time) {
				vm.entryFeedback.push('A Ready Time at the top of the order is required');
			}

			if (!vm.readyInfo.close_time) {
				vm.entryFeedback.push('A Close Time at the top of the order is required');
			}

			// gotta have at least one item as well
			// console.log(vm.orderItems.items)
			if (!vm.orderItems.items) {
				vm.entryFeedback.push('At least one item is required');
			}
			else if (vm.orderItems.items.length === 0) {
				vm.entryFeedback.push('At least one item is required');
			}

			return (vm.entryFeedback.length === 0);
		}

		function isValidAddress(address, type, email) {
			// console.log('isValidAddress', type)
			// console.log('isValidAddress', address)
			var validation = [];
			if (!address.isInternational) {
				if ((!address.postal_code) || (address.postal_code.length < 2)) {
					validation.push('A zip code is required for the ' +type +' address');
				}
				if ((!address.state_code) || (address.state_code.length < 2)) {
				  validation.push('A state or province is required for the ' +type +' address');
				}
			}
			if ((!address.address_line_1) || (address.address_line_1.length < 2)) {
			  validation.push('An address line 1 is required for the ' + type + ' address');
			}
			if ((!address.city) || (address.city.length < 3)) {
			  validation.push('A city is required for the ' + type + ' address');
			}

			if ((!address.contact_name) || (address.contact_name.length < 2)) {
			  validation.push('A contact name is required for the ' + type + ' address');
			}
			if (email) {
				if (!!address.contact_email) {
					// check format
					if (!isValidEmail(address.contact_email)) {
					  validation.push('Contact email format is not valid for the ' + type + ' address');
					}
				}
			}
			if ((!address.contact_phone) || (address.contact_phone.length < 2)) {
			  validation.push('A contact phone is required for the ' + type + ' address');
			}
			// console.log(validation)
			if (validation.length > 0) vm.entryFeedback.push(type + ' address is incomplete');
			return validation.length === 0;
		}


		function isValidEmail(email) {
			var valid = true;
			valid = (!!email);
			valid = email.indexOf('@');
			valid = email.indexOf('.');
			valid = email.indexOf('.') < email.length;
		  valid = email.indexOf('@') < email.lastIndexOf('.');
			return valid;
		}

		vm.setDispatchFlag = function () {
			// console.log('checking form');
			// STAN:  modified to use validateAddress()
			// and set vm.dispatch (which controls button availability in the form)
			vm.dispatch = inputIsValidForQuotes();
			// ((isValidAddress(vm.order.shipment.origin, 'Shipper') &&	isValidAddress(vm.order.shipment.destination, 'Destination')));

			// original code
			// inputIsValidForDispatch();
		}


		function returnDefaultBillingInfo() {
			return {
				name: "SRC Logistics, Inc.",
				address_line_1: "P.O. Box 9147",
				address_line_2: "",
				city: "Springfield",
				state_code: "MO",
				postal_code: "65801",
				country_code: "US",
				contact_name: "Freight",
				contact_phone: "417-479-9565",
				contact_extension: "",
				contact_fax: "",
				contact_email: "freight@srclogisticsinc.com",
				contacts: { "contacts": [{ "name": "Freight", "phone": "4174799565", "extension": "", "email": "freight@srclogisticsinc.com", "fax": "" }] },
			}
		}

		function setDefaultTerms() {
			vm.terms = 'PREPAID';
		}

		function setDefaultDirection() {
			vm.direction = 'THIRD_PARTY';
		}

		function showOrderConfirmation(orderData) {
			var id = orderData.request.sw_number.replace("PW", "");
			window.scrollTo(0, 0);

			switch (orderData.status) {
				case 'MQ':
					vm.completedOrder = {
						success: false,
						message: orderData.message,
						swNumber: id,
						quoteNumber: '',
						details: orderData
					}
					vm.state = 'confirmation';
					break;
				case 'QT':
					vm.completedOrder = {
						success: false,
						message: orderData.message,
						swNumber: orderData.request.sw_number,
						quoteNumber: id,
						details: orderData
					}
					vm.state = 'confirmation';
					break;
				default:
					$location.path('/order/' + id).replace();
					//getOrderDetails(id);
					break;
			}
		}

		function loadQuickQuote() {
		  clearData();
		  vm.quickQuote = true;
			vm.order.shipment = {
				origin: {
					name: 'SRCL',
					address_line_1: '123 Any St.',
					address_line_2: ' ',
					city: '',
					state_code: '',
					postal_code: '',
					contact_name: 'SRCL',
					contact_phone: '(417) 860-4946',
					// contact_fax: '(417) 851-5815',
					contacts: { "contacts": [{ "name": "Freight", "phone": "4178604946", "extension": "", "email": "freight@srclogisticsinc.com", "fax": "" }] },
				},
				destination: {
					name: 'SRCL',
					address_line_1: '123 Any St.',
					address_line_2: ' ',
					city: '',
					state_code: '',
					postal_code: '',
					contact_name: 'SRCL',
					contact_phone: '(417) 860-4946',
					// contact_fax: '(417) 851-5815',
					contacts: { "contacts": [{ "name": "Freight", "phone": "4178604946", "extension": "", "email": "freight@srclogisticsinc.com", "fax": "" }] },
				},
				billing: returnDefaultBillingInfo()
			};

			vm.readyInfo.ready_date = new Date(new Date().setHours(0, 0, 0, 0));
			vm.readyInfo.ready_time = new Date(new Date().setHours(8, 0, 0, 0));
			vm.readyInfo.close_time = new Date(new Date().setHours(17, 0, 0, 0));

			/* //removing test item per transpo request
				var testItem = {
				pieces: 1,
				container: 'Box',
				description: 'Used Tractor Parts',
				itemReference: '',
				dimensions: {
					lengthInInches: 9,
					widthInInches: 9,
					heightInInches: 9
				},
				CL: 85,
				NMFC: '0', //'120790-1',
				cubicFeet: 24,
				weight: 50
			}
			vm.orderItems.items.push(testItem);
			vm.orderItems.totalPieces = 1;
			vm.orderItems.totalWeight = 515;
						*/
		}


		function loadMQData(request, orderId, org_id) {
			clearData();
			vm.order = request;

			if (vm.mode === 'quote-resume' || vm.mode === 'bol-edit')
				vm.order.mq_id = orderId;
			else
				vm.order.mq_id = '';

			$http.get('/api/organizations/' + org_id).then(function successCallback(response) {
				vm.customer = response.data;
			}, function failureCallback(response) {
				console.log('RESPONSE');
				console.log(response);
			});

			// vm.additionalServixes.insidePickup = true;
			if (vm.order.status === 'MQ' || vm.order.status === 'QT') {
				vm.readyInfo.ready_date = new Date(new Date().setHours(0, 0, 0, 0));
				vm.readyInfo.ready_time = new Date(new Date().setHours(8, 0, 0, 0));
			} else {
				var ready_date = new Date(vm.order.shipment.origin.ready_date);
				var userTimezoneOffset = ready_date.getTimezoneOffset() * 60000;
				var utc_ready_date = new Date(ready_date.getTime() + userTimezoneOffset);
				vm.readyInfo.ready_date = utc_ready_date;

				var readyHours = 17;
				var readyMinutes = 0;
				var readyTimeHHMM = _.split(vm.order.shipment.origin.ready_time, ':', 2);
				if (readyTimeHHMM.length === 2) {
					readyHours = +readyTimeHHMM[0] || 17;
					readyMinutes = +readyTimeHHMM[1] || 0;
				}
				vm.readyInfo.ready_time = new Date(new Date().setHours(readyHours, readyMinutes, 0, 0));
			}

			var closeHours = 17;
			var closeMinutes = 0;
			var closeTimeHHMM = _.split(vm.order.shipment.origin.close_time, ':', 2);
			if (closeTimeHHMM.length === 2) {
				closeHours = +closeTimeHHMM[0] || 17;
				closeMinutes = +closeTimeHHMM[1] || 0;
			}
			vm.readyInfo.close_time = new Date(new Date().setHours(closeHours, closeMinutes, 0, 0));

			var totalWeight = 0;
			var totalPieces = 0;

			for (var i = 0; i < vm.order.shipment.line_items.length; i++) {
				totalWeight += +vm.order.shipment.line_items[i].weight || 0;
				totalPieces += +vm.order.shipment.line_items[i].piece_count || 0;

				var item = vm.order.shipment.line_items[i];
				var testItem = {
					pieces: +item.piece_count,
					container: item.package_type === 'PLT' ? 'Pallet' : item.package_type,
					description: item.product_description,
					itemReference: '',
					dimensions: {
						lengthInInches: item.length ? item.length : 0,
						widthInInches: item.width ? item.width : 0,
						heightInInches: item.height ? item.height : 0
					},
					CL: +item.class,
					NMFC: item.nmfc ? item.nmfc : '',
					cubicFeet: 0,
					weight: item.weight
				};

				vm.orderItems.items.push(testItem);
			}

			vm.orderItems.totalPieces = totalPieces;
			vm.orderItems.totalWeight = totalWeight;

			vm.order.shipment.origin.country_code = (provinceIsCanadian(vm.order.shipment.origin.state_code)) ? "CA" : "US";
			vm.order.shipment.destination.country_code = (provinceIsCanadian(vm.order.shipment.destination.state_code)) ? "CA" : "US";

			forceManualForState(vm.order.shipment.origin.state_code);
		}

		function getContainerCode(container) {
			var result = "";
			switch (container) {
				case "Pallet":
					result = "PLT";
					break;
				case "Bucket":
				case "Crate":
				case "Drum":
				case "Carton":
				case "Box":
				case "Tube":
				case "Roll":
				case "Skid":
				case "Pail":
					result = container.toUpperCase();
					break;
				default:
					console.log('Unknown container type: %s', container);
					result = container.toUpperCase();
					break;
			}

			return result;
		}

		function provinceIsCanadian(provinceCode) {
			switch (provinceCode) {
				case "AB":
				case "BC":
				case "MB":
				case "NB":
				case "NL":
				case "NT":
				case "NU":
				case "ON":
				case "PE":
				case "QC":
				case "SK":
				case "YT":
				case "NS":
					return true;
				default:
					return false;
			}
		}

		function bolToDbAddress(address) {
			return {
				id: address.address_id,
				organization_id: (!!vm.customer) ? vm.customer.id : 0,  // TODO fix this to SRC preference
				name: address.name,
				address_1: address.address_line_1,
				address_2: address.address_line_2,
				city: address.city,
				state_code: address.state_code,
				postal_code: address.postal_code,
				country: (!!address.country_code) ? address.country_code : 'US ',
				contacts: address.contacts,
				is_shipping: address.is_shipping,
				is_destination: address.is_destination,
				is_billto: address.is_billto,
			};
		}

		function bolToDbProduct(product) {
			return {
				id: product.id,
				organization_id: (!!vm.customer) ? vm.customer.id : 0,
				item_reference: product.itemReference,
				description: product.description,
				package_type: product.container,
				NMFC: product.nmfc,
				class: product.CL,
				length: product.dimensions.lengthInInches,
				width: product.dimensions.widthInInches,
				height: product.dimensions.heightInInches,
				weight: product.weight,
			};
		}

		//TODO: move to a Service
		function buildQuoteJSON() {
			var readyDate = formatDateForP44(vm.readyInfo.ready_date);
			var readyTime = formatTimeForP44(vm.readyInfo.ready_time);
			var closeTime = formatTimeForP44(vm.readyInfo.close_time);

			var extraServices = [];
			if (vm.additionalServices.customsClearance) {
				extraServices.push({ "code": "CUSINBOND" });
			}
			if (vm.additionalServices.deliveryLiftgate) {
				extraServices.push({ "code": "LGDEL" });
			}
			if (vm.additionalServices.deliveryNotification) {
				extraServices.push({ "code": "NOTIFY" });
			}
			if (vm.additionalServices.extremeLength) {
				extraServices.push({ "code": "ELS" });
			}
			if (vm.additionalServices.hazmat) {
				extraServices.push({ "code": "HAZM" });
			}
			if (vm.additionalServices.insideDelivery) {
				extraServices.push({ "code": "INDEL" });
			}
			if (vm.additionalServices.insidePickup) {
				extraServices.push({ "code": "INPU" });
			}
			if (vm.additionalServices.limitedAccessDropoff) {
				extraServices.push({ "code": "LTDDEL" });
			}
			if (vm.additionalServices.limitedAccessPickup) {
				extraServices.push({ "code": "LTDPU" });
			}
			if (vm.additionalServices.pickupLiftgate) {
				extraServices.push({ "code": "LGPU" });
			}
			if (vm.additionalServices.pickupNotification) {
				extraServices.push({ "code": "APPTPU" });
			}
			if (vm.additionalServices.residentialDelivery) {
				extraServices.push({ "code": "RESDEL" });
			}
			if (vm.additionalServices.residentialPickup) {
				extraServices.push({ "code": "RESPU" });
			}

			var result = _.cloneDeep(vm.order);

			result.organization_id = vm.customer.id;
			result.group_id = '';
			result.location_id = '';
			result.service_type = (vm.mode !== 'parcel') ? 'LTL' : 'PARCEL';
			result.return_service = vm.return_service;
			result.rlms_number = '';
			result.is_rlms = vm.is_rlms;
			result.is_manual = (vm.mode !== 'manual') ? 'false' : 'true';

			/*if (!vm.systemUser) {
				// 3rd party users always use Prepaid terms
				result.terms = 'PREPAID';
			}
			else {
				// SRC-L selects between 3rd Party Collect and 3rd Party Prepaid
				result.terms = vm.terms;
			}*/
		  result.terms = vm.terms;

			/*if (!vm.systemUser) {
				// 3rd party users always use Prepaid terms
				result.direction = 'SHIPPER';
			}
			else {
				// SRC-L selects between 3rd Party Collect and 3rd Party Prepaid
				result.direction = vm.direction;
		  }*/
		  result.direction = vm.direction;

			result.quote = {};

			result.shipment = {
				origin: {},
				destination: {},
				billing: {},
				line_items: [],
				identifiers: []
			};

			result.shipment.origin = _.cloneDeep(vm.order.shipment.origin);
			result.shipment.origin.ready_date = readyDate;
			result.shipment.origin.ready_time = readyTime;
			result.shipment.origin.close_time = closeTime;
			// TODO set only if missing from entered address, else use what came from shipper/destination entry
			result.shipment.origin.country_code = (provinceIsCanadian(result.shipment.origin.state_code)) ? "CA" : "US";
			result.shipment.origin.requires_inside_delivery = vm.additionalServices.insidePickup;
			result.shipment.origin.is_residential = vm.additionalServices.residentialPickup;
			result.shipment.origin.requires_liftgate = vm.additionalServices.pickupLiftgate;
			result.shipment.origin.has_appointment = vm.additionalServices.pickupNotification;
			result.shipment.origin.is_limited_access = vm.additionalServices.limitedAccessPickup;
			result.shipment.origin.additional_services = (extraServices.length > 0) ? extraServices : null;

			result.shipment.destination = _.cloneDeep(vm.order.shipment.destination);
			// TODO set only if missing from entered address, else use what came from shipper/destination entry
			result.shipment.destination.country_code = (provinceIsCanadian(result.shipment.destination.state_code)) ? "CA" : "US";
			result.shipment.destination.requires_inside_delivery = vm.additionalServices.insideDelivery;
			result.shipment.destination.is_residential = vm.additionalServices.residentialDelivery;
			result.shipment.destination.requires_liftgate = vm.additionalServices.deliveryLiftgate;
			result.shipment.destination.has_appointment = vm.additionalServices.deliveryNotification;
			result.shipment.destination.is_limited_access = vm.additionalServices.limitedAccessDropoff;
			result.shipment.destination.additional_services = null;

			result.shipment.billing = _.cloneDeep(vm.order.shipment.billing);

			if (vm.insurance && vm.insurance.certificate_number && vm.insurance.certificate_number.length > 0 && vm.insurance.cost && vm.insurance.cost.length > 0 && vm.insurance.value && vm.insurance.value.length > 0)
				result.insurance = vm.insurance;

			if (vm.materialInformation && vm.materialInformation.temperature)
				result.materialInformation = vm.materialInformation;

			if (vm.mode === 'manual') {
				result.quote = vm.manualDetails.quote;
				result.allow_auto_dispatch = vm.manualDetails.autoDispatch === 'TRUE';

				if (result.insurance) {
					var insurance_charge = {
						code: 'FINSUR',
						description: 'Insurance Coverage: $' + result.insurance.value,
						weight: 0,
						weight_unit: '',
						amount: 0,
						chksum: +result.insurance.cost || 0,
						cert_number: result.insurance.certificate_number,
						insured_value: result.insurance.value
					};

					if (result.quote && result.quote.details) {
						result.quote.details.chksum = (+result.quote.details.chksum || 0) + (+result.insurance.cost || 0);
						if (result.quote.details.charges)
							result.quote.details.charges.push(insurance_charge);
					}
				}
				if (result.quote && result.quote.details) {
					result.chksum = _.round(+result.quote.details.chksum || 0, 2);
					result.total = _.round(+result.quote.details.amount || 0, 2);
				}
			}

			if (vm.referenceNumbers.identifiers) {
				result.shipment.identifiers = vm.referenceNumbers.identifiers;

				// create rlms number
				result.dealer_number = vm.referenceNumbers.dealerID || '';
				result.return_number = vm.referenceNumbers.returnNumber || '';
			}

			for (var i = 0, len = vm.orderItems.items.length; i < len; i++) {
				var entry = vm.orderItems.items[i];
				var orderItem = {
					class: entry.CL,
					weight: entry.weight,
					product_description: entry.description,
					package_type: getContainerCode(entry.container),
					nmfc: entry.NMFC,
					estimated_weight: entry.weight,
					piece_count: entry.pieces,
					length: entry.dimensions.lengthInInches,
					width: entry.dimensions.widthInInches,
					height: entry.dimensions.heightInInches,
					item_number: entry.itemReference
				};
				result.shipment.line_items.push(orderItem);
			}

			return result;
		}
	}


})();

