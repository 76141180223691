(function () {
	'use strict';

	angular
		.module('app.coreUi', [
			'app.core',
			'app.components',
			'ngAnimate',
			'ui.router',
			'ui.bootstrap',
			'ui.mask',
			'ui.grid',
			'ui.grid.autoResize',
		]);
})();
