(function () {
	'use strict';

	angular
		.module('app.bolEntry')
		.factory('bolEntryDataservice', bolEntryDataservice);

	bolEntryDataservice.$inject = ['$http', '$q', '$location', 'exception', 'logger'];

	/* @ngInject */
	function bolEntryDataservice($http, $q, $location, exception, logger) {

		var service = {
			getOrderByOrderId: getOrderByOrderId
		};
		return service;

		function getOrderByOrderId(orderId) {
			return $http.get('/api/orders/' + orderId)
				.then(success)
				.catch(fail);

			function success(response) {
				return response;
			}

			function fail(e) {
				if (e.data.name === 'OrderNotFound' || e.data.name === 'OrderDetailsNotFound') {
					$location.path('/order/' + orderId).replace();
					return exception.catcher('')(e);
				} 
				else {
					return exception.catcher('XHR Failed for getOrderBySwNumber')(e);
				}
			}
		}
	}
})();


