(function () {
	'use strict';

	var module = angular.module('app.components');

	// /* @ngInject */
	// function controller(currentUser, $http) {
	function controller($http, currentUser) {

		/*jshint validthis: true */
	  var vm = this;
	  vm.currentUserId = currentUser.userId;
	  vm.systemUser = currentUser.isSystemUser;
		vm.trackingSaveFeedback = { success: false, message: null };
		vm.claimsSaveFeedback = { success: false, message: null };
		vm.notesSaveFeedback = { success: false, message: null };
		vm.cancelShipmentFeedback = { success: false, message: null };

		// Handle component lifecycle events
		vm.$onInit = function () {
			doSomeDefaultStuff();
		};

		vm.$onChanges = function (changesObj) {
			loadTrackingInfo();
			loadNotes();
		}

		/*
		We have several fields that should be Date only, such as pickup_date
		Issue is we don't want to capture the current time. The date parsing
		assumes then that it's 0:00 but also assumes server locale. So,
		when running on servers set to ZULU/GMT, which most are, this
		then reports back out to GMT-5 as the day before.
		So, forcing it to be 12:00Z so no matter the user or server locale. This should
		capture and report back out correctly.
		 */
		vm.readVmDateToIsoString = function(vmDate){
			try {
				if (vmDate){
					var date = new Date(vmDate);
					date.setUTCHours(12);
					return date.toISOString();
				}else{
					return '';
				}
			}catch(e){
				//date parsing error, shouldn't happen but clear it out
				return '';
			}
		}

	  vm.SaveTrackingInput = function () {
			var param = {
				'pickup_date': vm.readVmDateToIsoString(vm.entity.pickup_date),
			  'delivery_date': vm.readVmDateToIsoString(vm.entity.delivery_date),
				'shipmentIdentifiers': [{ 'type': 'PRO', 'value': vm.entity.pro_number }]
			};
		  if (vm.entity.details.request.quote.trailer_status) {
				param.details = {
					'request': {
						'quote': {
							'trailer_status': vm.entity.details.request.quote.trailer_status
						}
					}
				};
		  }
		  
			var url = 'api/orders/' + vm.entity.id;
			console.log(url);
			console.log(param);

			$http.put(url, param)
				.then(
				function successCallback(response) {
					console.log(response);
					vm.trackingSaveFeedback.success = true;
					vm.trackingSaveFeedback.message = 'Successfully Saved';

					loadTrackingInfo();
				},
				function errorCallback(response) {
					console.log(response);
					vm.trackingSaveFeedback.success = false;
					vm.trackingSaveFeedback.message = 'Something went wrong';
				}
				)
				.catch(function caught(response) {
					console.log(response);
					vm.trackingSaveFeedback.success = false;
					vm.trackingSaveFeedback.message = 'Something went wrong';
				});
		};

		vm.SaveNotes = function() {
			var param = {
				'note': vm.entity.note,
				'order_id': vm.entity.id,
				'user_id': vm.currentUserId,
			};
			var url = 'api/notes';
			console.log(url);
			console.log(param);

			$http.post(url, param)
				.then(
				function successCallback(response) {
					console.log(response);
					vm.notesSaveFeedback.success = true;
					vm.notesSaveFeedback.message = 'Successfully Added';

					loadNotes();
				},
				function errorCallback(response) {
					console.log(response);
					vm.notesSaveFeedback.success = false;
					vm.notesSaveFeedback.message = 'Something went wrong';
				}
				)
				.catch(function caught(response) {
					console.log(response);
					vm.notesSaveFeedback.success = false;
					vm.notesSaveFeedback.message = 'Something went wrong';
				});
		};

		vm.CancelShipment = function() {
			$http.delete('api/orders/' + vm.entity.id)
				.then(
				function successCallback(response) {
					vm.cancelShipmentFeedback.success = true;
					vm.cancelShipmentFeedback.message = 'Successfully Cancelled';

					vm.entity.status = 'CX';

					var reason = vm.entity.cancelReason && vm.entity.cancelReason.length > 0 ? ': ' + vm.entity.cancelReason : '';
					if(!response.success)
						reason += ' ** Carrier Cancellation Failed **';
					var param = {
						'note': 'Shipment Cancelled' + reason,
						'order_id': vm.entity.id,
						'user_id': 1
					};

					$http.post('api/notes', param)
					.then(
					function successCallback(response) {
						console.log(response);
						vm.cancelShipmentFeedback.success = true;
						vm.cancelShipmentFeedback.message = 'Successfully Cancelled';

						loadNotes();
					},
					function errorCallback(response) {
						console.log(response);
						vm.cancelShipmentFeedback.success = false;
						vm.cancelShipmentFeedback.message = 'Something went wrong';
					}
					)
					.catch(function caught(response) {
						console.log(response);
						vm.cancelShipmentFeedback.success = false;
						vm.cancelShipmentFeedback.message = 'Something went wrong';
					});

					loadNotes();
				},
				function errorCallback(response) {
					vm.cancelShipmentFeedback.success = false;
					vm.cancelShipmentFeedback.message = response.data.message && response.data.message.length > 0 ? response.data.message : 'Something went wrong';
				}
				)
				.catch(function caught(response) {
					vm.cancelShipmentFeedback.success = false;
					vm.cancelShipmentFeedback.message = 'Something went wrong';
				});
		};

		vm.SaveClaimsInput = function() {
			vm.claimsSaveFeedback.success = false;
			vm.claimsSaveFeedback.message = 'Save code is not finished yet';
		}

		function doSomeDefaultStuff(){
			// do default stuff
		}

		function loadTrackingInfo(){
			if(vm.entity && vm.entity.id)
			{
				$http.get('/api/tracking/' + vm.entity.id).then(function successCallback(response) {
					vm.entity.status = response.data.status;
					vm.entity.details.tracking = response.data;
					if(response.data.pickup_date_time && response.data.pickup_date_time.length > 0)
						vm.entity.pickup_date = new Date(response.data.pickup_date_time);
					if(response.data.delivery_date_time && response.data.delivery_date_time.length > 0)
						vm.entity.delivery_date = new Date(response.data.delivery_date_time);
					if(response.data.pro && response.data.pro.length > 0)
						vm.entity.pro_number = response.data.pro;
				}, function failureCallback(response) {
					console.log(response);
				});
			}
		}

		function loadNotes(){
			vm.entity.note = '';
			$http.get('/api/orders/' + vm.entity.id + '/notes?rnd=' + new Date().getTime()).then(function successCallback(response) {
				vm.entity.notes = response.data;
			}, function successCallback(response) {
				console.log(response);
			});
		}
	}

	module.component('shipmentManagementOrderDetailTracking', {
		bindings: {
			entity: '<'
		},
		controllerAs: 'vm',
		controller: ['$http', 'currentUser', controller], // list of DI items with controller method as the last item
		templateUrl: 'app/components/shipmentManagement/orderDetailTracking.component.html'
	});

}());
