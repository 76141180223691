(function () {
	'use strict';

	var module = angular.module('app.components');

	// /* @ngInject */
	// function controller($http) {
	function controller(currentUser, $http, $location) {

		/*jshint validthis: true */
		var vm = this;
		vm.state = '';
		vm.stringifiedData = '';
	  vm.systemUser = currentUser.isSystemUser;
		vm.palletItems = [];

		// Handle component lifecycle events
		vm.$onInit = function () {
      /**
       * Called on each controller after all the controllers on an element have been constructed
       * and had their bindings initialized (and before the pre & post linking functions for the
       * directives on this element). This is a good place to put initialization code for your
       * controller.
       */
			//doSomeDefaultStuff();
		};

		vm.$onChanges = function (changesObj) {
			if (!vm.entity.details) return;

			vm.palletItems = [];
			for (var i = 0; i < vm.entity.details.request.shipment.line_items.length; i++) {
			//	if (vm.entity.details.request.shipment.line_items[i].package_type === 'PLT') {
					vm.palletItems.push(vm.entity.details.request.shipment.line_items[i]);
			//	}
			}
		}
		
		vm.formatPhoneNumber = function(tempPhoneNum) { 
			if (!tempPhoneNum) {
				tempPhoneNum = '';
			}
			var phoneNum = '' + tempPhoneNum;
			phoneNum = phoneNum.trim();
			phoneNum = phoneNum.replace(/\D/g, '');
			if (phoneNum.length < 10) {
				phoneNum = phoneNum.padStart(10, ' ');
			}
			var pn = '';
			if (phoneNum.length > 10) {
				pn += '+' + phoneNum.substring(0, phoneNum.length - 10) + ' ';
			}
			pn += '('; pn += phoneNum.substring(phoneNum.length - 10, phoneNum.length - 7) + ') ' + phoneNum.substring(phoneNum.length - 7, phoneNum.length - 4) + '-' + phoneNum.substring(phoneNum.length - 4); 
			
			return pn; 
		}
		
		vm.StringifyRawData = function() {
			vm.stringifiedData = JSON.stringify(vm.entity);
		}

		vm.hoursToAMPM = function(time) {
			if(!time) return '';

			var hours = (time[0] !== '0' ? time[0] : '') + time[1];
			var min = time[3] + time[4];
			if (hours < 12) {
				if(hours === '0') hours = 12;
				return hours + ':' + min + 'AM';
			} else {
				hours = (hours === '12' ? 12 : hours - 12);
				return hours + ':' + min + 'PM';
			}
		}

		vm.reactivateShipment = function() {

			var url = '/api/orders/' + vm.entity.id + '/status';

			$http.put(url)
				.then(
				function successCallback(response) {
					vm.entity.status = 'DISP-AP';

					$http.get('/api/orders/' + vm.entity.id + '/notes?rnd=' + new Date().getTime()).then(function successCallback(response) {
						vm.entity.notes = response.data;
					}, function successCallback(response) {
						console.log(response);
					});

					$location.path('/order/' + vm.entity.id).replace();
				},
				function errorCallback(response) {
					if (vm.state.length > 0) {
						vm.state = 'error';
						console.group('======= ERROR CAUGHT ON REACTIVATION ========');
						console.log(response);
						console.groupEnd();
					}
				}
				)
				.catch(function caught(response) {
					if (vm.state.length > 0) {
						vm.state = 'error';
						console.group('======= ERROR CAUGHT ON REACTIVATION ========');
						console.log(response);
						console.groupEnd();
					}
				});
		}

		////////////////////////
		// function doSomeDefaultStuff(){
		//   vm.entity.name = "doSomeDefaultStuff Was Also Here";
		// }
	}

	module.component('shipmentManagementOrderDetail', {
		bindings: {
			entity: '<',
			onFinished: '&'
		},
		controllerAs: 'vm',
		//controller: [controller], // list of DI items with controller method as the last item
		controller: ['currentUser', '$http', '$location', controller], // list of DI items with controller method as the last item
		templateUrl: 'app/components/shipmentManagement/orderDetail.component.html'
	});

}());
