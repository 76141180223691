(function () {
	'use strict';

	angular
		.module('app')
		.factory('currentCustomer', currentCustomer);

	currentCustomer.$inject = ['currentUser'];

	/* @ngInject */
	function currentCustomer(currentUser) {

		var service = {
			setCurrentCustomer: setCurrentCustomer,
			clearCurrentCustomer: clearCurrentCustomer,
			id: null,
			name: null,
		};

		return service;

		function setCurrentCustomer(id, name) {
			service.id = id;
			service.name = name;
		}

		function clearCurrentCustomer() {
			service.id = null;
			service.name = null;
		}
	}

})();
