(function () {
	'use strict';

	angular
		.module('app.organization')
		.factory('organizationDataservice', organizationDataservice);

	organizationDataservice.$inject = ['$http', '$q', 'exception', 'logger'];
	/* @ngInject */
	function organizationDataservice($http, $q, exception, logger) {

		var service = {
			getOrganizations: getOrganizations,
			getOrganizationById: getOrganizationById,
			getOrganizationByExternal: getOrganizationByExternal,
			getOrganizationByName: getOrganizationByName,
			getUsersForOrganization: getUsersForOrganization,
			createOrganization: createOrganization,
			updateOrganization: updateOrganization,
			removeOrganization: removeOrganization,
			getImpersonationTokenFor: getImpersonationTokenFor,
			getUserById: getUserById,
			getUserByName: getUserByName,
			createOrganizationUser: createOrganizationUser,
			updateOrganizationUser: updateOrganizationUser,
			removeOrganizationUser: removeOrganizationUser
		};
		return service;


		function getOrganizations() {
			return $http.get('/api/organizations')
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getOrganizations')(e);
			}
		}


		function getOrganizationById(id) {
			return $http.get('/api/organizations/' + id)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getOrganizationById')(e);
			}
		}

		function getOrganizationByExternal(id) {
			return $http.get('/api/organizations?external=' + id)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return e;
			}
		}
		function getOrganizationByName(name) {
			return $http.get('/api/organizations?name=' + name)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return e;
			}
		}


		function getUsersForOrganization(id) {
			return $http.get('/api/organizations/' + id + '/users')
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getUsersForOrganization')(e);
			}
		}

		function createOrganization(organization) {

			return $http.post('/api/organizations', organization).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for createOrganization')(e);
			}
		}

		function updateOrganization(organization) {
			console.log('data service updateOrganization')
			console.log(organization)

			return $http.put('/api/organizations/' + organization.id, organization).then(success).catch(fail);

			function success(response) {
				console.log(response.data);
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for updateOrganization')(e);
			}
		}

		function removeOrganization(organization) {

			return $http.delete('/api/organizations/' + organization.id).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for removeOrganization')(e);
			}
		}


		function getImpersonationTokenFor(userId) {
			return $http.get('/api/auth/impersonate/' + userId)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getImpersonationTokenFor')(e);
			}
		}

		function createOrganizationUser(newUser) {
			console.log('dataservice createOrganizationUser')
			console.log(newUser)
			return $http.post('/api/organizations/' + newUser.organization_id + '/users', newUser).then(success).catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				if (e.status === 400) {
					logger.error('User already exists');
				} else {
					logger.error('Failed to create user');
				}
				return null;
			}
		}

		function getUserById(orgId, userId) {
			console.log('reading for id: ', userId)
			return $http.get('/api/organizations/' + orgId + '/users/' + userId)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for getUserById')(e);
			}
		}

		function getUserByName(userName) {
			return $http.get('/api/users/name/' + userName)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				if (e.status === 404) {
					return e;
				} else {
					return exception.catcher('XHR Failed for getUserByName')(e);
				}
			}
		}

		function updateOrganizationUser(user) {
			return $http.put('/api/organizations/' + user.organization_id + '/users/' + user.id, user)
				.then(success)
				.catch(fail);

			function success(response) {
				return response.data;
			}

			function fail(e) {
				return exception.catcher('XHR Failed for updateOrganizationUser')(e);
			}
		}


		function removeOrganizationUser(orgId, userId) {
			return $http.delete('/api/organizations/' + orgId + '/users/' + userId).then(success).catch(fail);
			function success(response) {
				return response.data;
			}

			function fail(e) {
				return logger.error('Failed to delete user: ' + e);
			}
		}

		// function removeOrganizationUser(userId) {
		// 	return $http.delete('/api/users/' + userId).then(success).catch(fail);
		// 	function success(response) {
		// 		return response.data;
		// 	}

		// 	function fail(e) {
		// 		return logger.error('Failed to delete user: ' + e);
		// 	}
		// }


	}
})();
