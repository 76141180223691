(function () {
  'use strict';

  var module = angular.module('app.components');

  // /* @ngInject */
  // function controller($http) {
  function controller() {

    /*jshint validthis: true */
    var vm = this;
		vm.state = '';
		vm.selectedServices = [];

    // Handle component lifecycle events
    vm.$onInit = function () {
      /**
       * Called on each controller after all the controllers on an element have been constructed
       * and had their bindings initialized (and before the pre & post linking functions for the
       * directives on this element). This is a good place to put initialization code for your
       * controller.
       */
      //doSomeDefaultStuff();
			findSelectedServices();
    };

	vm.updateServices = function() {
		findSelectedServices();
		vm.state = '';
	};

	vm.$onChanges = function (changesObj) {
		findSelectedServices();
	}

	function findSelectedServices() {
		vm.selectedServices = [];

		for (var key in vm.entity) {
			if (vm.entity.hasOwnProperty(key)) {
				if (vm.entity[key] === true) {
					switch (key) {
						case 'deliveryLiftgate':
							vm.selectedServices.push('Delivery Liftgate');
							//vm.entity.deliveryLiftgate = true;
							break;
						case 'deliveryNotification':
							vm.selectedServices.push('Delivery Notification/Appointment Fee');
							//vm.entity.deliveryNotification = true;
							break;
						case 'extremeLength':
							vm.selectedServices.push('Extreme Length');
							//vm.entity.extremeLength = true;
							break;
						case 'hazmat':
							//vm.selectedServices.push('Hazmat'); //intentionall do nothing, break hazmat until further notice
							//vm.entity.hazmat = true;
							break;
						case 'customsClearance':
							vm.selectedServices.push('Customs Clearance');
							//vm.entity.insideDelivery = true;
							break;
						case 'insideDelivery':
							vm.selectedServices.push('Inside Delivery');
							//vm.entity.insideDelivery = true;
							break;
						case 'insidePickup':
							vm.selectedServices.push('Inside Pickup');
							//vm.entity.insidePickup = true;
							break;
						case 'limitedAccessDropoff':
							vm.selectedServices.push('Limited Access Dropoff');
							//vm.entity.limitedAccessDropoff = true;
							break;
						case 'limitedAccessPickup':
							vm.selectedServices.push('Limited Access Pickup');
							//vm.entity.limitedAccessPickup = true;
							break;
						case 'pickupLiftgate':
							vm.selectedServices.push('Pickup Liftgate');
							//vm.entity.pickupLiftgate = true;
							break;
						case 'pickupNotification':
							vm.selectedServices.push('Pickup Notification/Appointment Fee');
							//vm.entity.pickupNotification = true;
							break;
						case 'residentialDelivery':
							vm.selectedServices.push('Residential Delivery');
							//vm.entity.residentialDelivery = true;
							break;
						case 'residentialPickup':
							vm.selectedServices.push('Residential Pickup');
							//vm.entity.residentialPickup = true;
							break;
						default:
							console.log('Unhandled service encountered: %s', key);
							break;
					}
				}
			}
		}

		vm.selectedServices.sort();
	}
  }

  module.component('additionalServicesEntity', {
    bindings: {
      entity: '<',
      title: '@'
    },
    controllerAs: 'vm',
    controller: [controller], // list of DI items with controller method as the last item
    // controller: ['$http', controller], // list of DI items with controller method as the last item
    templateUrl: 'app/components/orderEntry/additionalServicesEntity.component.html'
  });

}());
