(function() {
  'use strict';

  angular
    .module('app.rateEstimate')
    .run(appRun);

  appRun.$inject = ['routerHelper'];
  /* @ngInject */
  function appRun(routerHelper) {
    routerHelper.configureStates(getStates());
  }

  function getStates() {
    return [
			{
				state: 'rateEstimate',
				config: {
					url: '/RateEstimate',
					templateUrl: 'app/areas/rateEstimate/rateEstimate.html',
				  controller: 'RateEstimateController',
					controllerAs: 'vm',
					title: 'Rate Estimate'
				}
			},
			{
			  state: 'rateEstimate-quote',
				config: {
					url: '/RateEstimate/:id/quote',
				  templateUrl: 'app/areas/rateEstimate/rateEstimate.html',
				  controller: 'RateEstimateController',
					controllerAs: 'vm',
					title: 'Rate Estimate Entry'
				}
			},
			{
			  state: 'rateEstimate-edit',
				config: {
					url: '/RateEstimate/:id/edit',
				  templateUrl: 'app/areas/rateEstimate/rateEstimate.html',
				  controller: 'RateEstimateController',
					controllerAs: 'vm',
					title: 'Rate Estimate Entry'
				}
			},
			{
			  state: 'rateEstimate-quote-print',
				config: {
					url: '/RateEstimate/:id/quote',
				  templateUrl: 'app/areas/rateEstimate/rateEstimate-quote.html',
				  controller: 'RateEstimateController',
					controllerAs: 'vm',
					title: 'Rate Estimate',
				}
			}
    ];
  }
})();
